<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<div class="create-item create-product-form">
    <app-go-back *ngIf=" p != undefined" [show]="isEdit && !isOnDialog" [text]="p!.name" (click)="goBackProduct()"></app-go-back>
    <app-card [contentLoaded]="loaded">
        <app-card-title>{{isEdit ? 'Editar producto' : 'Crear producto'}}</app-card-title>
        <p *ngIf="isOnDialog" class="fw600" [ngClass]="data.product.price != p?.price ? 'c_r' : 'c_g'">Precio unidad inputado : <span money [val]="data.product.price"></span></p>
        <form [formGroup]="form">
            <div class="form">
                <div class="onboarding-product-required">
                    <app-card-subtitle>Datos obligatorios</app-card-subtitle>
                    <div class="df">
                        <mat-form-field class="w50" appearance="outline">
                            <mat-label>Referencia</mat-label>
                            <input matInput placeholder="Referencia" formControlName="reference"
                                (focusout)="checkRef()">
                            <mat-error *ngIf="form.get('reference')?.getError('alreadyExisting')">Esta referencia ya
                                existe</mat-error>

                        </mat-form-field>
                        <div class="w50">
                            <app-add-remove [form]="form" [formCName]="'stock'" [showSaveIcon]="false"
                                [total]="this.p? this.p.stock : 0"></app-add-remove>
                        </div>
                    </div>

                    <div class="df">
                        <mat-form-field class="w50" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput placeholder="Nombre" formControlName="name">
                        </mat-form-field>
                        <mat-form-field class="w50" appearance="outline">
                            <mat-label>IVA del producto</mat-label>
                            <mat-select formControlName="tax">
                                <mat-option [value]="0">Sin IVA</mat-option>
                                <mat-option [value]="4">4%</mat-option>
                                <mat-option [value]="10">10%</mat-option>
                                <mat-option [value]="21">21%</mat-option>
                            </mat-select>
                            <mat-hint>IVA por defecto : {{userS.companyTax == 0 ? 'Sin IVA' :
                                userS.companyTax}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="df">
                        <mat-form-field class="w50" appearance="outline">
                            <mat-label>Precio de compra</mat-label>
                            <input type="number" min="0" matInput placeholder="Precio de compra"
                                formControlName="buy_price">
                            <mat-error
                                *ngIf="this.form.get('buy_price')?.hasError('required') || this.form.get('buy_price')?.hasError('min')">
                                El valor debe ser mayor o igual que 0
                            </mat-error>
                            <mat-error *ngIf="this.form.get('buy_price')?.hasError('max')">Número demasiado
                                grande</mat-error>
                        </mat-form-field>
                        <mat-form-field class="w50" appearance="outline">
                            <mat-label>Precio unidad</mat-label>
                            <input type="number" min="0" matInput placeholder="Precio" formControlName="price">
                            <mat-error
                                *ngIf="this.form.get('price')?.hasError('required') || this.form.get('buy_price')?.hasError('min')">
                                El valor debe ser mayor o igual que 0
                            </mat-error>
                            <mat-error *ngIf="this.form.get('price')?.hasError('max')">Número demasiado
                                grande</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <app-image-toggle #imageToggle [form]="form" [default]="true" [formCName]="'category'"
                    (onToggle)="resetExtraField()">
                    <app-img-toggle-item [text]="'General'" [formValue]="productCategory.GENERAL">
                        <mat-icon [svgIcon]="'barcode'"></mat-icon>
                    </app-img-toggle-item>

                    <app-img-toggle-item [text]="'Recambios'" [formValue]="productCategory.WRENCH">
                        <mat-icon>build_circle</mat-icon>
                    </app-img-toggle-item>

                    <app-img-toggle-item [text]="'Neumáticos'" [formValue]="productCategory.WHEEL">
                        <mat-icon>tire_repair</mat-icon>
                    </app-img-toggle-item>

                    <app-img-toggle-item [text]="'Electrónica'" [formValue]="productCategory.LIGHT">
                        <mat-icon>lightbulb</mat-icon>
                    </app-img-toggle-item>

                    <app-img-toggle-item [text]="'Aceite'" [formValue]="productCategory.LIQUID">
                        <mat-icon>water_drop</mat-icon>
                    </app-img-toggle-item>

                    <app-img-toggle-item [text]="'Boutique'" [formValue]="productCategory.BOUTIQUE">
                        <mat-icon>checkroom</mat-icon>
                    </app-img-toggle-item>


                    <!-- LIQUID EXTRA FIELD (SIGAUS)-->
                    <mat-form-field appearance="outline" *ngIf="isCategory(productCategory.LIQUID)">
                        <mat-label>Litros del producto</mat-label>
                        <mat-icon matPrefix>water_drop</mat-icon>
                        <input matInput type="number" placeholder="Litros del producto" formControlName="extra_field">
                        <mat-hint *ngIf="!form.get('extra_field')?.value">Si está vacío, se asume como 1L</mat-hint>
                        <mat-hint *ngIf="form.get('extra_field')?.value">Precio por litro actual :
                            <span money [val]="SIGAUS"></span>
                            <p class="c_b nmb"><a href="https://www.sigaus.es/factura-del-taller" target="_blank"
                                    rel="noopener noreferrer">Mas info <mat-icon class="c_b">open_in_new</mat-icon></a></p>
                        </mat-hint>
                    </mat-form-field>

                    <!-- WHEEL EXTRA FIELD (SIGNUS)-->
                    <mat-form-field appearance="outline" *ngIf="isCategory(productCategory.WHEEL)">
                        <mat-label>Categoría</mat-label>
                        <mat-icon matPrefix>tire_repair</mat-icon>
                        <mat-select formControlName="extra_field">
                            <mat-option *ngFor="let signus of SIGNUS_ARRAY" [value]="signus.id">
                                {{signus.name}}
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="!getSignus()">Si está vacío, se asume como N1</mat-hint>
                        <mat-hint *ngIf="getSignus() as signus">El precio actual de {{signus.name}} es de <span money
                                [val]="signus.price"></span>
                            <p class="c_b nmb"><a href="https://www.signus.es/tarifa-ecovalor" target="_blank"
                                    rel="noopener noreferrer">Mas info <mat-icon class="c_b">open_in_new</mat-icon></a></p>
                        </mat-hint>
                    </mat-form-field>

                </app-image-toggle>


                <div class="df jcc mt10">
                    <button appTrialbutton mat-flat-button color="warn" class="onboarding-product-finish"
                        (click)="create()">
                        <span>{{isEdit ? isOnDialog ? 'Actualizar' : 'Guardar' : 'Crear producto'}}</span>
                    </button>
                </div>
            </div>
        </form>
    </app-card>
</div>