import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_Client } from 'src/app/core/models/M_Client';


@Component({
  selector: 'app-create-invoice-dialog',
  templateUrl: './create-invoice-dialog.component.html',
  styleUrls: ['./create-invoice-dialog.component.css']
})

export class CreateInvoiceDialogComponent {
  date: FormControl<Date | null>;
  expedient: FormControl<string | null>
  
  constructor(public dialogRef: MatDialogRef<CreateInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: M_Client | undefined) {
    this.date = new FormControl(new Date(), Validators.required);
    this.expedient = new FormControl(null, this.requiredExpedient ? Validators.required : []);
  }

  get requiredExpedient() {
    return this.data && this.data.cli_is_invoice_e;
  }

}
