export class CompanyInfo {
    id: number;
    company_id: number;
    morning_work_schedule_start: number;
    morning_work_schedule_finish: number;
    afternoon_work_schedule_start: number;
    afternoon_work_schedule_finish: number
    granularity : number;

    constructor(data : any){
        this.id = data.id;
        this.company_id =  data.company_id;
        this.morning_work_schedule_start =  data.morning_work_schedule_start;
        this.morning_work_schedule_finish = data.morning_work_schedule_finish;
        this.afternoon_work_schedule_start = data.afternoon_work_schedule_start;
        this.afternoon_work_schedule_finish = data.afternoon_work_schedule_finish;
        this.granularity = data.granularity;
    }
}

export interface M_BookedHoursInterface{
    booked : number[];
    settings : CompanyInfo;
}
    
    
export class M_BookedHours implements M_BookedHoursInterface{
    booked: number[];
    settings : CompanyInfo;

    constructor(data : any) {
        this.booked = data.booked;
        this.settings = data.settings;
    }
}