import { Endpoint } from "@sinigual/angular-lib";
import { environment } from "src/environments/environment";
import { Calls } from "../enums/Calls";

export const endpoints = {
    
    globalSearch :              new Endpoint(environment.apiUrl, Calls.globalSearch, true, false),
    
    login :                     new Endpoint(environment.apiUrl, Calls.login, false),
    logout :                    new Endpoint(environment.apiUrl, Calls.logout, true),

    validateEmail :             new Endpoint(environment.apiUrl, Calls.validateEmail, false),
    updatePassword :            new Endpoint(environment.apiUrl, Calls.updatePassword, false),
    getInvoice :                new Endpoint(environment.apiUrl, Calls.getInvoice, true, false),
    getUsersById :              new Endpoint(environment.apiUrl, Calls.getUsersById, true, false),

    dashboard :                 new Endpoint(environment.apiUrl, Calls.dashboard, true, false),

    uploadImage :               new Endpoint(environment.apiUrl, Calls.uploadImage, true, false),
    deleteImage :               new Endpoint(environment.apiUrl, Calls.deleteImage, true, false),
    deleteById :                new Endpoint(environment.apiUrl, Calls.deleteId, true, false),
    
    profile :                   new Endpoint(environment.apiUrl, Calls.profile, true, false),
    forgotPassword :            new Endpoint(environment.apiUrl, Calls.forgotPassword, true, true),

    resetPassowrd :             new Endpoint(environment.apiUrl, Calls.resetPassword, true, true),
    updatePass :                new Endpoint(environment.apiUrl, Calls.updatePass, true),
    resendConfirmation :        new Endpoint(environment.apiUrl, Calls.resendConfirmation, true, true),
    pickUpMail :                new Endpoint(environment.apiUrl, Calls.pickUpMail, true, true),
    requestPDF :                new Endpoint(environment.apiUrl, Calls.requestPDF, true, true),
    getAppointmentId:           new Endpoint(environment.apiUrl, Calls.getAppointmentId, true, false),
    appointment:                    new Endpoint(environment.apiUrl, Calls.appointment, true, false),
    showFirstDay :                  new Endpoint(environment.apiUrl, Calls.showFirstDay, true , false),
    getBookedHours:                 new Endpoint(environment.apiUrl, Calls.getBookedHours, true , false),
    getBookedHoursByCompanyId :     new Endpoint(environment.apiUrl, Calls.getBookedHoursByCompanyId, false , false),
    getHolidays:                    new Endpoint(environment.apiUrl, Calls.getHolidays, true , false),
    getHolidaysRider:               new Endpoint(environment.apiUrl, Calls.getHolidaysRider, false , false),
    showRider:                      new Endpoint(environment.apiUrl, Calls.showRider, false , false),
    appointemtsRange:               new Endpoint(environment.apiUrl, Calls.appointemtsRange, true , false),
    getCitaByDay:                   new Endpoint(environment.apiUrl, Calls.getCitaByDay, true , false),
    setHolidays:                    new Endpoint(environment.apiUrl, Calls.setHolidays, true , false),
    setHolidaysCompanies:           new Endpoint(environment.apiUrl, Calls.setHolidaysCompanies, true , false),
    getTaskAppointment:             new Endpoint(environment.apiUrl, Calls.getTaskAppointment, true , false),
    createAppointmentClient:        new Endpoint(environment.apiUrl, Calls.createAppointmentClient, true , false),
    createAppointmentClientSide:    new Endpoint(environment.apiUrl, Calls.createAppointmentClientSide, false, true),
    changeAppointmentDate :         new Endpoint(environment.apiUrl, Calls.changeAppointmentDate),
    editAppointmentClient :         new Endpoint(environment.apiUrl, Calls.editAppointmentClient),
    updateAppointmentClient :       new Endpoint(environment.apiUrl, Calls.updateAppointmentClient),
    checkHolidays :                 new Endpoint(environment.apiUrl, Calls.checkHolidays,true, false),
    configCompanyAppointment :      new Endpoint(environment.apiUrl, Calls.configCompanyAppointment,true,false),
    updateConfigAppointment :       new Endpoint(environment.apiUrl, Calls.updateConfigAppointment),
    getCompanyInfoClientSide:       new Endpoint(environment.apiUrl, Calls.getCompanyInfoClientSide, false , false),
    getAppointmentsSide:       new Endpoint(environment.apiUrl, Calls.getAppointmentsSide, false , false),

    clients :                   new Endpoint(environment.apiUrl, Calls.clients, true, false),
    clientAdmincenters :        new Endpoint(environment.apiUrl, Calls.clientAdmincenters, true, false),
    clientsminify :             new Endpoint(environment.apiUrl, Calls.clientsminify, true, false),
    createClient :              new Endpoint(environment.apiUrl, Calls.createClient, true, true),
    editClient :                new Endpoint(environment.apiUrl, Calls.editClient, true, true),
    getClientById :             new Endpoint(environment.apiUrl, Calls.getClientById, true, false),

    checkExist :                new Endpoint(environment.apiUrl, Calls.checkPlateDni, true, false),

    vehicles :                  new Endpoint(environment.apiUrl, Calls.vehicles, true, false),
    clientsAdvances :           new Endpoint(environment.apiUrl, Calls.clientsAdvances, true, false),
    vehicleType :               new Endpoint(environment.apiUrl, Calls.vehicleType, true, false),
    vehiclesminify :            new Endpoint(environment.apiUrl, Calls.vehiclesminify, true, false),
    createVehicle :             new Endpoint(environment.apiUrl, Calls.createVehicle, true, true),
    searchClinets :             new Endpoint(environment.apiUrl, Calls.clients, true, true), //
    createVehicleWithClientId : new Endpoint(environment.apiUrl, Calls.createVehicle, true, true), //
    editVehicle :               new Endpoint(environment.apiUrl, Calls.editVehicle, true, true),
    getVehicleById :            new Endpoint(environment.apiUrl, Calls.getVehicletById, true, true),
    linkVehicle :               new Endpoint(environment.apiUrl, Calls.linkVehicle, true, true),
    clientsForVehicles :        new Endpoint(environment.apiUrl, Calls.clientsForVehicles, true, false),

    products :                  new Endpoint(environment.apiUrl, Calls.products, true, false),
    createProduct :             new Endpoint(environment.apiUrl, Calls.createProduct, true, true),
    getProductById:             new Endpoint(environment.apiUrl, Calls.getProductById, true, false),
    editStock :                 new Endpoint(environment.apiUrl, Calls.editStock, true, true),

    company :                   new Endpoint(environment.apiUrl, Calls.company, true, true),
    createCompany :             new Endpoint(environment.apiUrl, Calls.createCompany, false, true),
    updateCompany :             new Endpoint(environment.apiUrl, Calls.updateCompany, true, true),
    deleteCompany :             new Endpoint(environment.apiUrl, Calls.deleteCompany, true, true),            
    welcomeCompany :            new Endpoint(environment.apiUrl, Calls.welcomeCompany, true, true),
    saveColors :                new Endpoint(environment.apiUrl, Calls.saveColors, true, true),
    saveFooters :               new Endpoint(environment.apiUrl, Calls.saveFooters, true, true),
    saveCompanyFiles :          new Endpoint(environment.apiUrl, Calls.saveCompanyFiles, true, true),
    smtp :                      new Endpoint(environment.apiUrl, Calls.smtp, true, true),
    testEmail :                 new Endpoint(environment.apiUrl, Calls.testEmail, true, true),
    presentationMail :          new Endpoint(environment.apiUrl, Calls.presentationMail, true, true),
    payedInvoice :              new Endpoint(environment.apiUrl, Calls.payedInvoice, true,true),
    pdfExample :                new Endpoint(environment.apiUrl, Calls.pdfExample, true, false),
    checkCompanyInfo :          new Endpoint(environment.apiUrl, Calls.checkCompanyInfo, true, false),
    getLocationByZip :          new Endpoint(environment.apiUrl, Calls.getLocationByZip, true, false, true),
    getFieldsByCCAA :           new Endpoint(environment.apiUrl, Calls.getFieldsByCCAA, true, false, true),

    users :                     new Endpoint(environment.apiUrl, Calls.users, true, false),
    createUser :                new Endpoint(environment.apiUrl, Calls.createUser, true, true),
    updateUser :                new Endpoint(environment.apiUrl, Calls.updateUser, true, true),
    updateUserName :            new Endpoint(environment.apiUrl, Calls.updateUserName, true, true),

    bills :                     new Endpoint(environment.apiUrl, Calls.bills, true, false),
    bill :                      new Endpoint(environment.apiUrl, Calls.bill, true, true),
    createBill :                new Endpoint(environment.apiUrl, Calls.createBill, true, true),
    payedBill :                 new Endpoint(environment.apiUrl, Calls.payedBill, true, true),
    extraInvoice :              new Endpoint(environment.apiUrl, Calls.extraInvoice, true, false),
    addClientBill :             new Endpoint(environment.apiUrl, Calls.addClientBill, true, false),
    addProductBill :            new Endpoint(environment.apiUrl, Calls.addProductBill, true, false),
    updateProdBill :            new Endpoint(environment.apiUrl, Calls.updateProdBill, true, false),
    delProductBill :            new Endpoint(environment.apiUrl, Calls.delProductBill, true, false),
    confirmInvoice :            new Endpoint(environment.apiUrl, Calls.confirmInvoice, true, true),
    discardInvoice :            new Endpoint(environment.apiUrl, Calls.discardInvoice, true, true),
    downloadInvoices :          new Endpoint(environment.apiUrl, Calls.downloadInvoices, true, true),
    templatePreview :           new Endpoint(environment.apiUrl, Calls.templatePreview, true, true),
    hasDraft :                  new Endpoint(environment.apiUrl, Calls.hasDraft, true, false),
    exportInvoices :            new Endpoint(environment.apiUrl, Calls.exportInvoices, true, false),
    lastInvoiceDate :           new Endpoint(environment.apiUrl, Calls.lastInvoiceDate, true, false),

    actionTypes :               new Endpoint(environment.apiUrl, Calls.actionTypes, true, true),
    actions :                   new Endpoint(environment.apiUrl, Calls.actions, true, false),
    createOR :                  new Endpoint(environment.apiUrl, Calls.createOR, true, true),
    addClientVehicleAction :    new Endpoint(environment.apiUrl, Calls.addClientVehicleAction, true, true),
    getAction :                 new Endpoint(environment.apiUrl, Calls.getAction, true, false),
    addEditGroup :              new Endpoint(environment.apiUrl, Calls.addEditGroup, true, true),
    addTask :                   new Endpoint(environment.apiUrl, Calls.addTask, true, true),
    assignAction :              new Endpoint(environment.apiUrl, Calls.assignAction, true, true),
    editDelivery :              new Endpoint(environment.apiUrl, Calls.editDelivery, true, true),
    updateSchedule :            new Endpoint(environment.apiUrl, Calls.updateSchedule, true, true),
    addEditTasks :              new Endpoint(environment.apiUrl, Calls.addEditTasks, true, true),
    schedules :                 new Endpoint(environment.apiUrl, Calls.schedules, true, true),
    rmGroup :                   new Endpoint(environment.apiUrl, Calls.rmGroup, true, true),
    rmTask :                    new Endpoint(environment.apiUrl, Calls.rmTask, true, true),
    rmAction :                  new Endpoint(environment.apiUrl, Calls.rmAction, true, true),
    invoiceOR :                 new Endpoint(environment.apiUrl, Calls.invoiceOR, true, true),
    sortTask :                  new Endpoint(environment.apiUrl, Calls.sortTask, true, true),
    changeTask :                new Endpoint(environment.apiUrl, Calls.changeTask, true, true),
    editKm :                    new Endpoint(environment.apiUrl, Calls.editKm, true, true),
    editNotes :                 new Endpoint(environment.apiUrl, Calls.editNotes, true, true),
    editTitle :                 new Endpoint(environment.apiUrl, Calls.editTitle, true, true),
    closeGroup :                new Endpoint(environment.apiUrl, Calls.closeGroup, true, true),
    openGroup :                 new Endpoint(environment.apiUrl, Calls.openGroup, true, true),
    reorderActions :            new Endpoint(environment.apiUrl, Calls.reorderActions, true, true),
    editAction :                new Endpoint(environment.apiUrl, Calls.editAction, true, true),
    invoiceState:               new Endpoint(environment.apiUrl, Calls.stateInvoice, true,true),
    showTypePayment:            new Endpoint(environment.apiUrl, Calls.showTypePayment, true,true),
    CreateLastPayment:          new Endpoint(environment.apiUrl, Calls.CreateLastPayment, true,true),
    CreateAdvancesClient:       new Endpoint(environment.apiUrl, Calls.CreateAdvancesClient, true,true),
    DeleteAdvancesClient:       new Endpoint(environment.apiUrl, Calls.DeleteAdvancesClient, true,true),
    EditAdvancesClient:         new Endpoint(environment.apiUrl, Calls.EditAdvancesClient, true,true),
    DeleteMovementInvoice:      new Endpoint(environment.apiUrl, Calls.DeleteMovementInvoice, true,true),
    DeleteTypePayment:          new Endpoint(environment.apiUrl, Calls.DeleteTypePayment, true,true),
    createBudget :              new Endpoint(environment.apiUrl, Calls.createBudget, true, true),
    closeBudget :               new Endpoint(environment.apiUrl, Calls.closeBudget, true, true),
    copyAction :                new Endpoint(environment.apiUrl, Calls.copyAction, true, true),

    importStorage :             new Endpoint(environment.apiUrl, Calls.importStorage, true, true),
    importClients :             new Endpoint(environment.apiUrl, Calls.importClients, true, true),
    importTarifas :             new Endpoint(environment.apiUrl, Calls.importTarifas, true, true),

    /** Notifications */
    getNotifications :          new Endpoint(environment.apiUrl, Calls.getNotifications, true, false),
    updateNotifications:        new Endpoint(environment.apiUrl, Calls.updateNotifications),
    readAllNotifications :      new Endpoint(environment.apiUrl, Calls.readAllNotifications),

    /** Cloud tickets */
    cloudtickets:               new Endpoint(environment.apiUrl, Calls.cloudtickets, true, false),
    createTicket:               new Endpoint(environment.apiUrl, Calls.createTicket),
    addcomment:                 new Endpoint(environment.apiUrl, Calls.addcomment),

    /** Abono */
    abono :                     new Endpoint(environment.apiUrl, Calls.abono, true, true),


    /** Report erorr */
    reportError :               new Endpoint(environment.apiUrl, Calls.reportError, false, false),


    /** Templates url */
    invoicePDF :                new Endpoint(environment.apiUrl, Calls.invoice, true, false),
    eInvoicePDF :               new Endpoint(environment.apiUrl, Calls.eInvoice, true, false),
    budgetPDF :                 new Endpoint(environment.apiUrl, Calls.budgetPDF, true, false),
    raPDF :                     new Endpoint(environment.apiUrl, Calls.ra, true, false),
    createAdvancePdf :          new Endpoint(environment.apiUrl, Calls.createAdvancePdf, true, false),
    albaranPDF :                new Endpoint(environment.apiUrl, Calls.albaranPDF, true, false),

    /** No id Objects URL */
    previewRA :                 new Endpoint(environment.apiUrl, Calls.previewRA, true, false),


    /** Brand and models */
    brands :                    new Endpoint(environment.apiUrl, Calls.brand, true, false),
    brandModels :               new Endpoint(environment.apiUrl, Calls.brand_models, true, false),

    /** Paycomet */
    payComet :                  new Endpoint(environment.apiUrl, Calls.payComet, true, false, true),
    payCometInvoices :          new Endpoint(environment.apiUrl, Calls.payCometInvoices, true, false, true),
    checkSubscription :         new Endpoint(environment.apiUrl, Calls.checkSubscription,true, false, true),
    paymentByCompany :          new Endpoint(environment.apiUrl, Calls.paymentByCompany,true, false),

    /** Templates */
    getFieldsFromDoc :          new Endpoint(environment.apiUrl, Calls.getFieldsFromDoc,true, false),
    saveTemplateFields :        new Endpoint(environment.apiUrl, Calls.saveTemplateFields,true, true),
    saveTemplateImage :         new Endpoint(environment.apiUrl, Calls.saveTemplateImage,true, true), 


    /** Flotas */
    getActionGroupsClosed :         new Endpoint(environment.apiUrl, Calls.getActionGroupsClosed, true, false), 
    invoiceOR_ActionGroupsClosed :  new Endpoint(environment.apiUrl, Calls.invoiceOR_ActionGroupsClosed, true,), 


    /** Fichage */
    currentlyWorking :              new Endpoint(environment.apiUrl, Calls.currentlyWorking, true),
    startTime :                     new Endpoint(environment.apiUrl, Calls.startTime, true),
    endTime :                       new Endpoint(environment.apiUrl, Calls.endTime, true), 
    addManualTime :                 new Endpoint(environment.apiUrl, Calls.addManualTime, true), 
    deleteTimeHistory :             new Endpoint(environment.apiUrl, Calls.deleteTimeHistory, true),
}