import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ClassSearcherComponent, ResponsiveService } from '@sinigual/angular-lib';
import { endpoints } from 'src/app/core/api/Enpoints';
import { DragGroupComponent } from 'src/app/core/components/drag/drag-group/drag-group.component';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { M_Product } from 'src/app/core/models/M_Product';
import { AddRemoveComponent } from '../../products/add-remove/add-remove.component';
import { CreateBillComponent } from '../create-bill.component';
import { CreateCustomProductComponent } from './create-custom-product/create-custom-product.component';
import { FormControl } from '@angular/forms';
import { MASTER_PRODUCT } from 'src/app/core/constants/masters';
import { ProductLineTableService } from 'src/app/core/services/product-line-table.service';
import { DragTaskComponent } from 'src/app/core/components/drag/drag-task/drag-task.component';
import { CustomProductData } from 'src/app/core/interfaces/CustomProductData';


type Phase = "search" | "product"

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})


export class AddProductComponent implements OnInit {

  product = MASTER_PRODUCT;

  @ViewChild(ClassSearcherComponent) searchForm!: ClassSearcherComponent<M_Product>;
  @ViewChild(AddRemoveComponent) addRemove?: AddRemoveComponent;
  @ViewChild(MatCheckbox) aboned?: MatCheckbox;
  /** type Phase = "add" | "search" | "product" */
  @Input() fase?: Phase | undefined;
  @Input() p: M_Product | M_CustomProduct | undefined;
  @Input() canModify: boolean = true;
  @Input() classSearch?: string;
  @Input() classAdd?: string;
  @Input() dragHandle: boolean = false;
  @Input() isOr: boolean = false;
  @Input() unsavedChanges: boolean = false;
  @Input() instantChangeEmission: boolean = false;
  @Output() onSelectProduct: EventEmitter<M_Product | M_CustomProduct> = new EventEmitter();
  @Output() onChangeDiscount: EventEmitter<M_Product | M_CustomProduct> = new EventEmitter();
  @Output() onChangePrice: EventEmitter<M_Product | M_CustomProduct> = new EventEmitter();
  @Output() onModifyStock: EventEmitter<M_Product | M_CustomProduct> = new EventEmitter();
  @Output() onDestroy: EventEmitter<{ componentId: number | undefined, producLine: number | undefined, product: M_Product | M_CustomProduct | undefined }> = new EventEmitter();
  @ViewChild("drag") dragTemplateRef!: TemplateRef<any>;

  currentFase: Phase = "search";
  destroyed = false;
  overstock = 0;
  addedByParam: boolean = false;
  e = endpoints;
  id!: number;
  parent: CreateBillComponent | DragGroupComponent;
  initialValue: number = 0;
  showTooltip: boolean = false;
  showSearchStock: boolean = true;
  fcDiscount: FormControl<number> = new FormControl()
  fcPrice: FormControl<number> = new FormControl()
  browserClass: string = '';

  constructor(@Optional() private cbc: CreateBillComponent, @Optional() private dtc: DragGroupComponent,
    @Optional() public taskComponent: DragTaskComponent,
    private dialog: MatDialog, private chdRef: ChangeDetectorRef, public responsiveS: ResponsiveService,
    public productsLineS: ProductLineTableService) {
    this.parent = this.cbc ? this.cbc : this.dtc;
  }

  ngOnInit(): void {

    if (!this.canModify) { this.fcDiscount.disable(); }
    if (!this.canModify) { this.fcPrice.disable(); }

    this.fcDiscount.valueChanges.subscribe(valor => {
      if (valor < 0) {
        this.fcDiscount.patchValue(Math.abs(valor), { emitEvent: false });
      }
      if (this.fcDiscount.value !== null) {
        if (this.fcDiscount.value < 0) {
          this.fcDiscount.patchValue(0, { emitEvent: false });
        } else if (this.fcDiscount.value > 100) {
          this.fcDiscount.patchValue(100, { emitEvent: false });
        }
      }
      if (this.fcDiscount.value) {
        const regex = new RegExp(`^[0-9]+(\.[0-9]{0,${2}})?$`);
        if (!regex.test(this.fcDiscount.value.toString())) {
          const newValue = parseFloat(this.fcDiscount.value.toString().replace(/[^0-9.]/g, '')).toFixed(2);
          this.fcDiscount.patchValue(Number(newValue), { emitEvent: false });
        }
      }
      this.discountDisablePrice();
    })

    if (this.dtc) {
      this.dtc.onTypeChangeEvent.subscribe(val => {
        if (this.p && val.cargo) {
          this.p.discount = 0;
          this.fcDiscount.disable();
        }
        else if (!val.cargo && this.canModify) {
          this.fcDiscount.enable();
        }
      })
    }

  }

  ngOnChanges() {
    if (!this.canModify || this.isInterno) {
      if (!this.fcDiscount.disabled) {
        this.fcDiscount.disable();
      }
      if (!this.fcPrice.disabled) {
        this.fcPrice.disable();
      }
    }
    this.discountDisablePrice();
  }

  ngAfterViewInit() {
    this.discountDisablePrice();
  }

  focusSearcher() {
    if (this.currentFase == 'search') {
      this.searchForm?.focus();
      this.chdRef.detectChanges();
    }
  }

  ngAfterContentInit() {
    if (this.p) {
      this.currentFase = "product";
      this.initialValue = this.p.quantity;
    }
    else {
      this.currentFase = this.fase ? this.fase : this.currentFase;
    }
    this.chdRef.detectChanges();
  }

  /** Disable a product entry if the user already puts it on the screen 
   * Parent needed
  */
  disabledIf(rowProduct: M_Product) {
    return this.parent.getInProcessProducts().filter(p => p.product_id == rowProduct.product_id).length != 0
  }

  /** Return stock if a product is instance of M_Product */
  getProductStock() {
    if (this.p instanceof M_Product) {
      return this.p.stock - this.getStockSubstraction();
    }
    return 0;
  }

  /** Stock Substraction ?
   * 
   * Need comment
   * 
   */
  getStockSubstraction() {
    if (this.addRemove && this.p instanceof M_Product) {
      return this.addRemove.getValue() - this.initialValue
    }
    return 0;
  }

  /** Sync all stocks of same product on screen */
  getOthersStockSubsctractionOf(p: M_Product | M_CustomProduct) {
    if (p instanceof M_Product) {
      return this.dtc ? this.dtc?.getOthersStockOf(p) : 0;
    }
    return 0;
  }

  remainingStock() {
    if (this.p instanceof M_Product) {
      let remaining = this.getProductStock() - this.getOthersStockSubsctractionOf(this.p);
      this.updateProductSearcherStock(remaining);
      return remaining;
    }
    return 0;
  }

  /** Check product overstock */
  haveOverStock() {
    if (this.p instanceof M_Product) {
      return this.remainingStock() < 0;
    }
    return false;
  }

  /** Get the overstock */
  getOverStock(val: number) {
    if (this.p instanceof M_Product) { return this.p.getOverStock(val); }
    return 0;
  }

  /** Changes te fase of product */
  nextPhase() {
    this.currentFase = this.currentFase == "search" ? "product" : this.currentFase;
    this.chdRef.detectChanges();
  }

  /** Set a product to the component */
  setProduct(val: M_CustomProduct | M_Product) {
    this.currentFase = "product";
    this.p = val;
    this.chdRef.detectChanges();
  }

  /** CUSTOM PRODUCT DIALOG 
   * Emits "onSelectProduct" event
  */
  createProductDialog(inputValue: string) {
    let dRef = this.dialog.open<CreateCustomProductComponent, CustomProductData>
      (CreateCustomProductComponent,
        {
          autoFocus: false,
          data: {
            inputValue: inputValue,
            interno: this.isInterno,
            product: undefined,
          }
        });
    dRef.afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        this.emitSelectProduct(res);
      }
    })
  }


  validateMinus(event: KeyboardEvent): void {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  discountDisablePrice() {
    if (this.fcDiscount.value) {
      this.fcPrice.disable();
    }
    else {
      if (this.canModify && !this.fcDiscount.value) {
        this.fcPrice.enable();
      }
    }
  }

  /** Necessary to mark as unsaved de gorup tasks */
  onDiscountInput(e: any) {
    if (this.p) {
      this.discountDisablePrice();
      this.p.discount = this.fcDiscount.value;
      this.onChangeDiscount.emit(this.p);
    }
  }

  /** Necessary to mark as unsaved de gorup tasks */
  onPriceLineInput(e: any) {
    if (this.p) {
      this.updateProductLinePrice();
      this.onChangePrice.emit(this.p);
    }
  }

  updateProductLinePrice() {
    if (this.p) {
      if (this.isInterno && !this.isTime) {
        this.p.buy_price = this.fcPrice.value;
      }
      else {
        this.p.price = this.fcPrice.value;
      }
    }
  }

  onFocus(e: HTMLInputElement) {
    if (e.value == "0") {
      e.value = "";
    }
  }

  onFocusOut() {
    if (!this.instantChangeEmission) {
      this.onChangeDiscount.emit(this.p)
    }
  }

  getLabel() {
    return "Cantidad";
  }

  get isLiquid() {
    return this.p instanceof M_Product && this.p.isLiquid
  }

  get isWheel() {
    return this.p instanceof M_Product && this.p.isWheel
  }

  get showHintTotal() {
    return !this.isInterno && (this.isLiquid || this.isWheel);
  }

  get productExtraFieldName() {
    return this.isLiquid ? "SIGAUS" : this.isWheel ? "SIGNUS" : undefined;
  }

  get isInterno() {
    return this.parent instanceof DragGroupComponent && this.parent.group.type.cargo;
  }

  /** SIGAUS and SIGNUS */
  getTotalExtraField() {
    if (this.p instanceof M_Product) {
      if (this.p.isLiquid || this.p.isWheel) {
        return this.p.getExtraFieldTotalPrice;
      }
    }
    return undefined;
  }

  destroy() {
    this.onDestroy.emit({ componentId: this.id, producLine: this.p?.line_id, product: this.p })
    this.destroyed = true;
  }

  getQuantity() {
    return this.p ? this.p.quantity ? this.p.quantity : 1 : 0;
  }

  onFocusOutAddRemove() {
    if (this.p) {
      this.p.quantity = this.addRemove?.getValue();
      this.onModifyStock.emit(this.p);
    }
  }

  checkStock(v: number) {
    if (this.p) {
      this.p.quantity = v;
      this.onModifyStock.emit(this.p);
    }
  }

  getRef() {
    if (this.p instanceof M_Product) {
      return " Ref : " + this.p.reference + "";
    }
    else if (this.p instanceof M_CustomProduct) {
      return this.p.type == "time" ? this.p.price + "€/hora" : " Producto personalizado";
    }
    else return ""
  }

  getTotal(extrafield = false) {
    if (this.p) { return this.p.getTotal(extrafield, this.isInterno); }
    return 0;
  }

  get getExtraFieldTotalPrice() {
    if (this.p && this.p instanceof M_Product) { return this.p.getExtraFieldTotalPrice; }
    return 0;
  }

  get getPriceUnit() {
    var value = 0;
    if (this.p) {
      if (this.isInterno) {
        value = this.p instanceof M_CustomProduct && this.p.type == "time" ? this.p.price : this.p.buy_price;
      }
      else {
        value = Number(this.p.discount ? this.p.getDiscounted(this.p.discount) : this.p.price);
      }
    }
    return value;
  }

  get totalCurrency() {
    return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(Number(this.getTotal(true)));
  }

  emitSelectProduct(val: M_CustomProduct | M_Product) {
    val.discount = this.parent.getClientDiscount();
    this.onSelectProduct.emit(val);
  }

  isProduct() {
    return this.p instanceof M_Product;
  }

  get isTime() {
    return this.p instanceof M_CustomProduct && this.p.type == "time";
  }

  getIconByType() {
    if (this.isTime) {
      return "schedule";
    }
    else if (this.p instanceof M_Product) {
      return this.p!.icon;
    }
    else {
      return "barcode";
    }
  }

  getHint() {
    if (this.isProduct()) {
      return this.haveOverStock() ? '¡Fuera de stock!' :
        this.remainingStock() <= 0 ? 'Sin stock' :
          this.remainingStock() + ' unidades restantes';
    }
    return ""
  }

  abone(v: MatCheckboxChange) {
    if (this.p) {
      this.p.aboned = v.checked;
    }
  }

  /** TO DO : Refactor needed */
  syncAllRealProducts(product: M_Product) {

    let invoicedProducts: M_Product[] = []; // Already invoiced products (or and budget)
    let otherProducts: M_Product[] = []; //The rest of products

    /** OR and Budget pages */
    if (this.dtc && this.dtc.dg) {
      this.dtc.dg.groupsComponents?.forEach(g => {
        g.tasksComponent?.forEach(t => {
          if (t.apc && t.apc.p instanceof M_Product && t.apc.p.product_id == product.product_id) {
            if (g.isGroupInvoiced()) { invoicedProducts.push(t.apc.p); }
            else { otherProducts.push(t.apc.p); }
          }
          if (t.apc && t.apc.searchForm) {
            let classSearcherPorduct = t.apc.searchForm.allData.find(p => p.product_id == product.product_id);
            if (classSearcherPorduct) {
              otherProducts.push(classSearcherPorduct);
            }
          }
        })
      })

      if (this.taskComponent) {
        if (!this.taskComponent.task.task_id) {
          this.taskComponent.saveTask();
        }
        else {
          this.taskComponent.task.changes = false;
        }
      }

    }

    /** Invoices page */
    if (this.cbc) {
      this.cbc.productsComponent.forEach(productC => {
        if (productC.searchForm) {
          let classSearcherPorduct = productC.searchForm.allData.find(p => p.product_id == product.product_id);
          if (classSearcherPorduct) { otherProducts.push(classSearcherPorduct); }
        }
      })

      /** Refresh the breakdown of the current invoice */
      let breakDownProduct = this.cbc.currentBill?.breakdown.products.find(p => p.product_id == product.product_id);
      if (breakDownProduct) { otherProducts.push(breakDownProduct); }
    }

    invoicedProducts.forEach(p => { p.stock = product.stock });
    otherProducts.forEach(p => { p.copyCoreAttributes(product) });
  }


  syncCustomProducts(product: M_CustomProduct) {
    /** OR and Budget pages */
    if (this.dtc && this.dtc.dg && this.p instanceof M_CustomProduct && this.taskComponent) {
      this.p.copyCoreAttributes(product);
      this.addRemove?.setValue(this.p.quantity);
      this.taskComponent.saveTask();
    }

    /** Invoices page */
    if (this.cbc && this.p instanceof M_CustomProduct) {
      let cp = this.cbc.currentBill?.breakdown.customs.find(c => c.line_id == product.line_id);
      if (cp) { cp.copyCoreAttributes(product) }
      this.addRemove?.setValue(this.p.quantity);
      this.cbc.updateDraftProduct(product)
    }
  }

  /** TO DO : Refactor needed
  * Updates the class searcher stock (cs_stock) of all the add-product.component on the screen
  * Only for OR and Budgets
  */
  updateProductSearcherStock(remaining: number) {
    if (this.dtc && this.dtc.dg) {
      this.dtc.dg.groupsComponents?.forEach(g => {
        g.tasksComponent?.forEach(t => {
          if (t.apc && t.apc.searchForm) {
            if (this.p && this.p instanceof M_Product) {
              let compoenentProduct = this.p;
              let classSearcherPorduct = t.apc.searchForm.allData.find(p => p.product_id == compoenentProduct.product_id);
              if (classSearcherPorduct) {
                classSearcherPorduct.cs_stock = remaining;
              }
            }
          }
        })
      })
    }
  }

}
