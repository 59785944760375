<div class="transition-appear maxw" *ngIf="vehicle; else noData">
    <div class="df fdc aic jcc">
        <div [ngClass]="{'cp' : !vehicle.deleted && userS.adminOrAdviser}" (click)="goVehicle()"
            [matTooltip]="vehicle.deleted || userS.isMechanic ? '' : 'Ir a detalles avanzados del vehículo'">
            <div class="df">
                <lord-icon *ngIf="lord && vehicle.isBike" src="https://cdn.lordicon.com/nrmtiuty.json" trigger="loop"
                    delay="9999"
                    [attr.colors]="'primary:#121331,secondary:#3a3347,tertiary:'+getColorByVehicle(vehicle)+',quaternary:#ebe6ef'"
                    [ngClass]="small ? 'lord-medium' : 'lord-big'">
                </lord-icon>
                <script src="https://cdn.lordicon.com/olaxuwzv.json"></script>
                <lord-icon *ngIf="lord && vehicle.isCar" src="https://cdn.lordicon.com/olaxuwzv.json" trigger="loop"
                    delay="9999"
                    [attr.colors]="'primary:#121331,secondary:#ebe6ef,tertiary:'+getColorByVehicle(vehicle)+',quaternary:#3a3347,quinary:#ffffff'"
                    [ngClass]="small ? 'lord-medium' : 'lord-big'">
                </lord-icon>
            </div>
        </div>
        <p *ngIf="vehicle.deleted" class="c_r fw600">¡Vehículo eliminado!</p>

        <app-card-subtitle class="title tac" [first]="true" [noMarginBottom]="true">
            {{vehicle.getName()}}</app-card-subtitle>
        <p class="fsm nmb fw300" style="letter-spacing: 2px" *ngIf="vehicle.brandAndModel">
            {{vehicle.license.toUpperCase()}}</p>

        <button color="blue" mat-button [matMenuTriggerFor]="menu">
            <span>Más información</span></button>
        <mat-menu #menu="matMenu" class="card-row-menu">
            <app-card-row-content [data]="[ 
                ['Kms', vehicle.km],
                ['Serie', vehicle.serie ? vehicle.serie : undefined],
                ['Chasis', vehicle.chassis],
                ['CC', vehicle.cc],
                ['Tipo', vehicle.engineType],
            ]">
            </app-card-row-content>
        </mat-menu>
    </div>
</div>

<ng-template #noData>
    <p class="c_t2">{{noDataText ? noDataText : 'Al seleccionar un vehículo, en esta sección se mostrarán más detalle
        sobre él.'}}</p>
</ng-template>