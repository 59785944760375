<div class="df jcc">
    <button mat-stroked-button color="accent" (click)="addTab()" [disabled]="max">
        <mat-icon>account_tree</mat-icon>
        <span *ngIf="max">Máx intervenciones alcanzado</span>
        <span *ngIf="!max">Nueva intervención</span>
    </button>
</div>


<mat-tab-group color="accent" [selectedIndex]="selected.value" (selectedIndexChange)="onSelectedChange($event)"
    class="onboarding-or-tasks">
    <mat-tab *ngFor="let tab of tabs; let index = index">
        <ng-template mat-tab-label>
            <div class="df jcc aic">
                <p class="nmb">{{tab.title}}</p>
                <app-or-type-label style="margin-left: 5px;" [showText]="false" [wrapOn]="1"
                    [tipo]="[tab.or_type]"></app-or-type-label>
            </div>
        </ng-template>
        <div class="buttons-section">
            <app-or-type-label style="margin-left: 21px;  margin-bottom: 10px;" [menuIconOpen]="true"
                [mat-menu-trigger-for]="groupType" [showText]="false" [tipo]="[tab.or_type]">
            </app-or-type-label>
        </div>
        <app-comentarios-moto #moto></app-comentarios-moto>
        <div class="botom-section">
            <app-comentarios [moto]="moto" [label]="'Otras intervenciones'" [placeHolder]="'Añadir otra intervención'"
                [form]="form"></app-comentarios>
            <div class="df jcc">
                <button mat-button color="primary" (click)="preview()">
                    Ver todos
                </button>
                <button mat-button color="warn" class="delete-button" [disabled]="tabs.length === 1"
                    (click)="removeTab(index)">
                    Eliminar
                </button>
            </div>
        </div>

        <mat-menu #groupType="matMenu" [yPosition]="'above'">
            <app-or-type-selector (onClickOption)="onTypeChange(tab, $event)"></app-or-type-selector>
        </mat-menu>
    </mat-tab>
</mat-tab-group>