<div [ngClass]="!responsive.isPhone() ? 'entry-point': 'entry-point-phone'">
    <div *ngIf="displayToolbar; else router">
        <app-wifi-checker></app-wifi-checker>
        <app-onboarding #onboarding [userOnboarding]="refSercice.onboarding" [faqs]="refSercice.faqs"
            [showProgress]="false" [refService]="refSercice">
            <mat-drawer-container [hasBackdrop]="true" (backdropClick)="menu.close()">
                <mat-drawer #drawer [mode]="'over'">
                    <div class="df jcc mt20 mb10">
                        <img class="logoMenu" src="./assets/img/logos/eina_logo.png">
                    </div>
                    <div class="menu-logo-line"></div>
                    <app-menu #phoneMenu [drawer]="drawer" [isDesktop]="false" [ngClass]="{'dn' : !responsive.isPhone(),
                                          'menu-shadow' : menu.opened && !responsive.isPhone()}"
                        [refService]="refSercice">
                    </app-menu>
                </mat-drawer>
                <mat-drawer-content class="main-drawer-content">

                    <app-toolbar [onboarding]="onboarding" [mc]="menu"
                        (onclick)="toggleMenu(menu, phoneMenu)"></app-toolbar>
                    <div [ngClass]="{'entry-point-contents' : !responsive.isPhone()}">
                        <app-menu #menu [ngClass]="{'menuDesktop' : !responsive.isPhone(),
                                              'dn' : responsive.isPhone(),
                                              'menu-shadow' : menu.opened && !menu.lockmenu,
                                              'fixed' : menu.lockmenu && !responsive.isPhone()}" [isDesktop]="true"
                            [refService]="refSercice">
                        </app-menu>
                        <app-app-contents
                            [ngStyle]="{'margin-left' : menu.lockmenu && !responsive.isPhone() ? '10px' : !responsive.isPhone() ? '60px' : '0px'}">
                            <div [ngTemplateOutlet]="router">
                            </div>
                        </app-app-contents>
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </app-onboarding>
    </div>
</div>

<ng-template #router>
    <router-outlet></router-outlet>
</ng-template>

<app-developer-helper #dh>
    <mat-tab-group>
        <mat-tab label="SUBSCRIPTION">
            <ng-template matTabContent>
                <div class="subscriptions">
                    <button mat-raised-button color="primary" (click)="subS.startFreeTrialDev()">FREE TRIAL</button>
                    <button mat-raised-button color="warn" (click)="subS.endFreeTrialDev()">FREE TRIAL END</button>
                    <p></p>
                    <button mat-raised-button (click)="subS.startProDev()">
                        SUBSCRIBED USER
                        <mat-icon class="c_y">star</mat-icon>
                    </button>
                    <button mat-raised-button color="blue" (click)="subS.cancelProDev()">
                        CANCEL PRO SUBSCRIPTION
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-raised-button color="blue" (click)="subS.canceledProDev()">
                        PRO SUBSCRIPTION ENDED
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </ng-template>
        </mat-tab>


        <mat-tab label="ROLE">
            <ng-template matTabContent>
                <button (click)="setRole(re.ADMIN)">ADMIN</button>
                <button (click)="setRole(re.ADVISER)">ASESOR</button>
                <button (click)="setRole(re.MECHANIC)">MECÁNICO</button>
            </ng-template>
        </mat-tab>

        <mat-tab label="DESIGN">
            <button mat-flat-button color="warn" (click)="goFrontendStandards()">Frontend Design</button>
        </mat-tab>

        <mat-tab label="LOCAL STORAGE">
            <ng-template matTabContent>
                <div *ngFor="let kv of items">
                    <p class="fw600 nmb">{{kv[0]}}</p>
                    <p>{{kv[1]}}</p>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="ENDPOINTS">
            <lib-endpoint-viewer [er]="dh.endpointResponses"></lib-endpoint-viewer>
        </mat-tab>
        <mat-tab label="ERRORS">
            <lib-error-viewer [er]="dh.errors"></lib-error-viewer>
        </mat-tab>
    </mat-tab-group>
</app-developer-helper>

<app-entry-point-listener></app-entry-point-listener>