import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService, ParameterStateComponent, RouterService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Product } from 'src/app/core/models/M_Product';
import { AlreadyExistsService } from 'src/app/core/services/already-exists.service';
import { ParamsService } from 'src/app/core/services/params.service';
import { ViewPath } from 'src/app/app-routing.module';
import { SIGAUS_PRICE_LITER, SIGNUS_ARRAY, max_buy_price, max_price } from 'src/app/core/constants/constants';
import { ProductCategory } from 'src/app/core/enums/ProductCategory';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../profile/user-service';

export interface I_ProductEdit { product: M_Product, action_id?: number, draft_id?: number };

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent extends ParameterStateComponent {

  isEdit = false;
  loaded = false;
  v = ViewPath;
  p: M_Product | undefined;
  refOnEdit: string | undefined;
  productCategory = ProductCategory;
  existingReference: string | undefined;
  SIGNUS_ARRAY = SIGNUS_ARRAY;
  SIGAUS = SIGAUS_PRICE_LITER;

  public form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private fs: FormService,
    private apiS: ApiService, routerS: RouterService, route: ActivatedRoute,
    private params: ParamsService, private existsS: AlreadyExistsService, public userS: UserService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: I_ProductEdit,
    @Optional() public dialogRef: MatDialogRef<CreateProductComponent>) {
    super(routerS, route, ["product"]);
    this.form = this.formBuilder.group({
      reference: ['', [Validators.required, this.alreadyExisting()]],
      name: ['', [Validators.required]],
      price: ['', [Validators.required, Validators.min(0), Validators.max(max_price)]],
      buy_price: ['', [Validators.required, Validators.min(0), Validators.max(max_buy_price)]],
      tax: [userS.companyTax],
      stock: ['', [Validators.min(0), Validators.required]],
      category: [''],
      extra_field: ['']
    });
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }

  ngAfterViewInit(): void {
    if (this.isOnDialog) {
      this.onParam("product", this.data.product.product_id);
    }
  }

  resetExtraField() {
    this.form.patchValue({ extra_field: null });
  }

  getSignus() {
    let val = this.form.get('extra_field')?.value;
    if (val) { return SIGNUS_ARRAY.find(s => s.id == val); }
    return undefined;
  }


  alreadyExisting(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this.existingReference && this.existingReference == control.value) {
        return { alreadyExisting: true }
      }
      else {
        return null;
      }
    }
  }

  isCategory(cat: ProductCategory) {
    let currentVal = this.form.get('category')?.value;
    return currentVal == cat;
  }

  override onParam(_k: any, v: any) {
    this.apiS.getProductById(v).then(res => {
      this.isEdit = true;
      if (res) {
        this.fillDataWithProduct(res);
      }
    })

    this.form.get('price')?.hasError('required')
  }

  override noParams(): void {
    if (!this.isOnDialog) {
      this.loaded = true;
    }

  }

  checkRef() {
    let ref = this.form.get('reference')!;
    if (ref.valid && ref.value != this.refOnEdit) {
      this.apiS.checkReference(ref.value).then(res => {
        if (res instanceof M_Product) {
          this.existingReference = ref.value;
          this.existsS.show({
            title: "¡Atención!",
            message: "Ya existe un producto con la misma referencia",
            message2: "Recordamos que no se pueden crear dos productos con la misma matrícula",
            value: ref.value,
            view: this.v.productDetails,
            param_id: res.product_id!,
            accept_text: "Ver producto"
          })

          this.form.get("reference")?.updateValueAndValidity();

        }
      })
    }
  }

  fillDataWithProduct(p: M_Product) {
    this.form.patchValue({
      name: p.name,
      reference: p.reference,
      price: p.price,
      tax: p.tax,
      discount: p.discount,
      recycle: p.recycle,
      stock: p.stock,
      category: p.category,
      buy_price: p.buy_price,
      extra_field: p.extra_field ? typeof p.extra_field == "number" ? p.extra_field : p.extra_field.id : undefined
    })
    this.refOnEdit = p.reference
    this.p = p;
    this.isEdit = true;
    this.loaded = true;
  }

  create() {
    if (this.fs.isOk(this.form)) {
      if (this.isEdit) {
        let p = new M_Product(this.form.getRawValue());
        p.product_id = this.p?.product_id;
        p.line_id = this.p?.line_id;
        this.apiS.editProduct(p, this.data.action_id, this.data.draft_id).then(_resp => {
          if (this.isOnDialog) {
            this.dialogRef.close(p);
          }
          else {
            this.params.go(this.v.productDetails, p.product_id!);
          }
        })
      }
      else {
        this.apiS.createProduct(new M_Product(this.form.value)).then(_resp => {
          this.routerS.goTo(this.v.products)
        })
      }
    }
  }

  goBackProduct() {
    if (this.p && this.p.product_id) {
      this.params.go(ViewPath.productDetails, this.p.product_id);
    }
  }

}
