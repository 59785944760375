<div *ngIf="!destroyed"
    [ngClass]="{'product-line-phone' : productsLineS.phoneVersion, 'product-line-desktop' : !productsLineS.phoneVersion}">
    <div [class]="'search ' + (classSearch? classSearch : '')" *ngIf="currentFase == 'search'">
        <app-class-searcher [masterClass]="product" [required]="false" [showLeftNumber]="showSearchStock"
            [specialRow]="'Producto personalizado'" [width100]="true" [disableIf]="disabledIf.bind(this)"
            [searchPlaceHolder]="'Buscar producto'" (onSelect)="emitSelectProduct($event)"
            (onSpecialRow)="createProductDialog($event)">
        </app-class-searcher>
        <div class="remove">
            <button (click)="destroy()" mat-icon-button>
                <mat-icon class="c_t2">delete</mat-icon>
            </button>
        </div>
    </div>

    <div [ngClass]="{'mt10 mb10' : !isOr, 'aboned' : p?.aboned}" *ngIf="currentFase == 'product'">
        <div class="df" *ngIf="p != undefined; else loading">
            <div style="width: 100%;">
                <app-card [contentLoaded]="true" class="no-border product-line-card">
                    <span *ngIf="addedByParam" class="c_g">Añadido ahora</span>

                    <!-- Product line title on phone -->
                    <app-product-line-title *ngIf="productsLineS.phoneVersion"></app-product-line-title>


                    <div class="product-line-table-cell"
                        [ngClass]="{'invoiced' : !canModify, 'no-last-item' : !canModify}">

                        <!-- Product line title on desktop -->
                        <div class="df jcc aic fww first-product-line" *ngIf="!productsLineS.phoneVersion">
                            <app-product-line-title></app-product-line-title>
                        </div>

                        <div class="form-units" [ngClass]="{browserClass: true}">
                            <app-add-remove #ar [showSaveIcon]="false" [minValue]="1" class="add-remove-add-product"
                                [formClass]="'small-form-field'" [initValue]="getQuantity()" [apparence]="'outline'"
                                [showLabel]="productsLineS.phoneVersion" [label]="getLabel()" [isTime]="isTime"
                                (onInputFocusOut)="onFocusOutAddRemove()" (onModification)="checkStock($event)"
                                [required]="false" [addRemoveOutside]="false" [canModify]="canModify"
                                [hintclass]="remainingStock() <= 0 ? 'c_r fw500' : 'c_t fw500'" [hint]="getHint()">
                            </app-add-remove>
                        </div>
                        <div class="form-discount">
                            <mat-form-field appearance="outline" class="small-form-field" [floatLabel]="'always'">
                                <mat-label *ngIf="productsLineS.phoneVersion">Descuento</mat-label>
                                <input #input type="number" matInput [ngModel]="p.discount" [formControl]="fcDiscount"
                                    (input)="onDiscountInput($event)" (focus)="onFocus(input)"
                                    (keydown)="validateMinus($event)" (focusout)="onFocusOut()" [disabled]="!canModify">
                                <span matSuffix>%</span>
                                <mat-hint *ngIf="showTooltip">Valor máx. : 100</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-price-unit-hour">
                            <mat-form-field appearance="outline" class="small-form-field">
                                <mat-label *ngIf="productsLineS.phoneVersion">{{isTime ? isInterno ? 'Coste hora' :
                                    'Precio hora' : isInterno ? 'Coste unidad' : 'Precio unidad'}}</mat-label>
                                <input [ngClass]="{'newPrice' : p.discount}"
                                    [disabled]="!canModify" matInput 
                                    [ngModel]="isInterno && !isTime? p.buy_price : p.price"
                                    [formControl]="fcPrice"
                                    type="number"
                                    (input)="onPriceLineInput($event)">
                                <span matSuffix>€</span>
                                <mat-hint *ngIf="p.discount" [ngClass]="p.discount ? 'slash' : ''" money
                                    [val]="getPriceUnit"></mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-total">
                            <mat-form-field appearance="outline" class="small-form-field">
                                <mat-label *ngIf="productsLineS.phoneVersion">Total</mat-label>
                                <input [disabled]="true" matInput [value]="totalCurrency">
                                <mat-hint *ngIf="showHintTotal">
                                    {{productExtraFieldName}} :
                                    <span money [val]="getTotalExtraField()"></span>
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!productsLineS.phoneVersion" class="final-product-line">
                            <div class="df aib jcc">
                                <!-- DELETE ICON -->
                                <button (click)="destroy()" mat-icon-button *ngIf="canModify">
                                    <mat-icon class="c_t2i">delete</mat-icon>
                                </button>
                                <!-- DRAG HANDLE -->
                                <div [ngTemplateOutlet]="drag"></div>
                            </div>

                        </div>
                    </div>
                </app-card>
            </div>

        </div>
        <ng-template #loading>
            <app-skeleton [fullW]="true" [height]="100"></app-skeleton>
        </ng-template>
    </div>

</div>



<ng-template #drag>
    <ng-content></ng-content>
</ng-template>