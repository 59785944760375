import { Component } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { M_TaskAppointment } from '../../models/M_TaskAppointment';

@Component({
  selector: 'app-task-appointment',
  templateUrl: './task-appointment.component.html',
  styleUrls: ['./task-appointment.component.css']
})
export class TaskAppointmentComponent {
  selectedTasks: M_TaskAppointment[] = [];
  tasks: M_TaskAppointment[] = [];
  secciones = [
    { nombre: 'Revisiones', tareas: [] },
    { nombre: 'Cambios', tareas: [] },
    { nombre: 'Reglajes', tareas: [] },
  ];
  
  constructor(private apiS: ApiService) { }

  ngOnInit(): void {
    this.apiS.getTasksAppointment().then(res => {
      this.tasks = res;
    });
  }

  onTaskChange(task: M_TaskAppointment, isChecked: boolean): void {
    if (isChecked) {
      this.selectedTasks.push(task);
    } else {
      const index = this.selectedTasks.findIndex(t => t.id === task.id);
      if (index !== -1) {
        this.selectedTasks.splice(index, 1);
      }
    }
  }
  isCheckboxChecked(event: Event): boolean {
    return (event.target as HTMLInputElement).checked;
  }
}
