import { ChangeDetectorRef, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { CustomFile, FileUploadComponent } from '@sinigual/angular-lib';
import { EditOrComponent } from '../edit-or/edit-or.component';
import { OrFormComponent } from 'src/app/core/components/or-form/or-form.component';
import { NoteComponent } from 'src/app/core/components/note/note.component';

@Component({
  selector: 'app-or-notes-and-images',
  templateUrl: './or-notes-and-images.component.html',
  styleUrls: ['./or-notes-and-images.component.css']
})
export class OrNotesAndImagesComponent implements OnInit {
  @Input({ required: true }) orFormComponent!: OrFormComponent;
  @ViewChild(FileUploadComponent, { static: true }) fileupload!: FileUploadComponent;
  @ViewChild(NoteComponent, { static: true }) noteCompoenent!: NoteComponent;

  constructor(@Optional() public editORComponent: EditOrComponent, private chdRef: ChangeDetectorRef) { }

  ngOnInit(): void { }

  get allInvoiced() {
    return this.editORComponent ? this.editORComponent.allInvoiced : false;
  }

  hideUploadImage(fuc : FileUploadComponent){
    if (Array.isArray(fuc.uploadedFiles)) {
      return fuc.uploadedFiles.length == fuc.maxImagesLength;
    }
    return false;
  }

  onUploadImage(event: CustomFile) {
    if (this.editORComponent && !this.editORComponent.allInvoiced) {
      this.editORComponent.orS.uploadImage(this.editORComponent.action!, event);
    }
  }

  onuserPutNotes(e: any) {
    this.orFormComponent.form.patchValue({ notes: e.target.innerText })
  }

  onUserSpeach(val: string) {
    this.orFormComponent.form.patchValue({ notes: val })
    this.chdRef.detectChanges();
    this.orFormComponent.form.updateValueAndValidity();
    if (this.editORComponent) { this.editORComponent.chdRef.detectChanges(); }
  }

}
