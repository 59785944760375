import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QrDialogComponent } from '../qr-dialog/qr-dialog.component';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TimeService } from '../../services/time.service';
import { CustomTime, ResponsiveService, SnackService } from '@sinigual/angular-lib';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { M_AppointmentConfig } from '../../models/M_AppointmentConfig';
import { ApiService } from '../../api/api.service';
import { ParamsService } from '../../services/params.service';
import { ViewPath } from 'src/app/app-routing.module';

@Component({
  selector: 'app-appointment-settings',
  templateUrl: './appointment-settings.component.html',
  styleUrls: ['./appointment-settings.component.css']
})

export class AppointmentSettingsComponent {
  selectsArray: MatSelect[] = [];
  public companyForm: UntypedFormGroup;
  COMPANY_HOURS: [CustomTime[], CustomTime[], CustomTime[], CustomTime[]] = [[], [], [], []];
  @ViewChild('select4') select_ca!: MatSelect;
  @ViewChild('select1') select_om!: MatSelect;
  @ViewChild('select2') select_cm!: MatSelect;
  @ViewChild('select3') select_oa!: MatSelect;
  config: M_AppointmentConfig | undefined;
  disabled = false;
  max = 30;
  min = 5;
  showTicks = false;
  value = 0;
  constructor(private d: MatDialog, public dialogRef: MatDialogRef<AppointmentSettingsComponent>, private timeService: TimeService, private cdRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder, private apiS: ApiService, private paramS: ParamsService, private snackService : SnackService,
    public responsiveS : ResponsiveService) {

    this.companyForm = this.formBuilder.group({
      morning_work_schedule_start: ['', [Validators.required]],
      morning_work_schedule_finish: ['', [Validators.required]],
      afternoon_work_schedule_start: [''],
      afternoon_work_schedule_finish: [''],
      range_of_days: ['1', [Validators.required]],
      granularity: ['', [Validators.required]],
      total_appointment: [''],
      company_id: ['', Validators.required],
      id: ['']
    });


  }
  
  ngOnInit(): void { }

  openQRDialog(url? : string) {
    if (!url){
      this.snackService.show("Ha ocurrido un problema generando el QR.")
      this.dialogRef.close();
    }
    this.d.open(QrDialogComponent, { data: url, autoFocus: false });
  }

  get valueSlider() {
    return this.value ? this.value.toString() : '';
  }
  getTooltip(slider: number, apertura: MatSelect) {
    let str = "";
    let startTime = apertura.value;
    for (let i = 0; i <= 3; i++) {
      str += this.timeService.parseNumberToHour(startTime).getText() + (i == 3 ? '' : ", ");
      startTime += Number(slider);
    }
    return str;
  }
  inputChanged(evnt: MatSelectChange, selectId: number) {
    this.correctProgressiveBigger();
    this.generateInputs();
    this.correctRequired();
  }
  get someAfternoon() {
    if (this.selectsArray.length) {
      return this.selectsArray[2].value || this.selectsArray[3].value
    }
    return false;
  }
  ngAfterViewInit() {
    this.selectsArray = [this.select_om, this.select_cm, this.select_oa, this.select_ca];
    this.apiS.configCompanyAppointment().then(resp => {
      this.config = resp;
      this.generateInputs();
      this.companyForm.patchValue(resp);

    });

    this.cdRef.detectChanges();
  }

  correctRequired() {
    var f1 = this.companyForm.get('afternoon_work_schedule_start');
    var f2 = this.companyForm.get('afternoon_work_schedule_finish');

    if (this.someAfternoon) {
      f1?.setValidators(Validators.required);
      f2?.setValidators(Validators.required);
    }
    else {
      f1?.removeValidators(Validators.required);
      f2?.removeValidators(Validators.required);
    }

    f1?.updateValueAndValidity();
    f2?.updateValueAndValidity();
    this.cdRef.detectChanges();
  }
  getStartHour(i: number) {
    if (i == 0 || !this.selectsArray[i - 1].value) { return 0 }
    return this.selectsArray[i - 1].value;
  }
  generateInputs() {
    let maxHour = 60 * 24;
    let granularity = 30;
    for (let i = 0; i < this.selectsArray.length; i++) {
      let hoursavailable: CustomTime[] = []
      let startHour = this.getStartHour(i);
      for (let i = startHour; i < maxHour; i += granularity) {
        if (i < maxHour) {
          hoursavailable.push(this.timeService.parseNumberToHour(i));
        }
      }
      this.COMPANY_HOURS[i] = hoursavailable;
    }
  }
  /** If the company shcedule is correct like*/
  correctProgressiveBigger() {
    var ok = true;
    for (let i = 0; i < this.selectsArray.length; i++) {
      if (i != 0) {
        if (this.selectsArray[i - 1].value > this.selectsArray[i].value || this.selectsArray[i - 1].value == undefined) {
          ok = false;
        }
        if (ok == false) {
          this.selectsArray[i].value = "";
        }
      }
    }
    return ok;
  }
  updateSettings() {
    if (this.config?.id) {
      this.companyForm.patchValue({ id: this.config.id });
    }
    this.apiS.updateConfigAppointment(this.companyForm.value).then(res => {
      this.companyForm.patchValue(res);
    });
  }

  goClientSideAppointments() {
    //this.dialogRef.close();
    this.paramS.go(ViewPath.appointmentclientside, this.config!.token, undefined, true);
  }

  copyToClippboard() {
    if (this.config == undefined){return;}
    navigator.clipboard.writeText(this.config.url);
    this.snackService.show("Enlace copiado en el portapapeles")
  }

}
