import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ExitSaveChanges } from '../interfaces/ExitSaveChanges';
import { ConfirmExitDialogComponent } from '../components/confirm-exit-dialog/confirm-exit-dialog.component';

export class DeactivateGuard  {
    canDeactivate(component: ExitSaveChanges, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (nextState) {
            if (component.showSaveExitDialog() && !component.forceExit) {
                let url = nextState.url;
                component.d.open(ConfirmExitDialogComponent, { data: url, disableClose: true, autoFocus: false }).afterClosed().subscribe(res => {
                    if (res == "exit-save") {
                        component.saveChangesBeforeExit();
                        component.forceExit = true;
                        component.router.navigateByUrl(url);
                    }
                    else if (res == "exit") {
                        component.forceExit = true;
                        component.router.navigateByUrl(url);
                    }
                });
                return false;
            }
        }
        return true;
    }
}