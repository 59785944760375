import { Component } from '@angular/core';

@Component({
  selector: 'app-buy-vehicle',
  templateUrl: './buy-vehicle.component.html',
  styleUrls: ['./buy-vehicle.component.css']
})
export class BuyVehicleComponent {

}
