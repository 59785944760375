import { Injectable } from '@angular/core';
import { SessionService, WorkloadData, WorkloadInterface } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../api/api.service';
import { endpoints } from '../api/Enpoints';
import { UserService } from 'src/app/views/profile/user-service';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  workloadData!: WorkloadData;
  constructor(private apiS: ApiService, private userS: UserService, private sessionS: SessionService) {
    this.refresh();
  }

  refresh() {
    var configuration: WorkloadInterface = {
      defaultView: "or",
      switch: this.userS.isMechanic ? false : true,
      general: {
        users: this.apiS.users.bind(this.apiS),
        company: this.apiS.company.bind(this.apiS)
      },
      or: {
        schedules: this.apiS.schedules.bind(this.apiS),
        updateSchedule: endpoints.updateSchedule,
        reorderAction: endpoints.reorderActions,
        assignAction: endpoints.assignAction,
        userPermission: true,
        views: {
          editOr: ViewPath.editOr,
          createOr: undefined
        },
        draggable: {
          day: this.userS.adminOrAdviser,
          week: this.userS.adminOrAdviser,
          month: this.userS.adminOrAdviser,
        },
        canUserAssign: this.userS.adminOrAdviser,
        preFilteredUser: this.userS.isMechanic ? this.sessionS.getId() : undefined
      },
      appointments: {
        appointments: this.apiS.appointments.bind(this.apiS),
        updateAppointment: endpoints.changeAppointmentDate,
        userPermission: this.userS.adminOrAdviser,
        views: {
          createAppointment: ViewPath.createappointment,
          editAppointment: ViewPath.editAppointment
        },
        draggable: {
          day: true,
          week: true,
          month: true,
        }
      }
    }

    this.workloadData = new WorkloadData(configuration)
  }


  get wd() {
    return this.workloadData;
  }
}
