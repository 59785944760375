import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmData, ConfirmDialogService, SessionService, sumTimes } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_ORTime } from 'src/app/core/models/M_ORTime';
import { M_User } from 'src/app/core/models/M_User';
import { UserService } from 'src/app/views/profile/user-service';

@Component({
  selector: 'app-history-time-or-dialog',
  templateUrl: './history-time-or-dialog.component.html',
  styleUrls: ['./history-time-or-dialog.component.css']
})
export class HistoryTimeOrDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<HistoryTimeOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { or: M_Action, workers: M_User[], canRemove: boolean | undefined },
    private apiS: ApiService, private confirmD: ConfirmDialogService, public userS: UserService, public sessionS: SessionService) { }

  ngOnInit(): void { }

  canDelete(user_id: number) {
    let isMe = this.sessionS.isMe(user_id);
    return (this.userS.adminOrAdviser || isMe) && this.data.canRemove;
  }

  getUserByTime(time: M_ORTime) {
    return this.data.workers.find(w => w.id == time.user_id)
  }

  deleteTimeLine(id: number) {
    var v: ConfirmData = {
      title: "Eliminar registro",
      body: "¿Seguro que quieres eliminar este registro del historial?",
      showCancel: true,
      type: "danger"
    }

    this.confirmD.show(v).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.deleteTimeLine(id)
          .then(res => {
            this.data.or.removeTime(id);
          })
          .catch(e => {
            this.dialogRef.close(new Error(e))
          })
      }
    })
  }

  getTotalTime() {
    return sumTimes(this.data.or.timers.map(timer => timer.elapsed), { prefix: "short" })
  }
}
