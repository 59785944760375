import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { User } from './td';
import { animate, keyframes, transition, trigger } from '@angular/animations';
import * as kf from './keyframe';
import * as appointmentData from './appointment.json';
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-hour-appointment-tndr',
  templateUrl: './hour-appointment-tndr.component.html',
  styleUrls: ['./hour-appointment-tndr.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => swiperight', animate(750, keyframes(kf.swiperight))),
      transition('* => swipeleft', animate(750, keyframes(kf.swipeleft)))
    ])
  ]
})
export class HourAppointmentTndrComponent {
  public users: User[] = appointmentData;
  public index = 0;
  @Input()
  parentSubject: Subject<any> | undefined;
  animationState: string | undefined;
  constructor() { }

  ngOnInit() {
    this.parentSubject!.subscribe(event => {
      this.startAnimation(event)
    });
  }

  startAnimation(state:string) {
    if (!this.animationState) {
      this.animationState = state;
    }
  }

  resetAnimationState(state:AnimationEvent) {
    this.animationState = '';
    this.index++;
  }


  ngOnDestroy() {
    this.parentSubject!.unsubscribe();
  }
}
