<div class="df jcc advanced-details-container">
    <div class="user">
    <app-card [contentLoaded]="u != undefined">
        
        <div class="parent" *ngIf="u != undefined">
            <app-circular-letter [imageUpload]="true" [too] [checkIsMe]="false" [big]="true" [user]="u!"
                [showFullName]="false" (onUploadImage)="uploadImage($event)"></app-circular-letter>
            <p class="fsl fw400 mt10" style="margin-top: 5px">{{u.name}}</p>
            <p>{{u.email}}</p>
            
            
            <button style="margin-top: 10px;" mat-stroked-button (click)="logOut()">
                Cerrar sesión
            </button>
            <div class="codeEmp">
            <p class="c_t2 p10">Código Empresa: <span >{{u.company_code}}</span></p>
            </div>
        </div>
    </app-card>
    </div>
    <div class="Details">
        <app-card>
            
                <app-card-title>Cambiar contraseña</app-card-title>
                <form>
                    <app-password-input [label]="'Contraseña'" #pass [form]="form" formCName="pass1"></app-password-input>
                    <app-repeat-passowrd-input [label]="'Repite la contraseña'" #pass2 class_="mt-20" [otherPass]="pass" [form]="form" formCName="pass2">
                    </app-repeat-passowrd-input>
                    <div class="df jcc">
                        <button color="warn" style="margin-top: 20px;" mat-flat-button
                            (click)="updatePass(pass.value)" [disabled]="pass2.getDisabled() || passChanged">
                            Cambiar contraseña
                        </button>
                    </div>
                </form>
        </app-card>
    </div>
    <div class="holidays">
        <app-card [contentLoaded]="u != undefined">
            <app-card-title>Días festivos</app-card-title>
            <app-holidays-calendar [selfSave]="true" [prespective]="'user'" [profileView]="true"
                [enabledFor]="enabledCalender" [showUnsaved]="false" [showLegend]="true"
                [enabledFor]="enabledCalender"></app-holidays-calendar>

        </app-card>
    </div>

</div>