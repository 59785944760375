import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Filter, FilterOption, PageStructureComponent, RouterService, SliderFilter, TagFilter, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { M_Product } from 'src/app/core/models/M_Product';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { AddRemoveComponent } from './add-remove/add-remove.component';
import { feature } from 'src/app/core/features-controller/FeaturesController';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Product>;
  @ViewChild(AddRemoveComponent) arc?: AddRemoveComponent;
  v = ViewPath;
  f = filter;
  features = feature;
  products: M_Product[] = [];
  filters: Filter[] | undefined = undefined
  refreshReference = false;

  constructor(private apiS: ApiService, public routerS: RouterService, public params: ParamsService, private chdRef: ChangeDetectorRef, public subS: SubscriptionService) {
    this.getProducts();
  }

  getProducts() {
    this.apiS.products().then(resp => {
      if (this.ps) {
        this.filters = [new SliderFilter("Precio unidad", Math.max(...resp.map(o => o.price)), 'price'),
        new SliderFilter("Coste", Math.max(...resp.map(o => o.buy_price)), 'buy_price'),
        new SliderFilter("Stock", Math.max(...resp.map(o => o.stock)), 'stock'),
        new TagFilter("Categoría", undefined,
          new FilterOption("General", "barcode"),
          new FilterOption("Recambios", "build_circle"),
          new FilterOption("Neumáticos", "tire_repair"),
          new FilterOption("Electrónica", "lightbulb"),
          new FilterOption("Aceite", "water_drop"),
          new FilterOption("Boutique", "checkroom"),
        ),]
        this.ps.initTable(resp);
      }
    });
  }

  ngOnInit(): void { }

  saveNewStock(p: M_Product, stock: number) {
    this.apiS.editStock(p, stock).then(_res => {
      this.chdRef.detectChanges();
    })
  }

  afterSelectProduct(v: M_Product) {
    this.refreshReference = true;
    this.chdRef.detectChanges();
    this.refreshReference = false;
    this.chdRef.detectChanges();
  }

  productIcon(b: M_Product) {
    return b.icon;
  }
}
