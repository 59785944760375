import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { TranslateManagerService } from '../../services/translate-manager.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit {

  @ViewChild("select") select!: MatSelect;
  constructor(public translateS: TranslateService, public translateM: TranslateManagerService) { }

  ngOnInit(): void { }

  ngAfterViewInit() { }

  getTranslationName(lang: string) {
    return 'languages.' + lang
  }

  getFlagIcon(lang: string) {
    if (lang == "en") {
      return "us";
    }
    return lang;
  }

  onChangeSelect(val: string) {
    this.translateM.saveUserLanguage(val);
    this.translateS.use(val)
  }

}
