import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentStatus, ConfirmDialogService, DayFilter, EnumFilter, Filter, FilterOption, PageStructureComponent, RouterService, SessionService, TagFilter, getAccentColor, getPrimaryColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { ParamsService } from 'src/app/core/services/params.service';
import { M_Appointment } from 'src/app/core/models/M_Appointment';
import { AppointmentSettingsComponent } from 'src/app/core/components/appointment-settings/appointment-settings.component';
import { AppointmentInterface } from '@sinigual/angular-lib/lib/interface/AppointmentInterface';
import { ModifyAppointmentHourComponent } from 'src/app/core/components/modify-appointment-hour/modify-appointment-hour.component';
import { M_AppointmentConfig } from 'src/app/core/models/M_AppointmentConfig';
import { APPD_markAsCanceled, APPD_markAsDone, APPD_markAsPending } from 'src/app/core/constants/constants';
import { AppointmentToOrComponent } from '../appointment-form/appointment-to-or/appointment-to-or.component';
import { M_Company } from 'src/app/core/models/M_Company';

export enum AppoPageFiltesrEnum {
  APPOINTMENT_DAY = 0,
  APPOINTMENT_STATUS = 1,
  APPOINTMENT_IS_CLIENT = 2,
  APPOINTMENT_ORIGIN = 2
}

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {

  appo_status = AppointmentStatus;

  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Appointment>;
  v = ViewPath;
  comp?: M_Company;
  config = M_AppointmentConfig;
  f = filter;
  primary = getPrimaryColor;
  accent = getAccentColor;

  filters: Filter[] = [
    new DayFilter("Fecha cita", undefined, true).setId(AppoPageFiltesrEnum.APPOINTMENT_DAY),
    new TagFilter("Estado cita", AppointmentStatus, new FilterOption("Pendiente"), new FilterOption("Hecha"), new FilterOption("Cancelada")).setId(AppoPageFiltesrEnum.APPOINTMENT_STATUS),
    new EnumFilter("Cliente", new FilterOption("Registrado", "how_to_reg"), new FilterOption("No registrado", "no_accounts")).setId(AppoPageFiltesrEnum.APPOINTMENT_IS_CLIENT),
    new TagFilter("Origen", undefined, new FilterOption("Eina", "eina"), new FilterOption("Web cita previa", "public")).setId(AppoPageFiltesrEnum.APPOINTMENT_ORIGIN),
  ]

  constructor(public routerS: RouterService, private apiS: ApiService, private dialog: MatDialog,
    public sessionS: SessionService, public subS: SubscriptionService,
    public paramsS: ParamsService, private confirmD: ConfirmDialogService) {
    this.getAllAppoitments();
    this.company();
  }

  ngOnInit(): void { }

  getAllAppoitments() {
    this.apiS.appointment().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  appointmentIcon(a: M_Appointment) {
    return a.isClient ? "how_to_reg" : "no_accounts";
  }

  appointmentOrigin(a: M_Appointment) {
    return a.origin == "Web" ? "public" : "eina";
  }

  openSettings() {
    this.dialog.open(AppointmentSettingsComponent, { autoFocus: false });
  }
  showAppointments() {
    this.routerS.goWithQueryParams(ViewPath.showAppointments, { company: this.comp?.token }, true);
  }
  company() {
    this.apiS.company().then(res => {
      if (res) {
        this.comp = res;
      }

    });

  }
  onChangeHour(appointment: M_Appointment) {
    const dialogRef = this.dialog.open(ModifyAppointmentHourComponent, {
      data: appointment,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //A partir del resultado del dialogo, modificamos la fecha de la cita
        appointment.day = new Date(result);
        this.apiS.editAppointmentClient(appointment!, appointment!.hash).then(_res => { })
      }
    });
  }

  descartarDialogo(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsCanceled);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.canceled;
        cita.setStatus = AppointmentStatus.canceled;
        this.apiS.editAppointmentClient(cita!, cita!.hash).then(_res => {
        })
      }
    });
  }
  marcarHechaDialogo(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsDone);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.done; //Cerrada = Hecha
        cita.setStatus = AppointmentStatus.done; //Cerrada = Hecha
        this.apiS.editAppointmentClient(cita!, cita!.hash)
      }
    });
  }
  /**Botón par marcar una cita como pendiente */
  pendingDialog(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsPending);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.pending;
        cita.setStatus = AppointmentStatus.pending;
        this.apiS.editAppointmentClient(cita!, cita!.hash)
      }
    });
  }

  toOr(app: M_Appointment) {
    this.dialog.open<AppointmentToOrComponent, M_Appointment>(AppointmentToOrComponent, { data: app, autoFocus: false });
  }

  goOr(id: number | undefined) {
    if (!id) { return; }
    this.paramsS.go(ViewPath.editOr, id);
  }

}
