import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard, Views } from '@sinigual/angular-lib';
import { BillsComponent } from './views/bills/bills.component';
import { BudgetComponent } from './views/budget/budget.component';
import { ClientDetailsComponent } from './views/client-details/client-details.component';
import { ClientsComponent } from './views/clients/clients.component';
import { CreateRecoverPasswordComponent } from './views/create-recover-password/create-recover-password.component';
import { CreateBillComponent } from './views/create-bill/create-bill.component';
import { CreateClientComponent } from './views/create-client/create-client.component';
import { CreateProductComponent } from './views/create-product/create-product.component';
import { CreateVehicleComponent } from './views/create-vehicle/create-vehicle.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LinkVehicleComponent } from './views/link-vehicle/link-vehicle.component';
import { LoginComponent } from './views/login/login.component';
import { OrComponent } from './views/or/or.component';
import { CreateOr } from './views/or-create-edit/create-or/create-or.component';
import { PageNotFoundEinaComponent } from './views/page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { ProductsComponent } from './views/products/products.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RegisterComponent } from './views/register/register.component';
import { VehicleDetailsComponent } from './views/vehicle-details/vehicle-details.component';
import { VehiclesComponent } from './views/vehicles/vehicles.component';
import { CreateEditBudgetComponent } from './views/create-budget/create-edit-budget.component';
import { EditOrComponent } from './views/or-create-edit/edit-or/edit-or.component';
import { DataUploadComponent } from './core/components/data-upload/data-upload.component';
import { DeactivateGuard } from './core/guards/deactivate-guard-';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { IssuesViewComponent } from './views/issues-view/issues-view.component';
import { SubscribedUserGuard } from './core/guards/subscribed-user.guard';
import { CargaTallerParentComponent } from './views/carga-taller-parent/carga-taller-parent.component';
import { AbonoInvoiceComponent } from './views/abono-invoice/abono-invoice.component';
import { CompanyComponent } from './views/company/company.component';
import { RolesEnum, roleGroup } from './core/enums/RolesEnum';
import { environment } from 'src/environments/environment';
import { FrontendDesignComponent } from './views/frontend-design/frontend-design.component';
import { GarageComponent } from './views/garage/garage.component';
import { AppointmentsComponent } from './views/appointments/appointments.component';
import { TeamComponent } from './views/team/team.component';
import { BuyVehicleComponent } from './views/buy-vehicle/buy-vehicle.component';
import { SellVehicleComponent } from './views/sell-vehicle/sell-vehicle.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { AppointmentFormComponent } from './views/appointment-form/appointment-form.component';
import { ProvidersComponent } from './core/components/providers/providers.component';
import { AlbaranesComponent } from './core/components/albaranes/albaranes.component';
import { CreateAppointmentClientSideComponent } from './views/create-appointment-client-side/create-appointment-client-side.component';
import { LandingComponent } from './landing/landing.component';
import { ShowAppointmentsCompanyComponent } from './views/show-appointments-company/show-appointments-company.component';
import { feature } from './core/features-controller/FeaturesController';

export const ViewPath = {
  login :                   new Views("login",                  "Login", true),
  landing :                 new Views("landing",                "Landing", true),
  registerCompleted :       new Views("register-completed",     "Login", true),
  createRecoverPassword :   new Views("newpassword",            "Nueva contraseña", true),
  register :                new Views("register",               "Registrarse en Eina", true),
  appointmentclientside :   new Views("pedircitaprevia",        "Pedir cita previa",true),
  dashboard :               new Views("",                       "Dashboard", false),
  profile :                 new Views("profile",                "Perfil", false),
  clientes :                new Views("clientes",               "Clientes", false, roleGroup.adminAsesor),
  createClient :            new Views("createclient",           "Crear cliente", false, roleGroup.adminAsesor),
  vehiculos :               new Views("vehiculos",              "Vehiculos", false, roleGroup.adminAsesor),
  garage :                  new Views("garage",                 "Garaje", false, _GRIF_(roleGroup.adminAsesor,feature.vnvo)),
  buyVechicle :             new Views("buyvehicle",             "Compra de vehículo", false, _GRIF_(roleGroup.adminAsesor,feature.vnvo)),
  sellVehicle :             new Views("sellvehicle",            "Venta de vehículo", false, _GRIF_(roleGroup.adminAsesor,feature.vnvo)),
  createvehicle :           new Views("createvehicle",          "Crear Vehículo", false, roleGroup.adminAsesor),
  linkvehicle :             new Views("linkvehicle",            "Vincular Vehículo", false, roleGroup.adminAsesor),
  products :                new Views("products",               "Productos", false, roleGroup.adminAsesor),
  providers :               new Views("providers",              "Proveedores", false,_GRIF_(roleGroup.adminAsesor,feature.providers)),
  createProduct :           new Views("createproducts",         "Crear Producto", false,roleGroup.adminAsesor),
  facturas :                new Views("facturas",               "Facturas", false,roleGroup.adminAsesor),
  albaranes :               new Views("albaranes",              "Albarán", false, _GRIF_(roleGroup.adminAsesor,feature.providers)),
  createBill :              new Views("createbill",             "Crear factura", false, roleGroup.adminAsesor),
  business :                new Views("empresa",                "Empresa", false, [RolesEnum.ADMIN]),
  team :                    new Views("team",                   "Equipo", false, [RolesEnum.ADMIN]),
  userdetails :             new Views("userdetails",            "Detalles del usuario", false, [RolesEnum.ADMIN]),
  clientDetails :           new Views("clientdetails",          "Detalles del cliente", false, roleGroup.adminAsesor),
  vehicleDetails :          new Views("vehicledetails",         "Detalles del vehiculo", false, roleGroup.adminAsesor),
  productDetails :          new Views("producdetails",          "Detalles del producto", false, roleGroup.adminAsesor),
  appointments :            new Views("appointments",           "Citas", false, roleGroup.adminAsesor),
  createappointment :       new Views("createappointment",      "Crear cita", false, roleGroup.adminAsesor),
  editAppointment :         new Views("editappointment",        "Editar Appointment", false, roleGroup.adminAsesor),
  showAppointments :        new Views("showAppointments",       "Próximas citas",true),
  or :                      new Views("or",                     "Ordenes de reparación", false),
  createOr :                new Views("creteor",                "Crear OR", false),
  editOr :                  new Views("editor",                 "Editar or", false),
  budget :                  new Views("budget",                 "Presupuestos", false, roleGroup.adminAsesor),
  createEditBudget :        new Views("createeditbudget",       "Presupuesto", false, roleGroup.adminAsesor),
  cargataller :             new Views("workload",               "Carga de taller", false),
  dataupload :              new Views("dataupload",             "Importación de datos", false, [RolesEnum.ADMIN]),
  subscription :            new Views("subscription",           "Subscripción", false, [RolesEnum.ADMIN]),
  abono :                   new Views("abono",                  "Abono", false, roleGroup.adminAsesor),
  issues :                  new Views("issues",                 "Incidencias", false),
  frontend :                new Views("frontend",               "Frontend", false, environment.local || environment.dev ? [] : [-1]),
  pageNotFound :            new Views("notfound",               "Error", true),
}

const routes: Routes = [
  { path: ViewPath.login.path,                  component: LoginComponent},
  { path: ViewPath.registerCompleted.path,      component: LoginComponent},
  { path: ViewPath.landing.path,                component: LandingComponent},
  { path: ViewPath.createRecoverPassword.path,  component: CreateRecoverPasswordComponent}, 
  { path: ViewPath.register.path,               component: RegisterComponent},
  { path: ViewPath.appointmentclientside.path,  component: CreateAppointmentClientSideComponent},
  { path: ViewPath.dashboard.path,              component: DashboardComponent,        canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.profile.path,                component: ProfileComponent,          canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.clientes.path,               component: ClientsComponent,          canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.createClient.path,           component: CreateClientComponent,     canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.linkvehicle.path,            component: LinkVehicleComponent,      canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.vehiculos.path,              component: VehiclesComponent,         canActivate:[AuthGuardGuard]},
  { path: ViewPath.garage.path,                 component: GarageComponent,           canActivate:[AuthGuardGuard]},
  { path: ViewPath.buyVechicle.path,            component: BuyVehicleComponent,       canActivate:[AuthGuardGuard]},
  { path: ViewPath.sellVehicle.path,            component: SellVehicleComponent,      canActivate:[AuthGuardGuard]},
  { path: ViewPath.createvehicle.path,          component: CreateVehicleComponent,    canActivate:[AuthGuardGuard]},
  { path: ViewPath.products.path,               component: ProductsComponent,         canActivate:[AuthGuardGuard]},
  { path: ViewPath.providers.path,              component: ProvidersComponent,        canActivate:[AuthGuardGuard]},
  { path: ViewPath.createProduct.path,          component: CreateProductComponent,    canActivate:[AuthGuardGuard]},
  { path: ViewPath.facturas.path,               component: BillsComponent,            canActivate:[AuthGuardGuard]},
  { path: ViewPath.albaranes.path,              component: AlbaranesComponent,        canActivate:[AuthGuardGuard]},
  { path: ViewPath.createBill.path,             component: CreateBillComponent,       canActivate:[AuthGuardGuard]},
  { path: ViewPath.business.path,               component: CompanyComponent,          canActivate:[AuthGuardGuard]},
  { path: ViewPath.team.path,                   component: TeamComponent,             canActivate:[AuthGuardGuard]},
  { path: ViewPath.userdetails.path,            component: UserDetailsComponent,      canActivate:[AuthGuardGuard]},
  { path: ViewPath.clientDetails.path,          component: ClientDetailsComponent,    canActivate:[AuthGuardGuard]},
  { path: ViewPath.vehicleDetails.path,         component: VehicleDetailsComponent,   canActivate:[AuthGuardGuard]},
  { path: ViewPath.productDetails.path,         component: ProductDetailsComponent,   canActivate:[AuthGuardGuard]},
  { path: ViewPath.appointments.path,           component: AppointmentsComponent,     canActivate:[AuthGuardGuard]},
  { path: ViewPath.createappointment.path,      component: AppointmentFormComponent,  canActivate:[AuthGuardGuard]},
  { path: ViewPath.editAppointment.path,        component: AppointmentFormComponent,  canActivate:[AuthGuardGuard]},
  { path: ViewPath.or.path,                     component: OrComponent,               canActivate:[AuthGuardGuard]}, 
  { path: ViewPath.budget.path,                 component: BudgetComponent,           canActivate:[AuthGuardGuard]},
  { path: ViewPath.createOr.path,               component: CreateOr,                  canActivate:[AuthGuardGuard]},
  { path: ViewPath.editOr.path,                 component: EditOrComponent,           canActivate:[AuthGuardGuard], canDeactivate: [DeactivateGuard]},
  { path: ViewPath.createEditBudget.path,       component: CreateEditBudgetComponent, canActivate:[AuthGuardGuard], canDeactivate: [DeactivateGuard]},
  { path: ViewPath.cargataller.path,            component: CargaTallerParentComponent,canActivate:[AuthGuardGuard, SubscribedUserGuard]},
  { path: ViewPath.dataupload.path,             component: DataUploadComponent,       canActivate:[AuthGuardGuard, SubscribedUserGuard]},
  { path: ViewPath.subscription.path,           component: SubscriptionComponent,     canActivate:[AuthGuardGuard]},
  { path: ViewPath.abono.path,                  component: AbonoInvoiceComponent,     canActivate:[AuthGuardGuard]},
  { path: ViewPath.issues.path,                 component: IssuesViewComponent,       canActivate:[AuthGuardGuard]},
  { path: ViewPath.frontend.path,               component: FrontendDesignComponent,   canActivate:[AuthGuardGuard]},
  { path: ViewPath.showAppointments.path,       component: ShowAppointmentsCompanyComponent},
  { path: ViewPath.pageNotFound.path,           component: PageNotFoundEinaComponent}, //Error 404
  { path: '**', pathMatch: 'full',              component: PageNotFoundEinaComponent}, //Error 404

];

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


/** Get Roles if... */
function _GRIF_(roles : RolesEnum[], enableif : boolean){
  if (enableif){return roles;}
  else return [RolesEnum.NOONE]
}
