<form class="form" style="max-width: unset;" [ngClass]="{'dn_i' : missingComponent.showComponent}">
  <div class="container">
    <div class="title">
      <app-view-title>CREAR OR</app-view-title>
    </div>
    <div class="or_abstract">
      <app-card class="full-height-or">
        <app-card-title>Información general</app-card-title>
        <app-or-form #orform [isEdit]="false"></app-or-form>

        <div class="cv-details">
          <div class="df jcc">
            <app-client-vehicle-in-dialog [t]="'Información del cliente'"
              [c]="formComponent.vehicleSearcher?.selected?.client"
              [v]="formComponent.vehicleSearcher?.selected"></app-client-vehicle-in-dialog>
          </div>
        </div>

        <!-- OR NOTES AND IMAGES -->
        <app-or-notes-and-images [orFormComponent]="orform"></app-or-notes-and-images>

      </app-card>
    </div>

    <div class="or_client">
      <app-card>
        <div class="ribbon" *ngIf="formComponent.vehicleSearcher?.selected?.client?.discount">
          <span>{{formComponent.vehicleSearcher?.selected?.client?.discount}}% DTO</span>
        </div>
        <app-client-info [small]="true" [client]="formComponent.vehicleSearcher?.selected?.client" [extended]="true">
        </app-client-info>
      </app-card>
    </div>

    <div class="or_vehicle">
      <app-card>
        <app-vehicle-info [small]="true" [vehicle]="formComponent.vehicleSearcher?.selected" [extended]="false">
        </app-vehicle-info>
      </app-card>
    </div>

    <div class="or">
      <app-card>
        <app-card-title>Intervenciones</app-card-title>
        <!-- <app-tabbed-comments [form]="formComponent.form"></app-tabbed-comments> !-->
        <app-ra-groups #raGroups></app-ra-groups>
        <div class="mt20"></div>
        <mat-divider></mat-divider>
        <div class="mt20"></div>
        <div class="mt10"></div>
        <div class="df jcc">
          <button [disabled]="!orform.isFormOk || missingCompanyInfo" appTrialbutton
            class="onboarding-or-finish" color="warn" style="margin-top: 10px;" mat-flat-button
            (click)="createOr()">Crear orden de reparación
          </button>
        </div>
        <div class="mt10">
          <p class="tac c_r" *ngIf="!orform.isFormOk">Faltan campos requeridos</p>
          <p class="tac c_r" *ngIf="missingCompanyInfo" (click)="goCompany()" style="cursor: pointer;">Faltan rellenar
            la información de empresa</p>
        </div>
      </app-card>
    </div>
  </div>
</form>



<!-- MISSING COMPANY INFO COMPONENT-->
<app-missing-company-info #missingComponent [pageLoaded]="true" [model]="'ordenes de reparación'">
</app-missing-company-info>