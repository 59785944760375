<app-page-structure [masterClass]="'vehicle'" pageTitle="GARAJE" [autocompleteID]="'vehicle_garage'"
    searchLabel="Buscar vehículo" circleIcon="garage" circleIconClass="onboarding-create-garage"
    [addNewView]="v.buyVechicle" noDataCreateNew="Crear un nuevo vehículo" [data]="vehicles"
    [displayedHeader]=" ['Matrícula',   'Marca',    'Modelo', 'Propietario',    'Tipo',         'Última reparación']"
    [displayedColumns]="['license',     'brand',    'model',  'client',         'vehicletype',  'last_reception' ]"
    [specialText]="     [undefined,      undefined,  undefined,  getOwner,      getVnVo,      undefined]"
    [specialClass]="    [undefined,      undefined,  undefined,  undefined,     getVnVoClass,   undefined]"
    [preIcon]="[preIcon]" [card]="card" [lordIcon]="lordIcon" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()">
    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/sygewhdv.json" trigger="in"
            colors="primary:#121331,secondary:#ebe6ef,tertiary:#f9c9c0,quaternary:{{warn()}}" class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al vehículo'" mat-flat-button color="warn"
                (click)="params.go(v.vehicleDetails, ps.selected!.vehicle_id)">
                {{ps.selected!.getName()}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <button style="margin-top: 20px;" mat-stroked-button
                (click)="params.go(v.sellVehicle, ps.selected!.vehicle_id, 'vehicle')">
                <mat-icon>sell</mat-icon>
                Vender
            </button>
        </app-card>
    </ng-template>
</app-page-structure>