import { Component, Inject } from '@angular/core';
import { M_User } from '../../models/M_User';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserformComponent } from '../userform/userform.component';
import { SpaceRemoverService } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.css']
})
export class EditUserFormComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: M_User, private spaceRemover: SpaceRemoverService, private apiS: ApiService,
    public dialogRef: MatDialogRef<EditUserFormComponent>) { }

  editUser(u: UserformComponent, usr: M_User) {
    let newUser = new M_User(u.form.getRawValue());
    newUser.id = usr.id;
    this.spaceRemover.check(usr.name);
    this.apiS.updateUser(newUser).then(_res => {
      this.dialogRef.close(newUser);
    })
  }
}
