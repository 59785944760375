<app-card [contentLoaded]="loaded" class="appointment-client-side" [ngClass]="{'dn' : invalidToken}">
    <div class="form" [ngClass]="{'dn': created}">
        <div class="company-header">
            <img *ngIf="config!=undefined" class="test" src="{{config.company_logo}}">
            <div class="tac">
                <p class="mt20"></p>
                <app-view-title class="tac">{{config?.company_name}}</app-view-title>
                <p class="mt10"></p>
                <app-card-title>Pedir cita previa</app-card-title>
            </div>
        </div>
        <div>
            <mat-stepper linear #stepper [orientation]="responsiveS.w < 800 ? 'vertical' : 'horizontal'">
                <!-- DAY AND HOUR-->
                <mat-step [stepControl]="dayHourForm">
                    <ng-template matStepLabel>Día y hora</ng-template>
                    <app-card-subtitle>Seleccione día y hora</app-card-subtitle>
                    <app-hour-picker [matFormFieldsClasses]="'w50'" [form]="dayHourForm"
                        [isClientSide]="true"></app-hour-picker>
                    <div class="df jcfe">
                        <button [disabled]="!dayHourForm.valid" mat-button matStepperNext>Siguiente</button>
                    </div>
                </mat-step>

                <mat-step [stepControl]="clientForm">
                    <app-card-subtitle>Tus datos</app-card-subtitle>
                    <ng-template matStepLabel>Tus datos</ng-template>
                    <form [formGroup]="clientForm" class="form">
                        <app-plate-input class="w100" [strict]="false" [form]="clientForm"
                            [formCName]="'license'"></app-plate-input>
                        <mat-form-field class="w100" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput placeholder="Nombre" formControlName="name_client">
                        </mat-form-field>
                        <app-email-input class="w100" [form]="clientForm" [formCName]="'email'"></app-email-input>
                        <app-phone-input class="w100" [form]="clientForm" [formCName]="'phone'"></app-phone-input>
                    </form>
                    <div class="df jcfe">
                        <button [disabled]="!clientForm.valid" mat-button matStepperNext>Siguiente</button>
                    </div>
                </mat-step>

                <mat-step>
                    <ng-template matStepLabel>Intervenciones</ng-template>
                    <div class="last-step">
                        <app-card-subtitle>Intervenciones</app-card-subtitle>
                        <div class="onboarding-create-appointment-reparations">
                            <app-comentarios-moto class="client-side-comments" [isEina]="true"
                                #motocomments></app-comentarios-moto>
                            <app-comentarios #commentsRef [showEnter]="true" [form]="reparations"
                                [label]="'Otras intervenciones'" [placeHolder]="'Otras intervenciones'"
                                [moto]="motocomments"></app-comentarios>
                        </div>
                    </div>
                    <div class="df jcc mt10"
                        [matTooltip]="!commentsRef.getCommentsAsArray().length ? 'Seleccione alguna intervención' : ''">
                        <button mat-flat-button (click)="createAppointment()"
                            [disabled]="!commentsRef.getCommentsAsArray().length">PEDIR CITA</button>
                    </div>
                </mat-step>
            </mat-stepper>
        </div>
    </div>

    <!-- EMAIL SEND-->
    <div class="container-send-email" *ngIf="created">
        <p class="pedirCitaSubtitle" style="font-weight: bold;">¡CITA CREADA CON ÉXITO!</p>
        <lord-icon class="lord-large" src="https://cdn.lordicon.com/nqisoomz.json" trigger="in"
            colors="primary:#121331,secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#3a3347">
        </lord-icon>
        <p class="tac">Revise la bandeja de entrada de su correo para más detalles</p>
        <p></p>
    </div>

</app-card>

<!-- PAGE NOT FOUND -->
<app-page-not-found-eina *ngIf="loaded && invalidToken"></app-page-not-found-eina>