import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveService, ConfirmDialogService } from '@sinigual/angular-lib';
import { ParamsService } from '../../services/params.service';
import { M_LastMovements } from '../../models/M_LastMovement';
import { M_TypePayment } from '../../models/M_TypePayment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogHistoryAdvancesComponent } from './dialog-history-advances/dialog-history-advances.component';
import { DialogAdvancePayComponent } from 'src/app/views/client-details/dialog-advance-pay/dialog-advance-pay.component';
import { ApiService } from '../../api/api.service';
import { PreviewService } from '../../services/preview.service';

@Component({
  selector: 'app-advances-array',
  templateUrl: './advances-array.component.html',
  styleUrls: ['./advances-array.component.css']
})
export class AdvancesArrayComponent implements OnInit{

  @Input() dataMostrada: M_LastMovements[] = [];
  @Input() typePayment: M_TypePayment[] =  [];
  dataSrc: MatTableDataSource<M_LastMovements> = new MatTableDataSource<M_LastMovements>([]);
  dataSrc_copy: MatTableDataSource<M_LastMovements> = new MatTableDataSource<M_LastMovements>([]);
  textoBoton: string = 'Ver más';
  TypePayment = M_TypePayment;
  constructor(public params: ParamsService,
    public responsiveS: ResponsiveService,private d: MatDialog,private confirmD: ConfirmDialogService,private apiS:ApiService,private previewS: PreviewService){}
    
    get displayedColumns() {
      return this.responsiveS.w > 514
        ? ['Ornumber', 'status', 'delivery','state','options',]
        : ['Ornumber', 'delivery','state','options'];
    }
    ngOnInit(): void {

      this.dataSrc.data.slice(0,5);
      this.initTable(this.dataMostrada);
    }
    initTable(lm:M_LastMovements[]){

      this.dataSrc = new MatTableDataSource<M_LastMovements>(lm);
      this.dataSrc_copy = new MatTableDataSource<M_LastMovements>(lm);
      const updatedData = lm.slice(0, 5);  // Modify this according to your use case

      // Assign the updated data to MatTableDataSource
      this.dataSrc = new MatTableDataSource<M_LastMovements>(updatedData);
    }
    isLastMovement(val : M_LastMovements):val is M_LastMovements {
      return val instanceof M_LastMovements; 
    }

    mostrarMasFilas() {
      // Assign the updated data to MatTableDataSource
      if (this.dataSrc.data.length === (this.dataSrc_copy.data.length)) {
        this.dataSrc= new MatTableDataSource<M_LastMovements>(this.dataSrc.data.slice(0, 5));  // Mostrar las primeras 5 filas
        this.textoBoton = 'Ver más';
      } else {
        this.dataSrc.data = this.dataSrc_copy.data; // Mostrar todas las filas
        this.textoBoton = 'Ocultar';
      }
    }
    openHistory(last_movement:M_LastMovements){
      this.d.open(DialogHistoryAdvancesComponent,{data:{historAdvances:last_movement.history_advances}});
    }
    deleteAdvances(element : M_LastMovements){
      if (element != undefined) {
        let title_ = "Eliminar Anticipo";
        let body_ = '¿Está seguro de que desea eliminar este anticipo?'
        this.confirmD.show({ title: title_, body: body_, type: "danger" }).afterClosed().subscribe(res => {
          
            this.apiS.deleteAdvanceClient(element.id).then(res=>{

                this.dataMostrada.removeElement(element);
                this.initTable(this.dataMostrada);
            });
        });
      }
    }
    editAdvances(element:M_LastMovements){
      this.d.open(DialogAdvancePayComponent,{data:{typePayment:this.typePayment,last_movement:this.dataMostrada,editLastMovement:element}}).afterClosed().subscribe(_res=>{
        if(_res){
            let new_lastMovement = this.dataMostrada.findIndex(x => x.id == _res.id);
            this.dataMostrada[new_lastMovement] = _res;
            this.initTable(this.dataMostrada);
        }
      });
    }
    previsualize(last_mv: M_LastMovements) {
      this.previewS.showPreview("AN", last_mv.id.toString());
    }
}
