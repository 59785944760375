import { Component, OnInit, ViewChild } from '@angular/core';
import { PageStructureComponent, RouterService, getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_Client } from 'src/app/core/models/M_Client';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Client>;
  v = ViewPath;
  f = filter;
  features = feature;
  constructor(apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS : SubscriptionService) {
    apiS.clients().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  ngOnInit(): void {}

  preIcon(c : M_Client){
    return c.is_company ? "business" : "person";
  }

  completeName(u: M_Client) {
    return u.getName();
  }

}
