<app-card [contentLoaded]="parent.loaded">
    <app-card-title>Configurar documentación</app-card-title>
    <div>
        <!-- Missing company info warning -->
        <p *ngIf="userS.missingCompanyInfo">
            <mat-icon class="c_o" style="vertical-align: middle;">error</mat-icon>
            Se recomienda rellenar primero la
            <span class="c_o fw500 underline_hover" (click)="changeTab.emit(0)">
                informacón general
            </span>
            de tu empresa entes de empezar con esta sección
        </p>

        <!-- Section information -->
        <p>En este apartado puedes personalizar la documentación modificando su
            <span class="fw600">pie de página</span>
            y
            <span class="fw600">campos adicionales</span>.
        </p>

    </div>
    <mat-tab-group color="warn" mat-align-tabs="center" class="mt20 onboarding-company-layouts-subtab documentation-tabs">
        <mat-tab>
            <ng-template mat-tab-label>
                PRESUPUESTO
                <span class="pdf-label"></span>
                <mat-icon *ngIf="budget.pending">circle</mat-icon>
            </ng-template>
            <div class="layout-parent">
                <app-documentation-content #budget [pdf_type]="'B'"></app-documentation-content>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                APERTURA OR
                <span class="pdf-label"></span>
                <mat-icon *ngIf="ra.pending">circle</mat-icon>
            </ng-template>
            <div class="layout-parent">
                <app-documentation-content #ra [pdf_type]="'RA'"></app-documentation-content>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                FACTURA OR
                <span class="pdf-label"></span>
                <mat-icon *ngIf="or.pending">circle</mat-icon>
            </ng-template>
            <div class="layout-parent">
                <app-documentation-content #or [pdf_type]="'OR'"></app-documentation-content>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                FACTURA RECAMBIOS
                <span class="pdf-label"></span>
                <mat-icon *ngIf="invoices.pending">circle</mat-icon>
            </ng-template>
            <div class="layout-parent">
                <app-documentation-content #invoices [pdf_type]="'I'"></app-documentation-content>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div [ngTemplateOutlet]="saveColorsAndFooters" [ngTemplateOutletContext]="{icon : 'arrow_upward'}"></div>

</app-card>

<ng-template #saveColorsAndFooters let-icon='icon'>
    <div class="df jcc mb20" [ngClass]="icon == 'arrow_upward' ? 'mt20' : ''">
        <button appTrialbutton mat-flat-button [disabled]="saveAllDisabled()" (click)="saveLayout()"
            color="warn"
            class="onboarding-company-layouts-finish">
            Guardar
        </button>
    </div>
</ng-template>