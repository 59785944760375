import { ChangeDetectorRef, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { BurgerMultipleOption, BurgerOption, BurgerService } from '@sinigual/angular-lib';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { MenuComponent } from '../menu.component';
import { ViewPath } from 'src/app/app-routing.module';
import { UserService } from 'src/app/views/profile/user-service';

@Component({
  selector: 'app-multiple-menu-item',
  templateUrl: './multiple-menu-item.component.html',
  styleUrls: ['./multiple-menu-item.component.css']
})
export class MultipleMenuItemComponent {
  @Input() data!: BurgerMultipleOption;
  @Input() class_?: string;
  @Input() parent!: MenuComponent;
  @ViewChildren(MenuItemComponent) children!: QueryList<MenuItemComponent>;
  allChilds: BurgerOption[] = []
  show: boolean = true;
  someChildHasPermission = true;
  constructor(private chdRef: ChangeDetectorRef, public bS: BurgerService, private userS: UserService) { }

  ngOnInit(): void { }

  /**Una vez hemos obtenido los hijos, miramos sus permisos
   * En el caso de que ninguno de los hijos tenga una view a la que el usuario pueda ir, este componente no se muestra
   */
  ngAfterViewInit() {
    for (let i = 0; i < this.children.length; i++) {
      this.allChilds.push(this.children.get(i)!.data!)
    }
    this.someChildHasPermission = this.bS.childsHasPermission(this.allChilds);
    this.chdRef.detectChanges();
  }

  /**Desplegar el dropdown */
  changeShow() {
    this.show = !this.show;
  }

  getClass() {
    return this.bS.isSelectedMultiple(this.allChilds) ? 'burgerSelected' : '';
  }

  getBadge(bo: BurgerOption) {
    if (bo.view == ViewPath.business) {
      return this.userS.missingCompanyInfo ? '!' : undefined;
    }
    return undefined;
  }

  getSvgIcon(bo: BurgerOption) {
    return bo.icon == "wheel" || bo.icon == "barcode";
  }
}
