import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { M_TemplateField } from '../../models/M_TemplateField';
import { ApiService } from '../../api/api.service';
import { TemplateFieldsFormComponent } from '../template-fields-form/template-fields-form.component';
import { forkJoin } from 'rxjs';
import { PDF_DATA } from '../pdf/pdf.component';
import { TemplateSteps } from '../../enums/TemplateSteps';
import { VehicleType } from '../../enums/VehicleType';

export interface TEMPLATE_DIALOG_DATA {
  steps: TemplateSteps[];
  vehicleType?: VehicleType;
}

@Component({
  selector: 'app-template-fields-dialog',
  templateUrl: './template-fields-dialog.component.html',
  styleUrls: ['./template-fields-dialog.component.css']
})
export class TemplateFieldsDialog {
  loaded = false;
  index = 0;
  fields: M_TemplateField[] | undefined;
  TS = TemplateSteps;

  @ViewChild("clientFieldsComponent") clientFieldsComponent?: TemplateFieldsFormComponent
  @ViewChild("workShopFieldsComponent") workShopFieldsComponent?: TemplateFieldsFormComponent

  constructor(private dialogRef: MatDialogRef<TemplateFieldsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { pdf: PDF_DATA, dialog: TEMPLATE_DIALOG_DATA },
    private apiS: ApiService, private chdRef: ChangeDetectorRef) {
    dialogRef.disableClose = true;

    if (this.containFieldsClientStep) {
      this.apiS.getFieldsFromDoc(data.pdf.preview_type).then(res => {
        this.fields = res;
        this.removeFieldsStep(this.fields);
        this.loaded = true;
      })
    }
    else {
      this.loaded = true;
    }
  }

  removeFieldsStep(f: M_TemplateField[] | undefined) {
    if (!f || !f.length) {
      this.data.dialog.steps.removeElement(TemplateSteps.FIELDSCLIENT);
      this.data.dialog.steps.removeElement(TemplateSteps.FIELDSWORKSHOP);
    }
    else {
      if (!this.clientFields.length) {
        this.data.dialog.steps.removeElement(TemplateSteps.FIELDSCLIENT);
      }
      if (!this.workShopFields.length) {
        this.data.dialog.steps.removeElement(TemplateSteps.FIELDSWORKSHOP);
      }
    }
  }

  closeDialog() {
    this.chdRef.detectChanges();

    let finalModels: M_TemplateField[] = [];
    let finalFileModels: M_TemplateField[] = [];

    if (this.clientFieldsComponent) {
      finalModels.push(...this.clientFieldsComponent.getModels())
      finalFileModels.push(...this.clientFieldsComponent.getFilesModels())
    }

    if (this.workShopFieldsComponent) {
      finalModels.push(...this.workShopFieldsComponent.getModels())
      finalFileModels.push(...this.workShopFieldsComponent.getFilesModels())
    }

    if (this.containFieldsClientStep || this.containFieldsWorkshopStep) {
      /** !!!! If th data contains the id (object already exists)  !!! */
      if ((this.clientFieldsComponent || this.workShopFieldsComponent) && this.data.pdf.id) {
        const a = this.apiS.saveTemplateFields(this.data.pdf.id, finalModels);
        const b = this.apiS.saveTemplateFiles(this.data.pdf.id, finalFileModels);
        forkJoin([a, b]).subscribe(res => {
          this.dialogRef.close(true);
        })
      }
      /** The object not exists yet. Close the dialog with the introduced template values */
      else {
        this.dialogRef.close({
          models: finalModels,
          files: finalFileModels
        })
      }
    }
    else {
      this.dialogRef.close(true);
    }
  }

  get dialogTitle() {
    switch (this.data.pdf.preview_type) {
      case 'B':
        return "Generación de presupuesto"
      case 'RA':
        return "Generación de OR"
      case 'OR':
        return "??"
      case 'I':
        return "Generación de factura"
      case 'FL':
        return "??"
      case 'A':
        return "Generación de abono"
      case 'AN':
        return "Generación de anticipo"
      case 'AL':
        return "??"
    }
  }

  get clientFields() {
    if (this.fields == undefined) { return [] }
    return this.fields.filter(f => !f.workshopFill)
  }

  get workShopFields() {
    if (this.fields == undefined) { return [] }
    return this.fields.filter(f => f.workshopFill)
  }

  get stepShowing(): TemplateSteps {
    try {
      return this.data.dialog.steps[this.index];
    }
    catch {
      return TemplateSteps.PREVIEW
    }
  }

  previousStep() {
    this.index -= 1;
  }

  nextStep() {
    this.index += 1;
  }

  get isEndStep() {
    return this.index == this.totalSteps - 1;
  }

  get containPreviewStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.PREVIEW });
  }

  get containFieldsClientStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.FIELDSCLIENT });
  }

  get containFieldsWorkshopStep() {
    return this.data.dialog.steps.some(s => { return s == TemplateSteps.FIELDSWORKSHOP });
  }

  get totalSteps() {
    return this.data.dialog.steps.length;
  }

}
