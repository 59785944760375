<p mat-dialog-title>Previsualización</p>
<mat-dialog-content>
    <!--
    <div *ngFor="let tab of tabs">
        <div class="df aib">
            <p class="fw500 fsm" style="margin-right : 5px;">{{tab.name}}</p>
            <app-or-type-label [tipo]="[tab.or_type]" [showText]="false"></app-or-type-label>
        </div>
        <p *ngFor="let c of tab.comments">{{c}}</p>
        <p class="c_t2" *ngIf="tab.comments.length == 0">Sin intervenciones</p>
    </div>-->
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="true" color="warn">OK</button>
</div>