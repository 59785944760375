<div *ngIf="v != undefined; else nodata">
    <div *ngFor="let vehicle of v">
        <div class="df">
            <lib-icon-text [icon]="vehicle.icon" [text]="vehicle.getName()">
                <mat-icon>remove</mat-icon>
            </lib-icon-text>
            <mat-icon (click)="move(vehicle)" [ngClass]="top? 'c_r cp' : 'c_p cp'">{{top? 'remove' : 'add'}}</mat-icon>
        </div>
    </div>
</div>

<ng-template #nodata>
    <p class="c_t2">Sin datos</p>
</ng-template>
