import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { SnackService } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/core/api/api.service';
import { DocumentationContentComponent } from 'src/app/views/company/documentation-config/documentation-content/documentation-content.component';
import { UserService } from '../../profile/user-service';
import { M_TemplateField } from 'src/app/core/models/M_TemplateField';
import { CompanyComponent } from '../company.component';

/** Footers  */
export type T_Footers = {
  footer2: string | undefined,
  footer3: string | undefined,
  footer4: string | undefined,
  footer5: string | undefined,
}

/** Save documentation structure */
export type T_SaveDocumentation = {
  footer2: string | undefined,
  footer3: string | undefined,
  footer4: string | undefined,
  footer5: string | undefined,
  template_fields: M_TemplateField[]
}

@Component({
  selector: 'app-documentation-config',
  templateUrl: './documentation-config.component.html',
  styleUrls: ['./documentation-config.component.css']
})
export class DocumentationConfigComponent {

  @Output() changeTab: EventEmitter<number> = new EventEmitter();
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  /** The configuration tabs */
  @ViewChild("budget") budgetConfig!: DocumentationContentComponent;
  @ViewChild("ra") raConfig!: DocumentationContentComponent;
  @ViewChild("or") orConfig!: DocumentationContentComponent;
  @ViewChild("invoices") invoicesConfig!: DocumentationContentComponent;
  allDocuments: DocumentationContentComponent[] = [];

  constructor(@Inject(CompanyComponent) public parent: CompanyComponent, private snackS: SnackService, private apiS: ApiService, public userS: UserService) { }


  initTab(data: { templates: M_TemplateField[], footers: T_Footers }) {
    this.allDocuments.push(this.budgetConfig, this.raConfig, this.orConfig, this.invoicesConfig);
    this.fillTemplateFields(data.templates);
    this.fillFooters(data.footers);
  }


  fillTemplateFields(templateFields: M_TemplateField[]) {
    this.budgetConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "B"));
    this.raConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "RA"));
    this.orConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "OR"));
    this.invoicesConfig.templateComponent.init(templateFields.filter(tf => tf.add_typedoc == "I"));
  }

  /** Fill all footers on screen. The filling order, depends on the footer position in the html*/
  private fillFooters(footers: T_Footers) {
    this.budgetConfig.setFooter(footers.footer2);
    this.raConfig.setFooter(footers.footer3);
    this.orConfig.setFooter(footers.footer4);
    this.invoicesConfig.setFooter(footers.footer5);
  }

  /** Cehck if some footer has pending changes */
  saveFooterDisabled() {
    return this.allDocuments?.map(f => f).every(f => f.pending == false);
  }

  /** Set 'pending = false' on all footers */
  resetPendings() {
    this.allDocuments?.forEach(f => {
      f.pending = false;
      f.templateComponent.cleanForm();
    })
  }

  /** True o fale depending of the pending changes to save (footers and colors) */
  saveAllDisabled() {
    return this.saveFooterDisabled();
  }

  /** Save colors and footers */
  saveLayout() {

    const a = this.apiS.saveFootersAndTemplateFields(this.getViewValues());
    const b = this.apiS.saveCompanyFiles(this.getFileTemplateFields());

    forkJoin([a, b]).subscribe(res => {
      this.resetPendings();
      this.updateTemplateId(res[0]);
      this.updateTemplateFilesId(res[1]);
      this.snackS.show("Configuración guardada con éxito");
    })
  }

  getViewValues(): T_SaveDocumentation {
    let value: T_SaveDocumentation = {
      footer2: this.budgetConfig.getFooter(),
      footer3: this.raConfig.getFooter(),
      footer4: this.orConfig.getFooter(),
      footer5: this.invoicesConfig.getFooter(),
      template_fields: this.getTemplateFields(),
    }
    return value;
  }


  /** Get all template fields (NON FILE TYPE FIELDS) */
  getTemplateFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getModels());
    })
    return template_fields;
  }

  /** Get all template fields (ONLY FILE TYPE) */
  getFileTemplateFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getFilesModels());
    })
    return template_fields;
  }

  /** Get all template fields (FILE AND NON FILE TYPES) */
  getAllFields() {
    var template_fields: M_TemplateField[] = [];
    this.allDocuments?.forEach((layout) => {
      template_fields.push(...layout.templateComponent.getAllModels());
    })
    return template_fields;
  }


  /**
   * Update non-file fields
  */
  updateTemplateId(res: M_TemplateField[]) {
    let currentTemplate = this.getTemplateFields();
    currentTemplate.forEach((t, index) => {
      if (res[index]) {
        t.value_id = res[index].value_id;
        t.design_id = res[index].design_id;
      }
    })
  }

  /**
   * Update file fields
   */
  updateTemplateFilesId(res: M_TemplateField[]) {
    let currentTemplate = this.getFileTemplateFields();
    currentTemplate.forEach((t, index) => {
      if (res[index]) {
        t.value_id = res[index].value_id;
        t.design_id = res[index].design_id;
        t.adv_value = res[index].adv_value; // Update always the adv_value!
      }
    })
  }

}
