import {Component} from '@angular/core';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: '[test]',
  styleUrls: ['test.component.css'],
  templateUrl: 'test.component.html',
})
export class TestComponent {

}