<div>
    <mat-toolbar color="primary">
        <button #btn (click)="onclick.emit();" onclick="this.blur()" mat-icon-button class="df aic cp br5"
            [ngClass]="{'onboarading-menu' : mc.hiddenToUser}">
            <mat-icon style="color: white;">{{mc.lockmenu && mc.isDesktop ? 'menu_open' : 'menu'}}</mat-icon>
        </button>
        <img (click)="routerS.goTo(v.dashboard)" class="logoToolbar cp" src="./assets/img/logos/eina_logo.png">
        <app-big-mama *ngIf="f.bigMama" class="gsearcher" (onclick)="onclick.emit();"></app-big-mama>
        <span class="example-spacer"></span>


        <!-- CURRENTLY WORKING OR-->
        <div class="df aic" [ngClass]="timerS.refOr != undefined ? 'or-time-appear' : 'or-time-disappear'">
            <div class="timer-container" *ngIf="timerS.refOr" (click)="timerS.goOr(timerS.refOr)" mat-ripple>
                <p class="first-text"><span class="or-prefix">OR</span> {{timerS.refOr.id_to_show}} </p>
                <p class="elapsed-time cp nmb">{{timerS.elapsed}}</p>
            </div>
            <p class="more-or-text" *ngIf="timerS.hasMore" [matMenuTriggerFor]="otherOr">{{timerS.moreNumber}} más</p>
        </div>

        <mat-menu #otherOr="matMenu">
            <div *ngFor="let or of timerS.moreOr" class="timer-container" style="margin: 5px;"
                (click)="timerS.goOr(or)">
                <p class="first-text"><span class="or-prefix">OR</span> {{or.id_to_show}} </p>
                <p class="elapsed-time cp nmb">{{timerS.orElapsed(or)}}</p>
            </div>
        </mat-menu>

        <button class="help onboarading-help-toolbar" mat-icon-button (click)="onboarding.open()">
            <mat-icon>help</mat-icon>
        </button>

        <app-notifications [class_]="'mr-20'" [autoUpdate]="false" [getAll]="apiS.getNotifications.bind(apiS)"
            [readAll]="apiS.readAllNotifications.bind(apiS)" [setReaded]="apiS.setNotificationReaded.bind(apiS)"
            (onClickNotification)="mc.close(); notificationAction($event)">
        </app-notifications>

        <button mat-icon-button (click)="goProfile()">
            <app-circular-letter class="absolute-center" *ngIf="u; else loadingProfile" [checkIsMe]="false"
                [showTooltip]="false" [user]="u" [showFullName]="false"></app-circular-letter>
            <ng-template #loadingProfile>
                <app-skeleton class="absolute-center" [circle]="true" [transparency]="true" [height]="34"
                    [width]="34"></app-skeleton>
            </ng-template>
        </button>

    </mat-toolbar>
</div>