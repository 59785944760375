import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { or_status_invoiced } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_Client } from 'src/app/core/models/M_Client';
import { ParamsService } from 'src/app/core/services/params.service';
import { PreviewService } from 'src/app/core/services/preview.service';

@Component({
  selector: 'app-invoice-flota-or-dialog',
  templateUrl: './invoice-flota-or-dialog.component.html',
  styleUrls: ['./invoice-flota-or-dialog.component.css']
})
export class InvoiceFlotaOrDialogComponent {

  /** Flotas variables */
  //loaded = false;
  //ors: M_Action[] = [];

  constructor(public dialogRef: MatDialogRef<InvoiceFlotaOrDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: M_Client, private apiS: ApiService, private previewS: PreviewService, private paramS: ParamsService) { 
    /** If flotas feature is activated... */
    /*this.apiS.getClientClosedGroups(this.data).then(res => {
      this.ors = res;
      this.loaded = true;
    })*/
  }

  invoiceAll() {
    if (this.data.orPendingArray.length) {
      this.apiS.invoiceFlotas(this.data).then(res => {
        this.previewS.showPreview("FL", res.token);
        this.dialogRef.close();
        this.data.orPendingArray.forEach(i => {
          i.status = or_status_invoiced;
        })
      })
    }
    else {
      this.dialogRef.close();
    }
  }

  goOr(action: M_Action) {
    this.paramS.go(ViewPath.editOr, action.id);
    this.dialogRef.close();
  }

  pendingGroupsTotal(or : M_Action){
    return or.closedTotal - or.totalInternosClosed
  }

}
