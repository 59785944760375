import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFile, M_BaseUser, ProfilePictureService, RouterService, SessionService, SnackService, StorageService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { EnumSubscription } from 'src/app/core/enums/EnumSubscription';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_User } from 'src/app/core/models/M_User';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { UserService } from './user-service';
import { Subject } from 'rxjs/internal/Subject';
import { calendarEnabled } from 'src/app/core/constants/constants';
import { HolidaysCalendarComponent } from 'src/app/core/components/holidays-calendar/holidays-calendar.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild(HolidaysCalendarComponent) holidayC!: HolidaysCalendarComponent;
  enabledCalender = calendarEnabled;
  name: string = "";
  email: string = "";
  passChanged = false;
  u: M_User | undefined;
  es = EnumSubscription;
  feature = feature;
  onLogOut = new Subject<any>()
  public form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private apiS: ApiService, private snackS: SnackService,
    private sessionS: SessionService, public profilePicrureS: ProfilePictureService, public subS: SubscriptionService, public userS: UserService,
    public storageS: StorageService, public routerServie: RouterService) {
    this.form = this.formBuilder.group({
      pass1: [''],
      pass2: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.apiS.profile().then(res => {
      this.u = res;
      this.holidayC.initComponent(
        {
          companyH: this.u.company ? this.u.company.holidays : [],
          userH: this.u.holidays,
          userID: this.u.id
        });
    })
  }

  initPage() {

  }

  updatePass(pass: string) {
    this.apiS.updatePass(pass).then(_res => {
      this.passChanged = true;
      this.snackS.show("Contraseña modificada con éxito");
    })
  }

  logOut() {
    this.apiS.logout().then(res => {
      this.sessionS.logOut();
    })
  }

  uploadImage(data: [cf: CustomFile, u: M_BaseUser]) {
    this.apiS.uploadImage(data[0], "profile").then(_res => { })
    this.profilePicrureS.onChangeProfilePicture(data);
  }
}
