import { Component, Inject, Input, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PreviewService } from '../../../../core/services/preview.service';
import { TemplateFieldsFormComponent } from '../../../../core/components/template-fields-form/template-fields-form.component';
import { DocumentationConfigComponent } from 'src/app/views/company/documentation-config/documentation-config.component';
import { templateTypedoc } from 'src/app/core/models/M_TemplateField';


/** Component that contains the footer and the extra fields by CCAA*/
@Component({
  selector: 'app-documentation-content',
  templateUrl: './documentation-content.component.html',
  styleUrls: ['./documentation-content.component.css']
})
export class DocumentationContentComponent {

  @Input() pdf_type!: templateTypedoc;
  @ViewChild(TemplateFieldsFormComponent) templateComponent! : TemplateFieldsFormComponent;
  public form: UntypedFormGroup;
  pending = false;

  constructor(@Inject(DocumentationConfigComponent) public parent: DocumentationConfigComponent, private formBuilder: UntypedFormBuilder, private previewS: PreviewService) {
    this.form = this.formBuilder.group({
      footer: [''],
    });

    this.form.get('footer')?.valueChanges.subscribe(_v => {
      if (this.parent) {
        this.pending = true;
      }
    })
  }

  getFooter(): string {
    return this.form.get('footer')?.value
  }

  setFooter(f: string | undefined): void {
    if (f) {
      this.form.get('footer')?.setValue(f, { emitEvent: false });
    }
  }

  showPreview() {
    this.previewS.showPreview(this.pdf_type, undefined, { c: this.parent.parent.generalInfoTab.c!, p: this.parent.parent.generalInfoTab.cp.getPrimary(), s: this.parent.parent.generalInfoTab.cp.getSecondary(), footer: this.getFooter() })
  }

}
