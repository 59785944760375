export class M_AdminCenters {
    id: number;
    client_id: number;
    company_id: number;
    post: number;
    code: string;
    name: string
    deleted: boolean;
    created_at: Date;
    updated_at: Date;

    constructor(d: any) {
        this.id = d.id;
        this.client_id = d.client_id;
        this.company_id = d.company_id;
        this.post = d.post;
        this.code = d.code;
        this.name = d.name;
        this.deleted = d.deleted;
        this.created_at = new Date(d.created_at);
        this.updated_at = new Date(d.updated_at);
    }
}