<app-page-structure [pageTitle]="'EQUIPO'" [lordIcon]="lordIcon" (onclickAddNew)="openDialog()" class="users-table"
    [masterClass]="'user'" [autocompleteID]="'users'" searchLabel="Buscar usuario" circleIcon="person_add"
    circleIconClass="onboarding-company-users-create" [addNewView]="undefined" [data]="[]"
    [displayedHeader]=" ['Nombre', 'Email', 'Rol']" [displayedColumns]="['name', 'email', 'role']"
    [circularLetter]="true" [specialText]="[undefined, undefined, roleName]" [specialClass]="[undefined, undefined]"
    [card]="card" [circleIconLoading]="!subS.status" [disableCircle]="disableCircle" [circleTooltip]="circleTooltip"
    [circleIconLoading]="loading" [showPagination]="false" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()" (onChangeSelected)="onChange()">

    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/zxgyuwqy.json" trigger="in"
            colors="primary:#121131,secondary:#faddd1,tertiary:{{primary()}},quaternary:{{warn()}}" class="lord-small">
        </lord-icon>
    </ng-template>

    <ng-template #card>
        <app-card *ngIf="ps.showItem()">
            <div>
                <div class="df fdc aic">

                    <button [matTooltip]="'Ir al usuario'" mat-flat-button color="warn"
                        (click)="params.go(v.userdetails, ps.selected!.id)">
                        {{ps.selected!.name}}
                        <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                    </button>
                    <app-card-subtitle [first]="true">{{ps.selected!.email}}</app-card-subtitle>
                    <app-circular-letter [user]="ps.selected" [showFullName]="false" [showTooltip]="false"
                        [big]="true"></app-circular-letter>
                </div>
                <app-layout-send-email #alm [temaplte]="'user'"
                    *ngIf="!ps.selected!.confirm_sent && !ps.selected!.admin; else confirmedUser"
                    [endpoint]="resendConfirm.bind(this)"></app-layout-send-email>
                <ng-template #confirmedUser>
                    <p class="tac"><mat-icon class="vam c_b mr5">verified</mat-icon>Usuario confirmado</p>
                </ng-template>
            </div>
            <ng-template #no_data></ng-template>
        </app-card>
    </ng-template>
</app-page-structure>