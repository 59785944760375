export enum RolesEnum {
    ADMIN = 1, 
    ADVISER = 2,
    MECHANIC = 3,
    NOONE = -1,
}

/** Most common array of roles for views */
export const roleGroup = {
    adminAsesor : [RolesEnum.ADMIN, RolesEnum.ADVISER]
}