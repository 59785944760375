import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { AppointmentStatus, RoleService, SnackService, } from '@sinigual/angular-lib';
import { CustomDateByGranularity, DateByGranularity, } from 'src/app/core/custom-classes/CustomDateByGranularity';
import { ApiService } from '../../api/api.service';
import { M_Appointment } from '../../models/M_Appointment';
import { DateGranularity } from '../../enums/DateGranularity';
import { M_Company } from '../../models/M_Company';
import { RolesEnum } from '../../enums/RolesEnum';

export type initialHolidayData = {
  companyH: Date[];
  userH: Date[];
  userID?: number;
};

@Component({
  selector: 'app-holidays-calendar',
  templateUrl: './holidays-calendar.component.html',
  styleUrls: ['./holidays-calendar.component.css', './holiday-colors.css'],
})
export class HolidaysCalendarComponent implements OnInit, OnChanges {
  @ViewChild('datePicker', { static: false }) caledar?: MatCalendar<Date>;
  @ViewChildren('anually') caledarAnual?: QueryList<MatCalendar<Date>>;
  /** The component can also be initied by param. If the aparams changes, the component re-init itselef */
  @Input() initData?: initialHolidayData;
  /** Array of role numbers that can edit the calendar */
  @Input() enabledFor: RolesEnum[] = [];
  /** Show a save button on the bottom of the calendar. By default false */
  @Input() selfSave: boolean = false;
  /** Prespective of the calendar */
  @Input() prespective: 'company' | 'user' = 'company';
  /** Show legend */
  @Input() showLegend: boolean = true;
  /** Show unaved changes label? */
  @Input() showUnsaved: boolean = true;
  /** When the changes are saved */
  @Output() onSave: EventEmitter<initialHolidayData> =
    new EventEmitter<initialHolidayData>();

  /** ---------- MAIN ARRAYS ---------- */
  /** Holidays to show on the calendar */
  companyHolidays: Date[] = [];
  /** User holidays */
  userHolidays: Date[] = [];

  /** The id of the user on which the holidays will be set */
  userID: number | undefined = undefined;
  /** Is the calendar enbled for the current user? */
  enabled: boolean = false;
  /** Bottom contents of the caledar. Shows all months that have holidays with the respective number of days */
  holidayBreakdown: DateByGranularity[] = [];
  /** If ther is some changes on calendar, show unsaved changes */
  holidayChanges = false;
  /** Current user role */
  userRole: number;
  /** Days that its availability is being checked (api call)*/
  private checkingDays: Date[] = [];
  /** Is calendar loading ? */
  loading = true;
  year: number = new Date().getFullYear();
  months = [
    { number: 0, name: 'Enero' },
    { number: 1, name: 'Febrero' },
    { number: 2, name: 'Marzo' },
    { number: 3, name: 'Abril' },
    { number: 4, name: 'Mayo' },
    { number: 5, name: 'Junio' },
    { number: 6, name: 'Julio' },
    { number: 7, name: 'Agosto' },
    { number: 8, name: 'Septiembre' },
    { number: 9, name: 'Octubre' },
    { number: 10, name: 'Noviembre' },
    { number: 11, name: 'Diciembre' },
  ];
  startDate: Date;
  selectedDates: Date[] = [];
  constructor(
    private apiService: ApiService,
    roleS: RoleService,
    public snackS: SnackService,
    private cdr: ChangeDetectorRef
  ) {
    this.userRole = roleS.getRole();
    this.startDate = new Date(this.year, 0, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initData']) {
      this.initComponent(changes['initData'].currentValue);
    }
  }

  ngOnInit(): void {
    this.enabled = this.enabledFor.some((r) => r == this.userRole);
  }

  get prespectiveArrray() {
    return this.prespective == 'company'
      ? this.companyHolidays
      : this.userHolidays;
  }

  get isCompanyPrespective() {
    return this.prespective == 'company';
  }

  get isUserPrespective() {
    return this.prespective == 'user';
  }

  /** Set the holiday dates to the caledar. */
  initComponent(data: initialHolidayData) {
    this.companyHolidays = [...data.companyH]; // --> Important to lose the reference with the input
    this.userHolidays = [...data.userH]; // --> Important to lose the reference with the input
    this.userID = data.userID;
    this.caledar?.updateTodaysDate();
    this.generateBreakdown();
    this.loading = false;
    this.holidayChanges = false;
    this.refreshCalendar();
  }
  onDateSelected(selectedDate: Date): void {
    // Guardar la fecha seleccionada en el array
    this.selectedDates.push(selectedDate);
  }
  /** Determines if a day is selected or not. */
  dateClass = (d: Date) => {
    let date_ = new Date(d);

    let finalClass = '';
    /** CSS classes */
    var loading = 'loadingDay ';
    var company = 'companyDay ';
    var miniCompany = 'mini-companyDay ';
    var user = 'userDay ';

    if (this.checkingDays.some((d) => d.isEquals(date_))) {
      finalClass += loading;
    }

    if (this.isCompanyPrespective) {
      if (this.companyHolidays.some((d) => d.isEquals(date_))) {
        finalClass += company;
      }
    } else {
      if (this.userHolidays.some((d) => d.isEquals(date_))) {
        finalClass += user;
      }
      if (this.companyHolidays.some((d) => d.isEquals(date_))) {
        finalClass += miniCompany;
      }
    }

    return finalClass;
  };

  /** It is used to go to a specific day on the calendar.
   * It is useful to go to the months that have been established as holidays
   **/
  goToMonth(d: Date) {
    this.caledar?._goToDateInView(d, 'month');
  }
  getStartDate(monthNumber: number): Date {
    return new Date(this.year, monthNumber, 1);
  }
  getLastDate(monthNumber: number): Date {
    return new Date(this.year, monthNumber + 1, 0);
  }

  /** Refresh the caledar to re-paint the holiday dates with its respective color */
  refreshCalendar() {
    this.refreshCalendarAnual();
    this.refreshCalendarSingle();
    this.cdr.detectChanges();
  }
  refreshCalendarAnual() {
    this.caledarAnual?.forEach((calendar) => {
      this.refreshCalendarSingle(calendar);
    });
  }
  refreshCalendarSingle(anualCalendar?: MatCalendar<Date>) {
    let calendar = anualCalendar ? anualCalendar : this.caledar;
    calendar?.updateTodaysDate();
    this.generateBreakdown();
  }


  /** The breakdown is only showed on phone */
  generateBreakdown() {
    let d = this.caledar?.activeDate;
    let finalYear = d ? d.getFullYear() : new Date().getFullYear();
    this.holidayBreakdown = new CustomDateByGranularity(this.prespectiveArrray, DateGranularity.MONTH, finalYear).getData();
  }

  nextYear(): void {
    // Incrementa el año en 1
    this.year++;
    // Actualiza el año en los calendarios
    this.updateCalendarsYear();
    // Refresca los calendarios para reflejar el cambio de año
    this.refreshCalendar();

  }
  previousYear(): void {
    this.year--;
    // Actualiza el año en los calendarios
    this.updateCalendarsYear();
    // Refresca los calendarios para reflejar el cambio de año
    this.refreshCalendar();
  }
  updateCalendarsYear(): void {
    // Actualiza el año en todos los calendarios anuales
    this.caledarAnual?.forEach((calendar, index) => {
      if (calendar) {
        // Asegúrate de que 'calendar' no sea 'null'. Oke
        calendar?._goToDateInView(this.getStartDate(index), 'month');
        calendar?.updateTodaysDate();
      }
    });
    this.cdr.detectChanges();
  }
  /** On click a day on the caledar */
  public dateChanged(event: any) {
    if (!this.enabled || this.loading) {
      return;
    }
    if (event) {
      let dayClicked = new Date(event);
      if (dayClicked) {
        /** Set a new holiday */
        if (!this.prespectiveArrray.find((d) => d.isEquals(dayClicked))) {
          //Si el dia no esta ya en la array
          this.checkingDays.push(dayClicked);
          this.refreshCalendar();
          this.apiService.checkHolidays(dayClicked, this.userID).then((res) => {
            if (res == true) {
              this.prespectiveArrray.push(dayClicked);
              this.holidayChanges = true;
            }
            this.removeDayOfArray(this.checkingDays, dayClicked);
            this.refreshCalendar();
          });
        } else {
          /** Remove existing holiday */
          let removedDay = new Date(event);
          this.removeDayOfArray(this.prespectiveArrray, removedDay);
          this.holidayChanges = true;
        }
        this.refreshCalendar();
      }
    }
  }
  /** Removes specific day on specific array */
  private removeDayOfArray(array: Date[], day: Date): boolean {
    const index = array.indexOf(array.find((d) => d.isEquals(day))!, 0);
    if (index > -1) {
      array.splice(index, 1);
      return true;
    }
    return false;
  }

  /** Check if some appointment of array is on "pending" status*/
  dayWithAppointment(citas: M_Appointment[]): boolean {
    for (let i = 0; i < citas.length; i++) {
      if (citas[i].state == AppointmentStatus.pending) {
        return true;
      }
    }
    return false;
  }

  /** Triggered when click the self save button. The 'userID'is needed to save. */
  save() {
    if (this.prespective != 'company') {
      this.apiService
        .saveHolidaysByUserId(this.userHolidays, this.userID)
        .then((res) => {
          this.onSave.emit({
            companyH: this.companyHolidays,
            userH: this.userHolidays,
            userID: this.userID,
          });
          this.holidayChanges = false;
          this.snackS.show('Días festivos actualizados correctamente');
        });
    } else {
      this.apiService
        .saveHolidayByCompanies(this.companyHolidays, undefined)
        .then((res) => {
          this.onSave.emit({
            companyH: this.companyHolidays,
            userH: [],
            userID: this.userID,
          });
          this.snackS.show('Días festivos actualizados correctamente');
          this.holidayChanges = false;
        });
    }
  }
  formatAndSetMultipleDates(data: M_Company) {
    var d: Date[] = [];
    for (let i = 0; i < data.holidays.length; i++) {
      d.push(new Date(data.holidays[i]));
    }
    this.initComponent({ companyH: d, userH: [] });
  }

  /** Check if a breakdown month is the same month selected in the calendar  */
  isCurrentMonth(d: Date) {
    var selected = this.caledar?.activeDate;
    if (selected) {
      return d.isMonthEqual(new Date(selected));
    }
    return false;
  }
}
