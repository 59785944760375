import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarService, ConfirmDialogService, RouterService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { ClientInfoComponent } from 'src/app/core/components/class-searcher/client-info/client-info.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { ActionCreateEditParentComponent } from '../or-create-edit-parent/or-create-edit-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { OrFormComponent } from 'src/app/core/components/or-form/or-form.component';
import { RaGroupsComponent } from './ra-groups/ra-groups.component';
import { UserService } from '../../profile/user-service';
import { OrNotesAndImagesComponent } from '../or-notes-and-images/or-notes-and-images.component';
import { OrService } from 'src/app/core/services/or.service';

@Component({
  selector: 'app-or',
  templateUrl: './create-or.component.html',
  styleUrls: ['./create-or.component.css', '../create-edit-or-styeles.css']
})
export class CreateOr extends ActionCreateEditParentComponent implements OnInit {
  @ViewChild(ClientInfoComponent) clientDetails!: ClientInfoComponent;
  @ViewChild(OrFormComponent, { static: true }) formComponent!: OrFormComponent;
  @ViewChild(RaGroupsComponent) raGroups!: RaGroupsComponent;
  @ViewChild(OrNotesAndImagesComponent) notesAndImages!: OrNotesAndImagesComponent;
  e = endpoints;
  resizeSignatureSize = 530;

  constructor(routerS: RouterService, public cs: CalendarService, apiS: ApiService, d: MatDialog, route: ActivatedRoute,
    public userS: UserService, private chdRef: ChangeDetectorRef, confirmDialogS: ConfirmDialogService,
    private orS: OrService) {
    super(apiS, routerS, route, ["or", "startDate", 'vehicle'], d, confirmDialogS);
  }

  ngOnInit(): void {
    this.chdRef.detectChanges();
    this.fillSchedule(new Date());
  }

  override onParam(k: string, v: number | any) {
    if (k == "vehicle") {
      this.formComponent.fillFormWithVehicle(v);
    }
    else if (k == "startDate") {
      this.fillSchedule(new Date(v));
    }
  }

  get missingCompanyInfo() {

    return this.userS.missingCompanyInfo;
  }

  createOr() {
    this.orS.create({
      orForm : this.formComponent,
      raGroups : this.raGroups.groups,
      notesAndImages : this.notesAndImages
    });
  }

  override fillSchedule(v: Date) {
    this.formComponent.form.patchValue({ "schedule": v.datePickerFormat() })
  }

  disableNoVehicles(v: M_Client) {
    return !v.hasVehicles();
  }

  goCompany() {
    this.routerS.goTo(this.v.business);
  }
}


