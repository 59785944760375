import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '@sinigual/angular-lib';

@Component({
  selector: 'app-qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.css']
})
export class QrDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<QrDialogComponent>, private snackS: SnackService) { }

  downloadQR() {
    var link = document.createElement('a');
    link.download = 'qr-pedir-citas.png';
    let canvas = document.getElementById('qrcode-tag-ref')!.getElementsByTagName('canvas')[0]! as HTMLCanvasElement;
    link.href = canvas.toDataURL();
    link.click();
    this.snackS.show("¡QR descargado con éxito!")
  }
}
