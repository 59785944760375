<app-card-subtitle [first]="true" [noMarginBottom]="true">
    <div class="product-title">

        <div class="df" [ngClass]="parent.productsLineS.phoneVersion ? 'fww aic' : 'aifs'">
            <!-- Unsaved changes on desktop -->
            <mat-icon class="unsavedDot" [ngClass]="{'vh' : !parent.unsavedChanges}"
                *ngIf="!parent.productsLineS.phoneVersion">circle</mat-icon>
            <div class="df jcs"
                [ngClass]="{'underline_red_hover' : parent.canModify , 'aic' : parent.productsLineS.phoneVersion, 'aifs' : !parent.productsLineS.phoneVersion}"
                (click)="editProductDialog()">
                <!-- Product Icon-->
                <mat-icon smartIcon>{{parent.getIconByType()}}</mat-icon>                
                <div class="df aifs" [ngClass]="parent.productsLineS.phoneVersion ? 'fww dfr' : 'fdc'">
                    <!-- Product name -->
                    <span
                        [ngClass]="{'tas one-line': !parent.productsLineS.phoneVersion, 'mr5' : parent.productsLineS.phoneVersion, }">{{parent.isTime
                        ? 'Tiempo trabajado' : parent.p?.name}}</span>
                    <span class="c_t2 fw300"
                        [ngClass]="{'tas one-line': !parent.productsLineS.phoneVersion }">{{parent.getRef()}}</span>
                </div>
                <!-- Unsaved changes on phone -->
                <mat-icon class="unsavedDot"
                    *ngIf="parent.unsavedChanges && parent.productsLineS.phoneVersion">circle</mat-icon>
            </div>
        </div>

        <!-- ONLY ON PHONE VERSION -->
        <div class="right-actions" *ngIf="parent.productsLineS.phoneVersion">
            <!-- DELETE ICON -->
            <button (click)="parent.destroy()" mat-icon-button *ngIf="parent.canModify">
                <mat-icon class="c_t2i">delete</mat-icon>
            </button>
            <!-- DRAG HANDLE -->
            <div [ngTemplateOutlet]="parent.dragTemplateRef"></div>
        </div>
    </div>
</app-card-subtitle>