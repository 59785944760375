import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardComponent, ConfirmDialogService, CreateTicketDialog, SessionService } from '@sinigual/angular-lib';
import { Subject } from 'rxjs';
import { TryProDialogComponent } from '../components/try-pro-dialog/try-pro-dialog.component';
import { EnumSubscription } from '../enums/EnumSubscription';
import { ApiService } from '../api/api.service';
import { M_Subscription } from '../models/M_Subscription';
import { UserService } from 'src/app/views/profile/user-service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ViewPath } from 'src/app/app-routing.module';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  /** User's subscription state */
  status: M_Subscription | undefined = undefined;
  /** Subect for notify when the user subscribe / unsubscribe */
  onChangeSubscription: Subject<M_Subscription | undefined> = new Subject();

  /** Every 20 URL changes... check user subscription */
  MAX_CHANGES = 20;
  countToRefresh = 0;

  constructor(router: Router, private d: MatDialog, private apiS: ApiService, private userS: UserService, private sessionS: SessionService, private confirmD: ConfirmDialogService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.countToRefresh += 1;
        if (this.countToRefresh >= this.MAX_CHANGES) {
          this.countToRefresh = 0;
          if (this.sessionS.hasSession()) {
            this.refreshUserSubscription();
          }
        }
      }
    });
  }

  /** Called on toolbar component. */
  refreshUserSubscription(): Promise<M_Subscription> {
    return new Promise<M_Subscription>(resolve => {
      this.apiS.checkUserSubscription().then(res => {
        //Set up local hardcoded subscription
        if (environment.local) {
          res = {
            type: EnumSubscription.PRO,
            active: true,
            will_cancel: false,
            users: 3,
            remaining_days: 0,
            is_demo: false,
            amount: 100,
            next_payment: new Date().plusDays(2),
            typedetails: {
              first_user: 30,
            }
          }
        }
        this.countToRefresh = 0;
        this.setSubscriptionStatus(res);
        resolve(res);
      })
    })
  }

  /** Used on subscirbed-user-guard.ts */
  hasProPermissionsAsync(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.refreshUserSubscription().then(res => {
        resolve(this.hasProPermissions);
      })
    })
  }


  /**Change the user's subscription status. Also emitts changes */
  setSubscriptionStatus(status: M_Subscription | undefined) {
    if (environment.local) { this.startProDev(); }
    else {
      this.status = status;
      this.onChangeSubscription.next(this.status);
    }
  }

  /** Open dialog to pay */
  openTryProDialog(text?: string, card?: CardComponent) {
    if (!card || card.locked == true) {
      this.d.open(TryProDialogComponent, { data: text, autoFocus: false })
    }
  }

  get subscriptionDefined() {
    return this.status != undefined;
  }

  get isTrial() {
    return this.status?.type == EnumSubscription.TRIAL;
  }

  get isBasic() {
    return this.status?.type == EnumSubscription.BASIC;
  }

  get isPro() {
    return this.status != undefined &&
      this.status.type == EnumSubscription.PRO;
  }

  get hasProPermissions() {
    return this.status != undefined &&
      (this.status.type == EnumSubscription.PRO ||
        this.status.type == EnumSubscription.TRIAL);
  }

  /** Show the top banner ? 
   *  - When the user has the free trial (green)
   *  - When the user finished the free trial (red)
   *  - When the user canceled the subscription and already have remaining days of pro (blue)
   */
  get showBanner() {
    if (this.status == undefined || !this.userS.isAdmin) { return false; }
    return this.isTrial || this.isBasic || (this.isPro && this.status.will_cancel);
  }

  get someTimeHasPro() {
    return this.status != undefined && (this.isPro || (this.isBasic && !this.status.is_demo))
  }



  /** ==================== ONLY FOR DEV =============================== */
  startFreeTrialDev() {
    let s: M_Subscription = {
      type: EnumSubscription.TRIAL,
      active: false,
      will_cancel: false,
      users: 0,
      remaining_days: 2,
      amount: 0,
      is_demo: true,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.status = s;
    this.onChangeSubscription.next(this.status);
  }
  endFreeTrialDev() {
    let s: M_Subscription = {
      type: EnumSubscription.BASIC,
      active: false,
      will_cancel: false,
      users: 0,
      is_demo: true,
      remaining_days: 0,
      amount: 0,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.status = s;
    this.onChangeSubscription.next(this.status);
  }

  startProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.PRO,
      active: true,
      will_cancel: false,
      users: 3,
      remaining_days: 0,
      amount: 100,
      is_demo: false,
      next_payment: new Date().plusDays(2),
      typedetails: {
        first_user: 30,
      }
    }
    this.status = s;
    this.onChangeSubscription.next(this.status);
  }

  cancelProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.PRO,
      active: false,
      will_cancel: true,
      users: 3,
      remaining_days: 2,
      amount: 0,
      is_demo: false,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.status = s;
    this.onChangeSubscription.next(this.status);
  }

  canceledProDev() {
    let s: M_Subscription = {
      type: EnumSubscription.BASIC,
      active: false,
      will_cancel: true,
      users: 3,
      remaining_days: 2,
      amount: 0,
      is_demo: false,
      next_payment: null,
      typedetails: {
        first_user: 30,
      }
    }
    this.status = s;
    this.onChangeSubscription.next(this.status);
  }

  genericSubscriptionError(title: string, body: string) {
    this.confirmD.show({
      title: title,
      body: body,
      type: "danger",
      confirmTxt: "Contactar",
      showCancel: true
    }).afterClosed().subscribe(res => {
      if (res) {
        this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues: true }, autoFocus: false });
      }
    });
  }
}
