<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Explicación de los roles</p>

<div mat-dialog-content>
    <!-- Admin -->
    <p mat-dialog-subtitle>Administrador</p>
    <p>Es la primera persona que se ha registrado en Eina. Solo puede haber uno.</p>
    <p><span>• Tiene acceso completo a Eina.</span></p>

    <!-- Adviser -->
    <p mat-dialog-subtitle>Asesor</p>
    <p>Es designado por el administrador.</p>
    <p><span>• A excepción de la pantalla de empresa, equipo y subscripción, tiene acceso completo a Eina.</span></p>

    <!-- Mechanic-->
    <p mat-dialog-subtitle>Mecánico</p>
    <p>Es designado por el administrador.</p>
    <p><span>• Solo puede crear y editar OR.</span></p>
</div>

<div mat-dialog-actions>
    <button color="warn" mat-flat-button mat-dialog-close cdkFocusInitial>¡Genial!</button>
</div>