import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_GroupTask } from 'src/app/core/models/M_GroupTask';

@Component({
  selector: 'app-group-card-invoice-group',
  templateUrl: './group-card-invoice-group.component.html',
  styleUrls: ['./group-card-invoice-group.component.css'],
})
export class GroupCardInvoiceGroupComponent implements OnInit {

  @Input() g!: M_GroupTask;
  @Input() disabled: boolean = false;
  @Input() preSelected?: boolean;
  @Input() invoiceClient! : M_Client | undefined;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatCheckbox) checkbox?: MatCheckbox;
  disabledMessage = "Solo se pueden facturar intervenciones del mismo tipo"


  constructor() { }

  ngOnInit(): void {
    if (this.g.getTotalBreakdown(this.invoiceClient).total < 0){
      this.preSelected = undefined;
      this.disabledMessage = "No se pueden facturar intervenciones con un total negativo" 
      this.disabled = true;
    }
  }

  ngOnChanges(){   
  }

  ngAfterViewInit(){
    if (this.preSelected != undefined && this.preSelected && this.checkbox) {
      this.checkbox.checked = true;
    }
  }

  unSelect() {
    this.setSelected(false);
   
  }

  select() {
    
    if (!this.disabled) {
      this.setSelected(true);

    }
  }

  switchSelected(event: Event, stopPropagation : boolean) {
    if (!this.checkbox || this.disabled) { return; }
    this.checkbox.toggle();
    this.OnChange.emit();
  }

  setSelected(selected: boolean) {
    if (!this.checkbox) { return; }
    this.checkbox.checked = selected;
    this.OnChange.emit();

  }

  get selected() {
    if (!this.checkbox) { return; }
    return this.checkbox.checked;
  }

}
