import { Injectable } from '@angular/core';
import { BurgerMenuInterface, Faqs, OnBoarding, OnboardingComponent, RefServiceInterfece, ResponsiveService, Section, Step } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { feature } from '../features-controller/FeaturesController';
import { SubscriptionService } from './subscription.service';
import { UserService } from 'src/app/views/profile/user-service';

/**
 * Contains references to onboarding and the menu component
 */
@Injectable({
  providedIn: 'root'
})
export class ReferenceService implements RefServiceInterfece {

  private onboardingArray!: OnBoarding;
  onboardingComponent: OnboardingComponent | undefined;
  menuComponent: BurgerMenuInterface | undefined;

  faqs: Faqs[] = [];

  constructor(public responsive: ResponsiveService, private subsS: SubscriptionService, private userS: UserService) {
    this.responsive.width = window.innerWidth;
    this.faqs.push(

      new Faqs(
        "¿Cómo puedo obtener asistencia técnica?",
        "Si experimenta problemas técnicos o necesita asistencia, puede ponerse en contacto con nuestro equipo a través de la sección del menú llamada 'Incidencias'."
      ),

      ... this.userS.isAdmin ?
        [new Faqs(
          "¿Puedo cancelar mi subscripción en cualquier momento?",
          "Sí, definitivamente puedes darte de baja en cualquier momento. Tienes la flexibilidad de cancelar tu subscripción o cuenta cuando lo desees."
        )] : [],

      new Faqs(
        "¿Pierdo mis datos al terminar el periodo de prueba?",
        "No, no perderás tus datos una vez que finalice el periodo de prueba."
      ),

      new Faqs(
        "¿Cómo se protege mi información?",
        "La seguridad de la información de nuestros clientes es de máxima importancia. Implementamos medidas de seguridad avanzadas y ciframos los datos para proteger tanto sus datos personales como los de sus clientes."
      ),

    );
    this.generateOnboardingByScreenSize(responsive.isPhone());
    this.subsS.onChangeSubscription.subscribe(s => {
      this.generateOnboardingByScreenSize(responsive.isPhone());
    })

    this.userS.onMissingCompanyInfo.subscribe(value => {
      this.generateOnboardingByScreenSize(responsive.isPhone());
    })

  }






  get onboarding() {
    return this.onboardingArray;
  }

  generateOnboardingByScreenSize(isPhone: boolean) {


    /** Steps to use on the WelcomeDialog and the first step */
    let firstStepSteps = [
      new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
      new Step({ target: "onboarding-company", view: ViewPath.business, info: "Ves a la sección de 'empresa' del menú" }),
      new Step({ target: "onboarding-company-genera-tab", info: "Seleccione la pestaña de 'general'" }),
      new Step({ target: "onboarding-company-genera-tab-info", nextOnTargetClick: false, info: ["Rellene este formulario", "Todos los datos que se muestran en este formulario son obligatorios'"] }),
      new Step({ target: "onboarding-company-genera-tab-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón para guardar la configuración" }),
    ]

    this.onboardingArray = new OnBoarding(
      [


        /** HIDDEN SECTION. SHOWS THE USER WHERE IS THE HELP BUTTON AND CONCATENATE THE 'FIRST STEP' STEPS */
        new Section("Onboarding", "Onboarding", [
          new Step({ target: "onboarading-help-toolbar", info: ["Abre la sección de ayuda"], action: true, closSideMenu: false }),
          new Step({ target: "onboarading-help-first-step-button", info: ['Empieza la sección de "primer paso". Te enseñará como establecer la información de tu empresa.'], action: false, closSideMenu: false }),
          ...firstStepSteps], isPhone, true),

        /** COMPANY GENERAL | GENERAL INFO */
        new Section("Primer paso", "Establecer la información de tu empresa", firstStepSteps, isPhone, false, "onboarading-help-first-step"),

        /** CREAR CLIENTE */
        new Section("Dar de alta un cliente", "¿Cómo registro a un cliente?", [
          new Step({ target: "onboarading-menu", info: "Abre el menú", device: "phone", }),
          new Step({ target: "onboarding-client", info: "Ves a la sección de 'clientes' del menú", view: ViewPath.clientes }),
          new Step({ target: "onboarding-create-client", info: ["Haz clic en el botón circular", "Este te redireccionará a la página para crear un cliente"], view: ViewPath.createClient, }),
          new Step({ target: "onboarding-client-required", info: ["Rellene los campos obligatorios"], action: false, nextOnTargetClick: false }),
          new Step({ target: "onboarding-client-vinculate", info: "Si quieres vincular el cliente con un vehícluo, activa este botón" }),
          new Step({ target: "onboarding-vehicle-required", info: "Rellene los campos obligatorios y se asociará el vehículo al cliente que esté creando.", action: false, nextOnTargetClick: false }),
          new Step({ target: "onboarding-client-finish", info: "Una vez lo tengas todo listo, haz clic en este botón para crear el cliente", action: false }),

        ], isPhone),

        /** CREAR VEHÍCULO */
        new Section("Dar de alta un vehículo", "¿Cómo puedo registrar un vehículo?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-vehicle", view: ViewPath.vehiculos, info: "Ves a la sección de 'vehículos' del menú" }),
          new Step({ target: "onboarding-create-vehicle", view: ViewPath.createvehicle, info: ["Haz clic en el botón circular", "Este te redireccionará a la página para crear un vehículo"] }),
          new Step({ target: "onboarding-vehicle-required", action: false, nextOnTargetClick: false, info: "Rellene los campos obligatorios y se asociará el vehículo al cliente que esté creando." }),
          new Step({ target: "onboarding-vehicle-owner", action: false, info: ["Los vehículos siempre deben esta associados a un usuario.", "Puedes buscar un usuario ya creado o crear uno de nuevo"] }),
          new Step({ target: "onboarding-vehicle-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón para crear el vehículo" }),
        ], isPhone),

        /** BUSCAR CLIENTE */
        new Section("Buscar un cliente", "¿Cómo busco a un cliente?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-client", view: ViewPath.clientes, info: "Ir a la sección de 'clientes' del menú" }),
          new Step({ target: "onboarding-search-client", view: ViewPath.createvehicle, info: "Puedes buscar el cliente introduciendo cualquier dato sobre este en el buscador." })
        ], isPhone),


        ...feature.bigMama ?
          /** BIG MAMA */
          [new Section("Buscador general", this.userS.isMechanic ? 'Puedes buscar OR de forma rápida' : "Puedes buscar un cliente, vehiculo, producto, or...", [
            new Step({ target: "onboarading-big-mama", info: this.userS.isMechanic ? "Mediante este buscador, puedes buscar cualquier OR de forma rápida." : "Mediante este buscador, puedes buscar cualquier cosa dentro de la web." })
          ], isPhone)] : [],


        /** PRODUCT */
        new Section("Crear un producto", "¿Cómo puedo crear un producto?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-product", view: ViewPath.products, info: "Ves a la sección de 'productos' del menú" }),
          new Step({ target: "onboarding-create-product", view: ViewPath.createProduct, info: "Haz clic en el botón circular" }),
          new Step({ target: "onboarding-product-required", action: false, nextOnTargetClick: false, info: "Rellene los campos obligatorios" }),
          new Step({ target: "onboarding-product-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón para crear el producto" }),
        ], isPhone),


        /** INVOICE */
        ...!this.userS.missingCompanyInfo ?
          [new Section("Facturar productos", "¿Cómo puedo facturar productos?", [
            new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
            new Step({ target: "onboarding-invoice", view: ViewPath.facturas, info: "Ves a la sección de 'facturas' del menú" }),
            new Step({ target: "onboarding-create-invoice", view: ViewPath.createBill, info: "Haz clic en el botón circular" }),
            new Step({ target: "onboarding-invoice-select-client", action: false, nextOnTargetClick: false, info: "Selecciona el cliente al que desas facturar los productos" }),
            new Step({ target: "onboarding-invoice-add", action: false, info: "Puedes añadir diferentes entradas de productos a la factura" }),
            new Step({ target: "onboarding-invoice-add-custom", action: false, info: ["Si lo prefieres, puedes crear un producto 'personalizado'"] }),
            new Step({ target: "onboarding-invoice-finish", action: false, info: ["Una vez lo tengas todo listo, haz clic en este botón para crear la factura"] }),
          ], isPhone)] : [],


        /** OR */
        ...!this.userS.missingCompanyInfo ?
          [new Section("Crear orden de reparación", "¿Cómo puedo crear una orden de reparación?", [
            new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
            new Step({ target: "onboarding-or", view: ViewPath.or, info: "Ves a la sección de 'órdenes de reparación' del menú" }),
            new Step({ target: "onboarding-create-or", view: ViewPath.createOr, info: "Haz clic en el botón circular" }),
            new Step({ target: "onboarding-or-select-vehicle", action: false, nextOnTargetClick: false, info: "Selecciona el vehículo sobre el que efectuar la orden de reparación" }),
            new Step({ target: "onboarding-or-tasks", info: ["Puedes añadir diferentes intervenciones", "Una vez creada la orden de reparación, podrás modificarlas o añadir de nuevas"] }),
            new Step({ target: "onboarding-or-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón para crear la orden de reparación" }),
          ], isPhone)] : [],


        /** BUDGET */
        ...!this.userS.missingCompanyInfo ?
          [new Section("Crear un presupuesto", "¿Cómo puedo crear un presupuesto?", [
            new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
            new Step({ target: "onboarding-budget", view: ViewPath.budget, info: "Ves a la sección de 'presupuestos' del menú" }),
            new Step({ target: "onboarding-create-budget", view: ViewPath.createEditBudget, info: "Haz clic en el botón circular" }),
            new Step({ target: "onboarding-budget-select-vehicle", nextOnTargetClick: false, action: false, info: "Selecciona el vehículo sobre el que efectuar el presupuesto" }),
            new Step({ target: "onboarding-budget-tasks", info: "Al empezar el presupuesto, se desbloqueará esta sección y podrás presupuestar diferentes intervenciones" }),
            new Step({ target: "onboarding-budget-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón de 'Cerrar presupuesto'" }),
          ], isPhone)] : [],

        /** WORKLOAD */
        ...this.subsS.hasProPermissions ?
          [new Section("Carga de taller", "¿Qué es la carga de taller?", [
            new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
            new Step({ target: "onboarding-workload", view: ViewPath.cargataller, info: "Ves a la sección de 'carga de taller' del menú" }),
            new Step({ target: "onboarding-workload-week", action: false, info: "Aquí verás las órdenes de reparación que estén programadas para esta semana" }),
            new Step({ target: "onboarding-workload-change-view", action: false, info: "Puedes cambiar la cantidad de días que se muestran por pantalla mediante este desplegable'" }),
            new Step({ target: "onboarding-workload-workers", info: "Si lo necesitas, puedes filtrar las órdenes de reparación según tus operarios", showStepOn: ["bigger", 1004], action: false }),
          ], isPhone)] : [],

        /** IMPORTAR DATOS */
        ...this.subsS.hasProPermissions && feature.dataUpload ?
          [new Section("Importar datos", "¿Cómo puedo importar mis clientes o productos?", [
            new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
            new Step({ target: "onboarding-upload", view: ViewPath.dataupload, info: "Ves a la sección de 'Importar datos' del menú" }),
            new Step({ target: "onboarading-product-import", info: "En esta sección puedes importar tus productos." }),
            new Step({ target: "onboarading-product-import-link", info: "Deberás usar la plantilla que hemos preparado para ello.", action: false }),
            new Step({ target: "onboarading-client-import", info: "En esta otra sección, podrás importar tus clientes." }),
            new Step({ target: "onboarading-client-import-link", info: "La importación también se deberá realizar mediante una plantilla", action: false })
          ], isPhone)] : [],

        /** COMPANY GENERAL | CORREOS */
        new Section("Configurar mi servidor de correos", "¿Cómo puedo configurar mi propio servidor de correos?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-company", view: ViewPath.business, info: "Ves a la sección de 'empresa' del menú" }),
          new Step({ target: "onboarding-company-emails-tab", info: "Seleccione la pestaña de 'Correos'" }),
          new Step({ target: "onboarding-company-genera-tab-email", nextOnTargetClick: false, info: ["Rellene este formulario", "Todos los datos que se muestran en este formulario son obligatorios'"] }),
          new Step({ target: "onboarding-company-genera-tab-email-finish", action: false, info: "Una vez lo tengas todo listo, haz clic en este botón para guardar la configuración" }),
        ], isPhone),

        /** COMPANY USERS */
        new Section("Dar de alta usuarios", "¿Cómo puedo dar de alta mis usuarios?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-team", view: ViewPath.team, info: "Ves a la sección de 'Equipo' del menú" }),
          new Step({ target: "onboarding-company-users-create", info: "Haz clic en el botón circular'" }),
        ], isPhone),

        /** COMPANY GENERAL */
        new Section("Modificar el aspecto de la documentación", "¿Cómo puedo personalizar los documentos que envío a los clientes?", [
          new Step({ target: "onboarading-menu", device: "phone", info: ["Abre el menú"] }),
          new Step({ target: "onboarding-company", view: ViewPath.business, info: "Ves a la sección de 'empresa' del menú" }),
          new Step({ target: "onboarding-company-layouts-tab", info: "Seleccione la pestaña de 'Documentos'" }),
          new Step({ target: "onboarding-company-layouts-subtab", info: "Puedes personalizar todos los tipos de documentos que se envían" }),
          new Step({ target: "onboarding-company-layouts-finish", info: "Una vez lo tengas todo listo, haz clic aquí para guardar la configuración" }),
        ], isPhone),

      ]
    )
  }
}
