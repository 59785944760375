<app-card>
<app-card-title>Vacaciones</app-card-title>
<app-holidays-calendar 
                    [prespective]="'company'" 
                    [enabledFor]="enabledCalender"
                    [showLegend]="false"
                    [selfSave]="true">
                </app-holidays-calendar>
</app-card>


