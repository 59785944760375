<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Editar usuario</p>

<div mat-dialog-content>
    <app-userform #uf [u]="data"></app-userform>
</div>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button color="warn" [disabled]="!uf.form.valid" mat-flat-button (click)="editUser(uf, data)" cdkFocusInitial>Editar</button>
</div>