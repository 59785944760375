<form [formGroup]="form">
    <div class="form">
        <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" formControlName="name">
        </mat-form-field>
        <app-email-input [formCName]="'email'" [form]="form"></app-email-input>
        <mat-form-field appearance="outline">
            <mat-label>Rol</mat-label>
            <mat-select formControlName="role">
                <mat-option *ngIf="u && u.admin" [value]="1">Administrador</mat-option>
                <mat-option [value]="2">Asesor</mat-option>
                <mat-option [value]="3">Mecánico</mat-option>
            </mat-select>
            <mat-hint class="cp underline_hover c_r" (click)="openRolesExplanation()">¿Qué puede hacer cada rol?
                <mat-icon class="c_r">open_in_new</mat-icon>
            </mat-hint>
        </mat-form-field>
    </div>
</form>