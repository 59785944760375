import { Component, OnInit, ViewChild } from '@angular/core';
import { PageStructureComponent, RouterService, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-garage',
  templateUrl: './garage.component.html',
  styleUrls: ['./garage.component.css']
})
export class GarageComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Vehicle>;
  v = ViewPath;
  vehicles: M_Vehicle[] = [];
  f = filter;
  warn = getWarnColor
  constructor(apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS: SubscriptionService) {
    apiS.getVehicles().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  ngOnInit(): void { }

  vehicleType(v: M_Vehicle) {
    return v.typeName;
  }

  preIcon(v: M_Vehicle) {
    return v.icon;
  }

  getLicenseDate(v: M_Vehicle) {
    return v.license_date?.noHourFormat();
  }

  getOwner(v: M_Vehicle) {
    return v.client?.getName();
  }

  getVnVo(v: M_Vehicle) {
    return undefined;
  }

  getVnVoClass(vehicle: M_Vehicle) {
    return 'vn-label';
  }

}

