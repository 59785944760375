import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResponsiveService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-client-details-vehicle-table',
  templateUrl: './client-details-vehicle-table.component.html',
  styleUrls: ['./client-details-vehicle-table.component.css']
})
export class ClientDetailsVehicleTableComponent {
  v = ViewPath;
  @Input() client!: M_Client;
  displayedColumns = ['license', 'brand', 'model'];
  dataSource: MatTableDataSource<M_Vehicle> = new MatTableDataSource<M_Vehicle>([]);

  constructor(public paramS: ParamsService, public responsiveS : ResponsiveService) { }

  ngOnInit() {
    this.dataSource.data = this.client.vehicles;
  }

  goVehicle(v: M_Vehicle) {
    this.paramS.go(ViewPath.vehicleDetails, v.vehicle_id);
  }

  get displayedColumnsByScreenSize(){
    return this.responsiveS.w > 500 ? this.displayedColumns : this.displayedColumns.slice(0,this.displayedColumns.length-1);
  }

}
