<app-card class="sub" [contentLoaded]="subS.status != undefined">
    <app-card-title>
        <p class="nmb df aic fww">
            <span class="mr5">Tu subscripción</span>
            <span class="subscription-status">
                <span [class]="'banner ' + ('alert-color-' + this.color)">
                    <p>{{subS.status?.active ? 'ACTIVA' : 'INACTIVA'}}</p>
                </span>
                <span [class]="'bottom-banner ' + ('sub-alert-color-' + this.color)"></span>
            </span>
        </p>

    </app-card-title>

    <!-- USER HAS THE PRO PLAN -->
    <div *ngIf="subS.status">

        <app-card-subtitle [first]="true" [noMarginBottom]="true" [rightIcon]="'star'">Subscripción</app-card-subtitle>

        <!-- USERS SECTION !-->
        <table class="subscription-table no-pointer">
            <tr>
                <td class="first-cell">Plan actual</td>
                <td class="value-cell">
                    <span>PRO</span>
                </td>
            </tr>
            <tr>
                <td class="first-cell">Usuarios</td>
                <td class="value-cell">
                    <span>{{subS.status.users}}</span>
                </td>
            </tr>
        </table>


        <!-- PAYMENT SECTION !-->
        <app-card-subtitle [noMarginBottom]="true" [rightIcon]="'receipt'">Pagos</app-card-subtitle>
        <table class="subscription-table no-pointer">
            <tr>
                <td class="first-cell">Este mes</td>
                <td class="value-cell"><span money [val]="subS.status.amount"></span></td>
            </tr>

            <tr>
                <td class="first-cell">Próximo pago</td>
                <td class="value-cell">
                    <span
                        *ngIf="subS.status && subS.status.next_payment; else nodate">{{subS.status.next_payment.humanFormat()}}</span>
                    <ng-template #nodate>
                        <span class="c_t2">Sin datos</span>
                    </ng-template>

                </td>
            </tr>
        </table>

        <!-- THE PLAN IS GOING TO CANCEL AT THE END OF THE MONTH -->
        <div class="df jcc fww" style="gap: 10px;">
            <button *ngIf="subS.status.will_cancel || (subS.isBasic && subS.someTimeHasPro)" mat-flat-button color="blue" (click)="reactivateSubscription()">
                <mat-icon>published_with_changes</mat-icon>
                Reactivar subscripción
            </button>
            <button mat-flat-button color="warn" (click)="changeCard()">
                <mat-icon>credit_card</mat-icon>
                Cambiar tarjeta
            </button>
        </div>
    </div>

</app-card>


<!-- IF HAS THE PRO PLAN, AND NOT GOING TO CANCEL AT THE END OF THE MONTH... -->
<app-card class="change" *ngIf="!subS.status?.will_cancel" [contentLoaded]="subS.status != undefined">
    <app-card-title>Cancelar subscripción</app-card-title>
    <p class="nmb">Podrás seguir usando el plan PRO hasta la fecha de facturación.</p>
    <div class="mt10">
        <span class="c_r underline_hover fw500" (click)="cancelSubscription()">Cancelar subscripción</span>
    </div>
</app-card>