import { Injectable } from '@angular/core';
import { RouterService, Views } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(private routerS: RouterService) { }

  go(v: Views, param: string | number, param_name?: string, newTab?: boolean) {
    let paramName = param_name ? param_name : this.getStateNameByView(v);
    let p = {
      [paramName]: param
    }
    this.routerS.goWithQueryParams(v, p, newTab ? newTab : false);
  }

  getStateNameByView(v: Views) {
    let paramName = "";
    switch (v) {
      case ViewPath.clientes:
      case ViewPath.createClient:
      case ViewPath.clientDetails:
        paramName = "client";
        break;
      case ViewPath.vehiculos:
      case ViewPath.createvehicle:
      case ViewPath.vehicleDetails:
      case ViewPath.linkvehicle:
      case ViewPath.buyVechicle:
      case ViewPath.sellVehicle:
        paramName = "vehicle";
        break;
      case ViewPath.products:
      case ViewPath.productDetails:
      case ViewPath.createProduct:
        paramName = "product";
        break;
      case ViewPath.facturas:
        paramName = "bill"
        break;
      case ViewPath.editOr:
        paramName = "or";
        break;
      case ViewPath.editAppointment:
        paramName = "appointment";
        break;
      case ViewPath.createEditBudget:
        paramName = "budget";
        break;
      case ViewPath.userdetails:
        paramName = "user";
        break;
      case ViewPath.appointmentclientside:
        paramName = "company";
        break;
    }

    return paramName;
  }
}
