<table mat-table [dataSource]="dataSrc" *ngIf="dataSrc.data.length; else noData">
    <ng-container matColumnDef="Ornumber">
        <th mat-header-cell *matHeaderCellDef> {{responsiveS.w > 514 ? 'Anticipo ' : 'OR'}} </th>
        <td mat-cell *matCellDef="let element" (click)="openHistory(element)">
            <div class="df aic">
                <app-or-status-label *ngIf="responsiveS.w <= 514" class="df jcc" [circularStatus]="true"
                    [status]="element.status">
                </app-or-status-label>
                <mat-icon class="mr5 scaled-icon vam" smartIcon>{{element.type_payment.icon}}</mat-icon>
                <p class="nmb fsm fw400">
                    <span *ngIf="isLastMovement(element) "><span class="c_b">Nº {{element.id_movement}}</span></span>
                </p>
            </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="status" *ngIf="responsiveS.w > 514">
        <th mat-header-cell *matHeaderCellDef>Importe</th>
        <td mat-cell *matCellDef="let element" (click)="openHistory(element)">
            <span class="df" money [val]="element.import_origin"></span>
        </td>
    </ng-container>

    <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
        <td mat-cell *matCellDef="let element" (click)="openHistory(element)"> {{element.created_at.shortFormat()}} </td>
    </ng-container> 
    <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>Estado</th>
        <td mat-cell *matCellDef="let element" (click)="openHistory(element)">
        <span  *ngIf="element.deleted == 0"></span>
            <mat-chip-list>
                <mat-chip-option  [selected]="true" [disabled]="true" class="mat-chip-disabled"   color="accent" *ngIf="element.deleted == 1">USADO</mat-chip-option>
                <mat-chip-option  [selected]="false" [disabled]="true" class="mat-chip-disabled greenchip"  *ngIf="element.deleted != 1">DISPONIBLE</mat-chip-option>
            </mat-chip-list>
        </td>

    </ng-container>

    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="options">
            <button mat-icon-button [mat-menu-trigger-for]="deleteMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #deleteMenu="matMenu">
                
                <button mat-menu-item  (click)="previsualize(element)">
                    <span class="pdf-la" >
                    </span> Anticipo 
                </button>
            </mat-menu>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    
</table>
<div class="btn_center jcc"  *ngIf="this.dataSrc_copy.data.length > 5">
    <button  mat-stroked-button (click)="mostrarMasFilas()">{{this.textoBoton}}</button>
</div>
<ng-template #noData>
    
</ng-template>