import { M_Parser } from "@sinigual/angular-lib";
import { M_CustomProduct } from "./M_CustomProduct";
import { M_Product } from "./M_Product";

export class M_Task implements M_Parser {
    needsToBeParsed: boolean = true;
    task_id: number | undefined;
    group_id: number;
    icon: string;
    type: "product" | "time"
    private product: M_Product | M_CustomProduct | undefined;
    time: M_CustomProduct | undefined;
    changes: boolean = false;
    discount: number | undefined;

    constructor(d: any) {
        this.task_id = d.id;
        this.group_id = d.group_id;
        this.type = d.is_hours ? "time" : "product";
        if (this.type == "product") {

            this.product = d.details ? d.custom ? new M_CustomProduct(d.details) : new M_Product(d.details) : undefined;

            //Second check
            if (this.product == undefined) {
                this.product = d.products ? new M_Product(d.products) : d.customproducts ? new M_CustomProduct(d.customproducts) : undefined;
            }

            if (this.product instanceof M_CustomProduct) {
                this.product.type = "customproduct"
            }
        }
        else {
            this.time = d.details ? d.custom ? new M_CustomProduct(d.details) : undefined : undefined;
            if (this.time) {
                this.time.type = "time";
            }
        }
        let p = this.getProduct();
        this.icon = this.type == "product" ? p instanceof M_Product ? p.icon : "barcode" : "schedule";
        this.changes = d.changes;

        if (this.getProduct()) {
            this.getProduct()?.setQuantity(d.quantity ? d.quantity : 0);
        }

        if (this.getProduct()) {
            this.getProduct()!.discount = d.discount;
        }
    }

    isTime() { return this.type == "time" }
    isMaterial() { return this.type == "product" }
    isCustomProduct() {
        if (this.type == "product") {
            return this.product instanceof M_CustomProduct;
        }
        else {
            return this.time instanceof M_CustomProduct;
        }

    }
    showProductSelection() { return (!this.isCustomProduct()) || (this!.product == undefined) }

    isEmpty() {
        if (this.type == "product") {
            return this.product == undefined;
        }
        else {
            return this.time == undefined;
        }
    }

    setProduct(p: M_Product | M_CustomProduct) {
        if (p instanceof M_Product) {
            this.product = p;
            if (p.quantity == 0 || p.quantity == undefined || (p.quantity > 1)) {
                this.product.setQuantity(1);
            }
        }
        else {
            this.type = p.type == "time" ? "time" : "product";
            if (this.type == "time") {
                this.time = p;
                if (this.time.quantity == 0 || this.time.quantity == undefined) {
                    this.time.setQuantity(1);
                }
            }
            else {
                this.product = p;
                if (this.product.quantity == 0 || this.product.quantity == undefined) {
                    this.product.setQuantity(1);
                }
            }
        }
    }

    getProduct() {
        if (this.type == "product") {
            return this.product;
        }
        else {
            return this.time;
        }
    }

    getProductId() {
        if (this.product instanceof M_Product) {
            return this.product.product_id;
        }
        return undefined;
    }

    setQuantity(q: number) {
        if (this.getProduct() != undefined) {
            this.getProduct()?.setQuantity(q);
        }
    }

    isValidToSave(): boolean {
        if (!this.changes) { return false; }
        var p = this.getProduct();
        return p != undefined && p.quantity != undefined;
    }

    parse() {
        var product = this.getProduct();
        var isHours = product instanceof M_CustomProduct && product.type == "time";
        let obj: any = {
            task_id: this.task_id,
            custom: this.isCustomProduct() ? "1" : "0",
            product_id: this.getProductId(),
            price : this.getProduct()?.price,
            buy_price : this.getProduct()?.buy_price,
            quantity: product?.quantity,
            discount: product ? product.discount ? product.discount : 0 : 0,
            is_hours: isHours,
            tax: product?.tax
        }

        if (this.group_id) {
            obj["group_id"] = this.group_id;
        }

        if (this.isCustomProduct()) {
            obj["name"] = product?.name,
            obj["reference"] = product?.reference
        }

        return obj;
    }
}