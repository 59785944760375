import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { M_TypePayment } from '../models/M_TypePayment';
import { M_Invoice } from '../models/M_Invoice';
import { invoice_states_partial, invoice_states_payed } from '@sinigual/angular-lib';
import { M_LastMovements } from '../models/M_LastMovement';

@Injectable({
  providedIn: 'root'
})
export class TypePaymentService {

  loaded = false;
  typePaymentArray: M_TypePayment[] = [];

  constructor(private apiS: ApiService) {
    this.getTypePayments();
  }

  getTypePayments(): Promise<M_TypePayment[]> {
    return new Promise<M_TypePayment[]>(resolve => {
      if (this.loaded) { resolve(this.typePaymentArray) }
      else {
        this.apiS.getPaymentType().then(res => {
          this.typePaymentArray = res;
          this.loaded = true;         
          resolve(this.typePaymentArray)
        })
      }
    })
   }

  deleteTypePayment(type_payment: M_TypePayment): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.apiS.deleteTypePayment(type_payment.id).then(res => {
        this.typePaymentArray.removeElement(type_payment);
        resolve(true);
      }, error => {
        resolve(false);
      })
    })
  }

  /** Any... */
  createPayment(value: any, invoice: M_Invoice, advance: M_LastMovements | undefined): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.apiS.createLastPayment(value, advance).then(
        res => {
          res.forEach(m => {
            const typePaymentValue = m.type_payment;
            if (!this.typePaymentArray.some(item => item.payment === typePaymentValue.payment)) {
              this.typePaymentArray.push(typePaymentValue);
            }
            invoice!.last_movement.push(m);
          })

          if (invoice!.pending_import == 0) {
            this.apiS.changeStateInvoice(invoice!.id!, invoice_states_payed).then(res => {
              invoice!.state = invoice_states_payed;
            });
          }
          else {
            invoice!.state = invoice_states_partial;
          }
          resolve(true);
        },
        error => {
          resolve(false)
        });
    })

  }


}
