import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTicketDialog, CustomFile, M_CloudTicket, PageStructureComponent, ReportedProblem, getAccentColor, getPrimaryColor, getWarnColor} from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-issues-view',
  templateUrl: './issues-view.component.html',
  styleUrls: ['./issues-view.component.css']
})
export class IssuesViewComponent implements OnInit {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_CloudTicket>;
  tickets: M_CloudTicket[] = [];
  filteredTickets: M_CloudTicket[] = [];
  loaded = false;
  f = filter;

  constructor(private apiS: ApiService, private d: MatDialog, public subS : SubscriptionService) {
    this.apiS.getCloudTickets().then(res => {
      this.tickets = res.sort((a, b) => a.created_at! > b.created_at! ? -1 : 1);
      this.filteredTickets = res;
      this.loaded = true;
    });
  }
  ngOnInit(): void { }

  
  createTicket() {
    this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues : false }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof ReportedProblem) {
        this.apiS.createCloudTicket(res).then(res => {
          this.tickets.unshift(res);
          this.filteredTickets = this.tickets;
          this.ps.searchInput.nativeElement.value = "";
        })
      }
    });
  }

  searchTicket(txt : string) {
    this.filteredTickets = [];
    this.tickets.forEach(t => {
      if (t.defaultSearchFilter(txt)) {
        this.filteredTickets.push(t);
      }
    })
  }

  onAddComment(event: [M_CloudTicket, string, CustomFile?]) {
    this.apiS.addComment(event[0].id, event[1], event[2]).then(res => {})
  }
}
