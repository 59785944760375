import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { R_Dashboard } from 'src/app/core/api/responses/R_Dashboard';
import { getAccentColor, isMobile, or_status_close, or_status_invoiced, or_status_open, PeriodEnum, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { forkJoin } from 'rxjs';
import { M_User } from 'src/app/core/models/M_User';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { PrevisionChartOtions, chartGenerationPrevision } from './chart-generation/chartPrevision';
import { SemiCircleChart, semiCircleChart } from './chart-generation/semiCircle';
import { environment } from 'src/environments/environment';
import { ORPageFiltesrEnum } from '../or/or.component';
import { BillPageFiltesrEnum } from '../bills/bills.component';
import { UserService } from '../profile/user-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  e = endpoints;
  v = ViewPath;
  public previsionChart?: Partial<PrevisionChartOtions>;
  public semiCircleChart?: Partial<SemiCircleChart>;
  data?: R_Dashboard;
  loaded = false;
  pe = PeriodEnum;
  open = or_status_open;
  closed = or_status_close;
  invoiced = or_status_invoiced;
  allUsers: M_User[] = [];
  isp = isMobile()
  env = environment;

  constructor(private apiS: ApiService, private routerS: RouterService, public wS: WorkloadService, private userS: UserService) {
    if (this.userS.isMechanic) {
      this.routerS.goTo(ViewPath.cargataller)
    }
  }

  ngOnInit(): void {
    let a = this.apiS.dashboard();
    let b = this.apiS.users();
    forkJoin([a, b]).subscribe(res => {
      this.data = res[0];
      this.allUsers = res[1];
      /** ----- CHART GENERATION ------ */
      // 1 -  Prevision chart
      this.previsionChart = chartGenerationPrevision(this.data);
      // 2 - Workload chart
      this.semiCircleChart = semiCircleChart(this.data);
    })
  }

  goClosedOr() {
    this.routerS.goWithQueryParams(this.v.or, { filter: ORPageFiltesrEnum.OR_GROUP_STATUS, filtervalue: "1" })
  }

  goCollectPending() {
    this.routerS.goWithQueryParams(this.v.facturas, { filter: BillPageFiltesrEnum.INVOICE_STATE, filtervalue: "0", filter1: BillPageFiltesrEnum.INVOICE_STATE, filtervalue1: "1" })
  }

  goCreateOR() {
    this.routerS.goTo(ViewPath.createOr);
  }

  goWorkload() {
    this.routerS.goTo(ViewPath.cargataller);
  }

}
