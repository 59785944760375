import { environment } from "src/environments/environment";

/** Maintain control of which functionalities should be shown or hidden */
export const feature = {

    /** Translations */
    translations: environment.local || environment.dev,

    /** Data upload */
    dataUpload: false,

    /** Import tariff */
    importTariff: false,

    /** Global searcher */
    bigMama: environment.local || environment.production,

    /** Tracking user actions */
    logRocket: environment.production,

    /** Flotas */
    flotas: false,

    /** Mostrar garantia? */
    garantia: false,

    /** Shor providers module? */
    providers: false,

    /** Show VN/VO module */
    vnvo: false

}