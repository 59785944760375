import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkloadService } from 'src/app/core/services/workload.service';
import { DragTutorialComponent } from '../carga-taller/drag-tutorial/drag-tutorial.component';
import { UserService } from '../profile/user-service';
import { ChangeDeliveryOrDialogComponent } from './change-delivery-or-dialog/change-delivery-or-dialog.component';
import { AppointmentStatus, ConfirmDialogService, CoreCargaTallerComponent, ORInterface } from '@sinigual/angular-lib';
import { AppointmentInterface } from '@sinigual/angular-lib/lib/interface/AppointmentInterface';
import { M_Appointment } from 'src/app/core/models/M_Appointment';
import { ApiService } from 'src/app/core/api/api.service';
import { ModifyAppointmentHourComponent } from 'src/app/core/components/modify-appointment-hour/modify-appointment-hour.component';
import { APPD_markAsCanceled, APPD_markAsDone, APPD_markAsPending } from 'src/app/core/constants/constants';
import { AppointmentToOrComponent } from '../appointment-form/appointment-to-or/appointment-to-or.component';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-carga-taller-parent',
  templateUrl: './carga-taller-parent.component.html',
  styleUrls: ['./carga-taller-parent.component.css']
})
export class CargaTallerParentComponent {

  @ViewChild(CoreCargaTallerComponent) public cargaTaller!: CoreCargaTallerComponent;

  constructor(public userS: UserService, private d: MatDialog, public wS: WorkloadService, private confirmD: ConfirmDialogService,
    private apiS: ApiService, public subS: SubscriptionService) {

    if (this.userS.needToShowWorkloadDialog) {
      this.d.open(DragTutorialComponent, { disableClose: true });
      this.userS.setWorkloadShowed(true);
    }
  }

  onModifyAppointment(change: [AppointmentInterface, ("ra" | "eliminar" | "done" | "pending" | "hour")]): void {
    var appointment = change[0];
    var changeName = change[1];
    if (changeName == "ra") {
      this.d.open(AppointmentToOrComponent, { data: appointment });
    }
    if (changeName == "eliminar") {
      this.descartarDialogo(appointment);
    }
    if (changeName == "pending") {
      this.pendingDialog(appointment);
    }
    if (changeName == "done") {
      this.marcarHechaDialogo(appointment);
    }
    if (changeName == "hour") {
      this.onChangeHour(appointment as M_Appointment);
    }
  }

  /** OR delivery Warn */
  openWarnDialog(data: ORInterface) {
    this.d.open(ChangeDeliveryOrDialogComponent, { data: data });
  }

  /**Botón de 'cerrar' cita en la tarjeta */
  descartarDialogo(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsCanceled);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.canceled;
        cita.setStatus = AppointmentStatus.canceled;
        this.apiS.editAppointmentClient(cita!, cita!.hash).then(_res => {
          this.cargaTaller.regenerateCalendar();
          this.cargaTaller.syncAppoWithLeftCalendar(appointment);
        })
      }
    });
  }

  onChangeHour(appointment: M_Appointment) {
    const dialogRef = this.d.open(ModifyAppointmentHourComponent, {
      data: appointment,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //A partir del resultado del dialogo, modificamos la fecha de la cita
        appointment.day = new Date(result);
        this.apiS.editAppointmentClient(appointment!, appointment!.hash).then(_res => {
          this.cargaTaller.regenerateCalendar();
          this.cargaTaller.syncAppoWithLeftCalendar(appointment);
        })
      }
    });
  }
  /**Botón par marcar una cita como pendiente */
  pendingDialog(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsPending);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.pending;
        cita.setStatus = AppointmentStatus.pending;
        this.apiS.editAppointmentClient(cita!, cita!.hash)
        this.cargaTaller.regenerateCalendar();
        this.cargaTaller.syncAppoWithLeftCalendar(appointment);
      }
    });
  }
  /**Botón de 'hecha' cita en la tarjeta */
  marcarHechaDialogo(appointment: AppointmentInterface): void {
    var cita = new M_Appointment(appointment);
    const dialogRef = this.confirmD.show(APPD_markAsDone);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appointment.setStatus = AppointmentStatus.done;
        cita.setStatus = AppointmentStatus.done;
        this.apiS.editAppointmentClient(cita!, cita!.hash)
        this.cargaTaller.regenerateCalendar();
        this.cargaTaller.syncAppoWithLeftCalendar(appointment);
      }
    });
  }

}