import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogWhatsNewsComponent } from './dialog-whats-news/dialog-whats-news.component';
import { M_New } from 'src/app/core/api/responses/R_Dashboard';
import { StorageService } from '@sinigual/angular-lib';

const LAST_DIMISSED = "last_dimissed"

/**
 * How exactly does this work?
 * 
 * Backend : 
 * If you want to show some news to the users, these news items must be added to the database.
 * Don't forget to remove the already existing news!!
 * 
 * Frontend :
 * The forntent only saves on local storage the largest ID of all the news[].
 * 
 */

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.css']
})
export class WhatsNewComponent {
  @Input() news!: M_New[];
  constructor(public d: MatDialog, private storageS: StorageService) { }

  openDialog() {
    this.d.open(DialogWhatsNewsComponent, {
      width: "90vw",
      disableClose: true,
      maxWidth: '600px', data: this.news
    })
  }

  dimissNews() {
    if (this.news.length) { this.storageS.save(LAST_DIMISSED, Math.max(...this.news.map(n => n.id))); }
  }

  isDimissed() {
    if (this.news.length == 0) { return true }
    let savedValue = this.storageS.get(LAST_DIMISSED)?.getNumber();
    if (savedValue == undefined) { return false }
    else {return this.news.every(n => n.id <= savedValue!);}
  }
}
