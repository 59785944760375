<!-- Optional close button-->
<app-close-dialog-button></app-close-dialog-button>

<!-- Change title If flotas feature is activated : "Facturación final de mes"-->
<p mat-dialog-title>Pendiente de facturar</p>

<!-- Dialog content -->
<div mat-dialog-content>
    <div *ngIf="data.orPendingArray.length;else noData">
        <div *ngFor="let or of data.orPendingArray" class="clickable-item" (click)="goOr(or)">
            <p class="nmb">
                <span class="fw500 c_r">{{or.title_id}} - </span>
                <span>{{pendingGroupsTotal(or)}} {{pendingGroupsTotal(or) == 1 ? 'intervención' : 'intervenciones'}} </span>
                <span class="c_t2 ml5 mr5">| </span>
                <!-- To do : Refactor totals -->
                <span class="c_p" money [val]="or.totalToInvoice"></span>
                <mat-icon class="vam c_t2">chevron_right</mat-icon>
            </p>
            <p class="fss c_t2">{{or.created_at.dayMonthYearFormat()}}</p>
        </div>
    </div>
    <ng-template #noData>
        <p *ngIf="!data.orPendingArray.length" class="c_t2">Sin órdenes de reparación por facturar</p>
    </ng-template>
</div>


<!-- Mat dialog actions 
<div mat-dialog-actions class="fdc">
    <mat-checkbox #chk [ngClass]="{'dn_i' : !data.orPendingArray.length}">Entiendo que se facturarán todos las intervenciones
        cerradas</mat-checkbox>
    <button color="warn" [disabled]="data.orPendingArray.length ? !chk.checked : false" mat-flat-button mat-dialog-close cdkFocusInitial
        (click)="invoiceAll()">
        {{data.orPendingArray.length ? "Facturar todo" : "OK"}}
    </button>
</div>-->