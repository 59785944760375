import { AppointmentStatus, SearchFiltrable, getArrayOf, match } from "@sinigual/angular-lib";
import { AppointmentInterface } from "@sinigual/angular-lib/lib/interface/AppointmentInterface";
import { M_Client } from "./M_Client";
import { M_Vehicle } from "./M_Vehicle";
import { M_TaskAppointment } from "./M_TaskAppointment";
import { M_RAGroup } from "./M_RAGroup";
import { M_Action } from "./M_Action";

export class M_Appointment implements AppointmentInterface, SearchFiltrable {
    id: number;
    cita: boolean = true;
    license: string;
    vehicle_brand: string | undefined;
    vehicle_model: string | undefined;
    name_client: string;
    phone: string | undefined;
    email: string | undefined;
    hash: string;
    tasks: M_TaskAppointment[] = [];
    state: AppointmentStatus;
    control_1: string | undefined;
    control_2: string | undefined;
    control_3: string | undefined
    client: M_Client | undefined;
    vehicle: M_Vehicle | undefined;
    date_appointment: Date;
    id_appointment: string | undefined;
    or: M_Action | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.license = d.license;
        this.vehicle_brand = d.vehicle_brand;
        this.vehicle_model = d.vehicle_model;
        this.name_client = d.name_client;
        this.email = d.email;
        this.phone = d.phone;
        this.hash = d.hash;
        this.state = d.state;
        this.control_1 = d.control_1;
        this.control_2 = d.control_2;
        this.control_3 = d.control_3;
        this.or = d.or ? new M_Action(d.or) : undefined;

        this.id_appointment = d.id_appointment;
        if (d.vehicle && d.vehicle.client) {
            this.vehicle = new M_Vehicle(d.vehicle);
            this.client = new M_Client(d.vehicle.client);
        }
        this.date_appointment = new Date(d.date_appointment);
        if (d.tasks) {
            this.tasks = getArrayOf(M_TaskAppointment, d.tasks);
        }
    }
    get title_appointment(): string | undefined {
       return this.id_appointment;
    }

    get clientphone(): string | undefined {
        if (this.client) {
            return this.client.phone;
        }
        return this.phone;
    }

    get clientemail(): string | undefined {
        if (this.client) {
            return this.client.email;
        }
        return this.email;
    }

    get status(): AppointmentStatus {
        return this.state;
    }

    set setStatus(status: AppointmentStatus) {
        this.state = status;
    }

    get plate(): string {
        return this.license;
    }

    get brand(): string {
        return this.brand;
    }

    get isClient(): boolean {
        return this.client != undefined;
    }

    get appointment_date(): Date {
        return this.date_appointment;
    }

    get isEditable(){
        return this.isPending;
    }

    get canRA(): boolean {
        return this.status != AppointmentStatus.canceled;
    }
    
    set day(v: Date) {
        this.date_appointment = v;
    }

    get tasksToTabbedComments() {
        let tc: M_RAGroup[] = [];
        this.tasks.map(g => {
            tc.push(new M_RAGroup(g.task))
        })
        return tc;
    }

    get reparations(): string[] {
        return this.tasks.map(t => t.task);
    }

    get origin() { return this.control_1 != undefined ? "Web" : "Eina"; }
    get isCanceled() { return this.status == AppointmentStatus.canceled; }
    get isPending() { return this.status == AppointmentStatus.pending; }
    get isDone() { return this.status == AppointmentStatus.done; }

    clientName(): string | undefined {
        return this.name_client;
    }

    clientNif(): string | undefined {
        if (this.client) {
            return this.client!.nif;
        }
        return '';
    }

    vehicleLicense(): string | undefined {
        if (this.vehicle) {
            return this.vehicle.license;
        }
        return this.license;
    }

    vehicleName(): string | undefined {
        if (this.vehicle) {
            return this.vehicle.getName();
        }
        return this.license ? this.license : this.vehicle_brand + ' ' + this.vehicle_model;
    }

    defaultSearchFilter(val: string): boolean {
        val = val.toLocaleLowerCase();
        return match(val,
            this.id_appointment,
            this.date_appointment.shortFormat(),
            this.clientName(),
            this.clientNif(),
            this.clientemail ? this.clientemail : '',
            this.clientphone ? this.clientphone : '',
            this.vehicleLicense());
    }
}