import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FormService, ParameterStateComponent, RouterService, SessionService, StorageService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { ViewPath } from 'src/app/app-routing.module';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeComponent } from 'src/app/core/components/welcome/welcome.component';
import { UserService } from '../profile/user-service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { M_Subscription } from 'src/app/core/models/M_Subscription';
import { WorkloadService } from 'src/app/core/services/workload.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends ParameterStateComponent {
  v = ViewPath;
  @ViewChild(MatStepper) stepper!: MatStepper;
  public loginForm: UntypedFormGroup;
  public forgotPassword: UntypedFormGroup;
  hash: string = "";
  email: string = "";
  constructor(private sessionService: SessionService, private formBuilder: UntypedFormBuilder, private formService: FormService,
    private apiS: ApiService, private d: MatDialog, private routers: RouterService, router: ActivatedRoute, private userS: UserService,
    private storageS: StorageService, private workloadS: WorkloadService,
    private subscrptionS: SubscriptionService) {
    super(routers, router, ["hash", "email"]);
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['', [Validators.required]],
    });

    this.forgotPassword = this.formBuilder.group({
      email: [''],
    });
  }

  override onParam(param: string, value: string): void {
    if (param == "email") {
      this.loginForm.patchValue({ email: value });
    }
  }

  makeCall(): void {
    if (this.formService.isOk(this.loginForm)) {

      //Remove the prevoius logged user subscription
      this.subscrptionS.setSubscriptionStatus(undefined);

      this.apiS.doLogin(this.loginForm.value).then(resp => {
        /** Clear all the storage */
        this.storageS.clear();
        this.sessionService.setToken(resp.access_token);
        this.sessionService.setId(resp.user_id);
        var fistLogin: boolean = resp.last_login == null;
        var fistCompanyLogin: boolean = resp.first_login == true;
        if (fistCompanyLogin) {
          this.d.open(WelcomeComponent, { disableClose: true, panelClass: "welcome-dialog" })
        }
        this.subscrptionS.setSubscriptionStatus(new M_Subscription(resp.subscription));
        this.userS.setFirstLogin(fistLogin);
        this.userS.setFirstCompanyLogin(fistCompanyLogin);
        this.userS.setRole(resp.role);
        this.userS.updateCompanyTax(resp.company_tax)
        this.userS.updateDefaultVehicle(resp.default_vehicle)
        this.workloadS.refresh();
        this.routers.goTo(this.v.dashboard)
      })
    }
  }

  sendRecoverPassword() {
    const call = this.apiS.forgotPaswword(this.forgotPassword.get('email')?.value);
    call.then(res => { this.stepper.selectedIndex = 0; })
    return call;
  }

  isEmailBadFormetted() {
    const emailInput = this.loginForm.get('email');
    return (emailInput?.errors != undefined && emailInput?.errors['email'] != undefined && emailInput.dirty && emailInput.touched);
  }

  goForgetPassword() {
    this.stepper.next();
  }

  goRegister() {
    this.routers.goTo(this.v.register);
  }

  goLogin() {
    this.stepper.previous();
  }

  /**Evitar que el stepper marque los formularios en rojo */
  stepChanged(stepper: any) {
    stepper.selected.interacted = false;
  }
}