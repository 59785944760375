import { Component, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { EntryPointService, ResponsiveService, RoleService, RouterService, SessionService, StorageService, SvgIconsService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import * as LogRocket from 'logrocket';
import { ReferenceService } from 'src/app/core/services/onboarding.service';
import { MenuComponent } from 'src/app/core/components/menu/menu.component';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { EnumSubscription } from 'src/app/core/enums/EnumSubscription';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';

@Component({
  selector: 'app-root',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.css']
})
export class EntryPointComponent {
  v = ViewPath;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {
    this.responsive.width = event.target.innerWidth;
  }

  re = RolesEnum;
  displayToolbar = false;
  es = EnumSubscription;
  items = this.storageS.getAllStorage();

  constructor(public responsive: ResponsiveService, private routerS: RouterService, router: Router,
    private sessionS: SessionService, entrypointS: EntryPointService, public refSercice: ReferenceService,
    public subS: SubscriptionService, private storageS: StorageService, private roleS: RoleService, private svgS: SvgIconsService) {

    this.svgS.addSvgIcons("wheel", "wheel_outline", "bizum", "damages_bike", "barcode", "file-pdf", "eina");

    if (feature.logRocket) {
      LogRocket.init('rfqxuq/eina');
    }

    this.responsive.width = window.innerWidth;
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        window.scroll(0, 0);
      }
      if (val instanceof NavigationEnd) {
        this.displayToolbar = entrypointS.currentViewNeedToolbar();
        if (this.displayToolbar && !this.sessionS.hasSession()) {
          this.routerS.goTo(this.v.login);
        }
      }
    });
  }

  goFrontendStandards() {
    this.routerS.goTo(ViewPath.frontend);
  }

  toggleMenu(menu: MenuComponent, phonemenu: MenuComponent) {
    this.responsive.isPhone() ? phonemenu.toggle() : menu.toggle();
  }


  setRole(n: RolesEnum) {
    this.roleS.setRole(n);
  }

}


