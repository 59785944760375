import { Component, Inject, ViewChild } from '@angular/core';
import { calendarEnabled } from 'src/app/core/constants/constants';
import { CompanyComponent } from '../company.component';
import { M_Company } from 'src/app/core/models/M_Company';
import { HolidaysCalendarComponent } from 'src/app/core/components/holidays-calendar/holidays-calendar.component';

@Component({
  selector: 'app-holidays-company',
  templateUrl: './holidays-company.component.html',
  styleUrls: ['./holidays-company.component.css']
})
export class HolidaysCompanyComponent {
  enabledCalender = calendarEnabled;
  @ViewChild(HolidaysCalendarComponent) holidays! : HolidaysCalendarComponent;
  constructor(@Inject(CompanyComponent) public parent: CompanyComponent){

  }
  initCompany(company : M_Company){
    this.holidays.formatAndSetMultipleDates(company);
    this.holidays.initComponent({companyH: company.holidays, userH: []});
  }
}
