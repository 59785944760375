<div  [@appearAnim]='appear' class="movement" (click)="billPreview(pi)">
    <app-card [noStyle]="true">
        <div class="df">
            <div>
                <!-- TO DO : CHANGE ID-->
                <app-card-subtitle>
                    <mat-icon class="vam mr5">{{pi.isAbono ? 'currency_exchange' : 'receipt_outline'}}</mat-icon>
                    <span class="c_b cp">#{{pi.invoice_id_company}}</span>
                </app-card-subtitle>
                <p class="normal">Unidades : {{pi.quantity}}</p>
                <p *ngIf="pi.discount">Descuento : {{pi.discount}}%</p>
                <p class="c_t2 fss">{{pi.created_at.shortFormat()}}</p>
            </div>
        </div>
    </app-card>
</div>

