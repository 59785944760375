<div class="advanced-details-container" *ngIf="user != undefined || (user == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado usuarios'" [v]="v.team" [show]="loaded"></app-go-back>
    <div class="user">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="delete_v">
            <div *ngIf="user != undefined">
                <div class="df fdc aic jcc">
                    <lord-icon  src="https://cdn.lordicon.com/diceznvw.json"
                    trigger="hover" delay="9999" colors="primary:#121331,secondary:{{warn()}},tertiary:#f9c9c0,quaternary:#646e78,quinary:{{primary()}},senary:{{accent()}}"
                    state="hover-looking-around" [ngClass]="'lord-medium'">
                    <app-user-email-always class="email-relative"></app-user-email-always>
                    </lord-icon>
                    <p class="fsxl fw200">{{user!.name!}}</p>
                    <button mat-flat-button color="warn" class="one-line" (click)="openEditDialog()">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>
                </div>
            </div>
            <ng-template #delete_v>
                <app-delete-button *ngIf="user && !sessionS.isMe(user.id)" class="leftItem" [item]="user!"></app-delete-button>
            </ng-template>
        </app-card>
    </div>
    <div class="Details">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="user != undefined">
                <app-card-row-content [data]="
                    [['Nombre', user.name],
                    ['Correo', user.email],
                    ['Rol',user.roleName]]">
                </app-card-row-content>
            </div>
        </app-card>
    </div>
    <div class="detail2">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="user != undefined">
                <app-card-title>
                    Calendario
                </app-card-title>
                <app-holidays-calendar  [showUnsaved]="false" [selfSave]="true" 
                    [prespective]="'user'"
                    [enabledFor]="enabledCalender"
                    [initData]="{companyH :this.user!.company!.holidays, userH: this.user!.holidays, userID :user!.id}"
                    (onSave)="onSaveUserHolidays($event, user!)">
                </app-holidays-calendar>
                
            </div>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el usuario'" [goText]="'Ir al listado de usuarios'"
        [view]="v.vehiculos">
    </app-model-not-found>
</ng-template>