import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-pay-form',
  templateUrl: './invoice-pay-form.component.html',
  styleUrls: ['./invoice-pay-form.component.css']
})
export class InvoicePayFormComponent {

}
