<div class="df aic fdc">
    <mat-progress-bar *ngIf="loading" class="all-c-width" [mode]="'indeterminate'"></mat-progress-bar>
    <div class="containerBtn">
        <button mat-icon-button (click)="previousYear()"><mat-icon>chevron_left</mat-icon></button>
        <span class="spanCalend">{{year}}</span>
        <button mat-icon-button (click)="nextYear()"><mat-icon>chevron_right</mat-icon></button>
    </div>
    <!-- MAIN CALENDAR-->
    <mat-calendar  class="all-c-width mat-elevation-z2 calendarMobile" [ngClass]="{'calendar-disabled' : !enabled || loading}" #datePicker [dateClass]="dateClass"
        (yearSelected)="generateBreakdown()"
        (selectedChange)="dateChanged($event)"></mat-calendar>
        <div class="df fdr fww">
              <mat-calendar  *ngFor="let month of months" [startAt]="getStartDate(month.number)"
                  class="all-c-width mat-elevation-z2 showCalendar" [ngClass]="{'calendar-disabled' : !enabled || loading}"  
              [dateClass]="dateClass" #anually  (selectedChange)="dateChanged($event)"></mat-calendar>
        </div>

    <!-- BOTTOM CALENDAR CONTENTS-->
    <div class="bottom-contents all-c-width mat-elevation-z2 calendarPC">
        <div class="df fdc aic w100">

            <!-- LEGEND -->
            <div class="legend" *ngIf="showLegend" #header>
                <p><mat-icon class="c_r">fiber_manual_record</mat-icon> Tus festivos </p>
                <p><mat-icon class="c_p">fiber_manual_record</mat-icon> Festivo empresa </p>
            </div>

            <!-- UNSAVED CHANGES-->
            <span *ngIf="holidayChanges && showUnsaved" class="fss c_r fw500 mt5">Cambios sin guardar</span>

            <div class="df fww jcc aic pcView">
                <!-- HOLIDAYS BREAKDOWN-->
                <div *ngFor="let dayByGranularity of holidayBreakdown" >
                    <div  class="breakdown-month" (click)="goToMonth(dayByGranularity.splitDate)" [ngClass]="{'current-month mat-elevation-z1': isCurrentMonth(dayByGranularity.splitDate)}" mat-ripple>
                        <!-- Ex : Enero (2023)-->
                        <div class="breakdown-month-title">
                            {{dayByGranularity.splitDate.getMonthName(true)}}
                            <span class="breakdown-month-year">{{"("+dayByGranularity.splitDate.getYear(true) + ")"}}</span>
                        </div>
                        <!-- Ex : 2 días festivos-->
                        <div class="total-days-container">
                            <span class="semi-bold">{{dayByGranularity.data.length}}</span>
                            <span>{{dayByGranularity.data.length >= 2? ' días' : ' día'}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SELF SAVE BUTTON -->
            <button *ngIf="selfSave && enabled" mat-flat-button [disabled]="!holidayChanges" class="save-button" color="primary" (click)="save()">
                GUARDAR
            </button>

        </div>
    </div>
</div>