<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Historial fichajes</p>

<div mat-dialog-content>
    <div *ngFor="let t of data.or.timers" class="timers-list">
        <ng-container *ngIf="t.custom">
            <div class="df aic">
                <!--<mat-icon class="starting-icon manually-added" [matTooltip]="'Añadido manualmente'">add_circle</mat-icon>!-->
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.dayMonthYearFormat()}}</div>
            </div>
            <div class="c_t2">Manual</div>
        </ng-container>

        <!-- SAME DAY START AND END-->
        <ng-container *ngIf="t.end_time && t.start_time.isEquals(t.end_time) && !t.custom">
            <div class="df aic">
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.dayMonthYearFormat()}}</div>
            </div>
            <div class="c_t2">{{t.start_time.hourMinuteSecond() + " - " + t.end_time.hourMinuteSecond()}}</div>
        </ng-container>

        <!-- DIFFERENT DAYS-->
        <ng-container *ngIf="t.end_time && !t.start_time.isEquals(t.end_time) && !t.custom">
            <div class="df aic">
                <mat-icon class="starting-icon error-icon"
                    [matTooltip]="'El fichaje transcurre en distintos días'">error</mat-icon>
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div> {{t.start_time.shortFormat()}}</div>
            </div>
            <div>{{t.end_time.shortFormat()}}</div>
        </ng-container>

        <!-- USER IS ACTUALLY WORKING -->
        <ng-container *ngIf="!t.end_time && !t.custom">
            <div class="df aic">
                <app-circular-letter class="mr5" [showFullName]="false" [small]="true"
                    [user]="getUserByTime(t)"></app-circular-letter>
                <div>{{t.start_time.dayMonthYearFormat()}}</div>
            </div>
            <div class="c_t2">{{t.start_time.hourMinuteSecond() + " - " + '??'}}</div>
        </ng-container>

        <!------------------------------------------------------------------------->

        <!-- ELAPSED TIME AND REMOVE BUTTON IF USER HAS PERMISSIONS-->
        <div class="pr0 df aic">
            <div class="c_p mr10">{{t.elapsed.format}}</div>
            <button [color]="'warn'" mat-icon-button (click)="deleteTimeLine(t.id)" *ngIf="canDelete(t.user_id)">
                <mat-icon>remove</mat-icon>
            </button>
        </div>

    </div>

    <p *ngIf="data.or.timers.length == 0" class="c_t2 fsm">Nada que mostrar</p>

</div>

<div mat-dialog-actions *ngIf="data.or.timers.length != 0">
    <div class="timer-total">
        <p><span class="c_t fw500">Total: </span>{{getTotalTime().format}}</p>
    </div>
</div>