<form class="form create-appoitnment-form" [ngClass]="{ 'is-edit': isEdit }" style="max-width: unset"
  (keydown.enter)="$event.preventDefault()" [formGroup]="form">
  <div class="container" [ngClass]="{ 'no-client': tabsGroup.selectedIndex == 1 }">
    <div class="title">
      <app-view-title *ngIf="loaded">{{
        isEdit ? "EDITAR CITA" : "CREAR CITA"
        }}</app-view-title>
    </div>
    <div class="top_card">
      <app-card [contentLoaded]="loaded">

        <!-- EDIT APPOINTMENT TITLE -->
        <div class="df aic" *ngIf="isEdit && appointment">
          <app-card-title [noMarginBottom]="true" class="mr5">{{appointment.id_appointment}}</app-card-title>
          <lib-appointment-status-label [menuIconOpen]="isFormOk" [status]="appointment.status"
            [mat-menu-trigger-for]="isFormOk ? status: null"></lib-appointment-status-label>
          <mat-menu #status="matMenu">
            <button *ngIf="!appointment.isCanceled" mat-menu-item class="chip-option" (click)="changeStatus(app_status.canceled)">
              <lib-appointment-status-label [pointer]="true" [status]="app_status.canceled"></lib-appointment-status-label>
            </button>
            <button *ngIf="!appointment.isPending" mat-menu-item class="chip-option" (click)="changeStatus(app_status.pending)">
              <lib-appointment-status-label [pointer]="true" [status]="app_status.pending"></lib-appointment-status-label>
            </button>
            <button *ngIf="!appointment.isDone" mat-menu-item class="chip-option" (click)="changeStatus(app_status.done)">
              <lib-appointment-status-label [pointer]="true" [status]="app_status.done"></lib-appointment-status-label>
            </button>
          </mat-menu>
        </div>


        <!-- TABS -->
        <mat-tab-group mat-align-tabs="center" [color]="'warn'">
          <!-- REGISTERED CLIENT TAB -->
          <mat-tab [labelClass]="'onboarding-create-appointment-client'">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">how_to_reg</mat-icon>
              Cliente registrado
            </ng-template>
            <app-card-subtitle>Cliente</app-card-subtitle>
            <app-class-searcher [canRemove]="appointment == undefined" class="w100"
              #vehicleSearcher [masterClass]="vehicle" searchPlaceHolder="Buscar vehículo o cliente"
              [noSelectionPlaceHolder]="'Ningún vehículo seleccionado'" [specialRow]="'Nuevo cliente'"
              (onSelect)="changeClient($event)" (onSpecialRow)="routerS.goTo(v.createvehicle)" [required]="true">
            </app-class-searcher>
            <div class="cv-details">
              <div class="df jcc">
                <app-client-vehicle-in-dialog [t]="'Información cliente'" [c]="vehicleSearcher.selected?.client"
                  [v]="vehicleSearcher.selected"></app-client-vehicle-in-dialog>
              </div>
            </div>
          </mat-tab>

          <!-- NO REGISTERED CLIENT TAB -->
          <mat-tab [labelClass]="'onboarding-create-appointment-no-client'">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">no_accounts</mat-icon>
              Cliente no registrado
            </ng-template>
            <div>
              <app-card-subtitle>Datos cliente no registrado</app-card-subtitle>
              <div>
                <app-plate-input [strict]="false" (onFocusOut)="checkPlate()" class="w50" [formCName]="'license'"
                  [form]="form"></app-plate-input>
                <mat-form-field class="w50" appearance="outline">
                  <mat-label>Nombre de cliente</mat-label>
                  <input matInput placeholder="Introduce el nombre del cliente" [(ngModel)]="textInput"
                    (input)="checkForTindr()" formControlName="name_client" required />
                </mat-form-field>
              </div>
              <div>
                <app-email-input class="w50" [form]="form" formCName="email"></app-email-input>
                <app-phone-input class="w50" [form]="form" formCName="phone"></app-phone-input>
              </div>
              <div>
                <mat-form-field class="w50 mb10" appearance="outline">
                  <mat-label>Marca</mat-label>
                  <input matInput placeholder="Introduce la marca" formControlName="vehicle_brand" />
                </mat-form-field>
                <mat-form-field class="w50 mb10" appearance="outline">
                  <mat-label>Modelo</mat-label>
                  <input matInput placeholder="Introduce el modelo" formControlName="vehicle_model" />
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <app-card-subtitle>Fecha y hora</app-card-subtitle>
        <div class="onboarding-create-appointment-hour">
          <app-hour-picker [isEdit]="isEdit" [matFormFieldsClasses]="'w50'" [form]="form"></app-hour-picker>
        </div>
      </app-card>
    </div>

    <div class="appo_client" [ngClass]="{ dn: tabsGroup.selectedIndex == 1 }">
      <app-card [contentLoaded]="loaded">
        <div class="ribbon" *ngIf="vehicleSearcher?.selected?.client?.discount">
          <span>{{ vehicleSearcher?.selected?.client?.discount }}% DTO</span>
        </div>
        <app-client-info [small]="true" [client]="vehicleSearcher?.selected?.client" [extended]="true">
        </app-client-info>
      </app-card>
    </div>

    <div class="appo_vehicle" [ngClass]="{ dn: tabsGroup.selectedIndex == 1 }">
      <app-card [contentLoaded]="loaded">
        <app-vehicle-info [small]="true" [vehicle]="vehicleSearcher?.selected" [extended]="false">
        </app-vehicle-info>
      </app-card>
    </div>

    <!-- MIDDLE ELEMENT -->
    <div class="middle_bar" *ngIf="isEdit && appointment && loaded">
      <app-card class="normal-padding-top bc_r0">
        <div class="floating-action-buttons">
          <button mat-flat-button color="warn" (click)="editAppointment()" [disabled]="!hasChanges || !isFormOk">
            Guardar
          </button>
          <div [matTooltip]="!appointment.canRA ? 'La cita esta cancelada' : ''">
            <button mat-flat-button (click)="toOr()" color="primary" [disabled]="!isFormOk || !appointment.canRA">
              {{appointment.or ? 'Generar otra OR' : 'Pasar a OR'}}
            </button>
          </div>
        </div>
        <p *ngIf="appointment.or" class="nmb tac" style="margin-top: 5px;">
          <mat-icon class="vam mr5 c_b">build</mat-icon>
          <span>Ya se ha pasado a OR</span>
          <span class="ml5 c_b c_p underline_hover" (click)="goOr(appointment.or.id)">Ver</span>
        </p>
      </app-card>
    </div>

    <div class="interventions">
      <app-card [contentLoaded]="loaded">
        <app-card-title>Intervenciones</app-card-title>
        <div class="onboarding-create-appointment-reparations">
          <app-comentarios-moto [isEina]="true" #moto style="display: none"></app-comentarios-moto>
          <app-comentarios [showEnter]="true" [removable]="!appointment?.isDone" [form]="form"
            [label]="'Introduce las intervenciones'" [placeHolder]="'Intervenciones'" [moto]="moto"></app-comentarios>
        </div>
        <button appTrialbutton mat-raised-button type="submit" [disabled]="!isFormOk" color="warn" *ngIf="!isEdit"
          class="scb mt10 mb-20 onboarding-create-appointment-finish" style="width: 130px !important; margin: auto"
          (click)="createAppointment()">
          Crear cita
        </button>
      </app-card>
    </div>
  </div>
</form>

<!-- MISSING COMPANY INFO COMPONENT-->
<ng-template #notfound>
  <app-model-not-found [title]="'No se ha encontrado la cita'" [goText]="'Ir al listado de citas'"
    [view]="v.appointments">
  </app-model-not-found>
</ng-template>