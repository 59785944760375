import { match, M_BaseUser, SearchFiltrable, parseOBJ } from "@sinigual/angular-lib";
import { I_ClassSearcher } from "../interfaces/I_ClassSearcher";
import { endpoints } from "../api/Enpoints";
import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { M_Company } from "./M_Company";
import { RolesEnum } from "../enums/RolesEnum";
import { M_LastActivity } from "../api/responses/R_Dashboard";

export class M_User extends M_BaseUser implements SearchFiltrable, I_ClassSearcher<M_User> {
   confirm_sent: boolean = false;
   company_code: string | undefined;
   actions: M_Action[] = [];
   invoices: M_Invoice[] = [];
   company: M_Company | undefined;
   role: RolesEnum | undefined;
   logs : M_LastActivity[] =[];

   constructor(d: any) {
      super(d);
      this.confirm_sent = d.confirm_sent ? d.confirm_sent : false;
      this.company_code = d.company_code;
      this.company = d.company ? new M_Company(d.company) : undefined;
      this.role = d.role ? d.role : RolesEnum.NOONE;

      /** To review on implement appointments */
      if (d.holidays) {
         for (let i = 0; i < d.holidays.length; i++) {
            var currentObj = d.holidays[i];
            if (currentObj.day_holiday) {
               this.holidays.push(new Date(currentObj.day_holiday))
            }
         }
      }
      if (d.actions) {
         for (let i = 0; i < d.actions.length; i++) {
            this.actions.push(new M_Action(d.actions[i]));
         }
      }

      if (d.invoices) {
         for (let i = 0; i < d.invoices.length; i++) {
            this.invoices.push(new M_Invoice(d.invoices[i]));
         }
      }
      if (d.logs) {
         for (let i = 0; i < d.logs.length; i++) {
            this.logs.push(new M_LastActivity(d.logs[i]));
         }
      }
   }

   defaultSearchFilter(text: string): boolean {
      return match(text, this.name, this.email);
   }
   parse(): any {
      let obj: any = parseOBJ(this);
      let newDates: string[] = [];
      this.holidays.forEach(dat => {
         newDates.push(dat.dataBaseFormat())
      });
      obj['day_holiday'] = newDates;

      return obj;
   }

   get admin(){return this.role == RolesEnum.ADMIN}
   get asesor(){return this.role == RolesEnum.ADVISER}
   get mecanico(){return this.role == RolesEnum.MECHANIC}

   get roleName() {
      if (this.admin) {
         return "Administrador";
      }
      else if (this.asesor) {
         return "Asesor";
      }
      else if (this.mecanico) {
         return "Mecánico";
      }
      return "Sin rol";
   }
   /** CLASS SEARCHER INTERFACE */
   get endpoint() { return endpoints.users }
   get cs_id() { return this.id; }
   get icon() { return "build"; }
   get typeof() { return M_User }
   createNew(d: any) { return new M_User(d) }
   getInputText(): string { return this.name; }
   getOptionText(): [string, string] { return ([this.name, this.email]) }
}