<div #parentDiv [formGroup]="form" class="df">
    <mat-form-field appearance="outline" style="margin-right: 5px;" [class]="matFormFieldsClasses">
        <mat-label>Elige un día</mat-label>
        <input [disabled]="!firstDay" #pickerInput matInput [matDatepicker]="picker" [matDatepickerFilter]="filterByDayMaxTodayDisableSunday" (click)="picker.open()" required formControlName="day"
        (dateInput)="addEvent($event)" (dateChange)="addEvent($event)">
        <div matPrefix *ngIf="!firstDay">
            <mat-spinner style="margin-right: 5px; margin-left: 5px;" [strokeWidth]="6" [diameter]="25" mode="indeterminate"></mat-spinner>
        </div>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [dateClass]="dateClass"></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" [class]="matFormFieldsClasses">
        <mat-label>Elige una hora</mat-label>
        <div matPrefix *ngIf="!loadedHours">
            <mat-spinner style="margin-right: 5px; margin-left: 5px;" [strokeWidth]="6" [diameter]="25" mode="indeterminate"></mat-spinner>
        </div>
        <mat-select #select formControlName="hour" required [disabled]="disableSelect || form.disabled">

        <div *ngIf="hoursavailable">
            <div *ngFor="let time of hoursavailable">
                <mat-option [disabled]="time.available" [value]="time.t.getValue()">{{time.t.getText()}} </mat-option>
            </div>
        </div>
        <div *ngIf="hoursavailable?.length == 0">
            <mat-option [disabled]="true" [value]="-1">{{noHoursAvailableError}}</mat-option>
        </div>
       
        </mat-select>
    </mat-form-field>
</div>