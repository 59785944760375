import { Directive, ElementRef } from '@angular/core';
import { UserService } from 'src/app/views/profile/user-service';


/** Interface for roles directives */
interface I_RolesDirective {
  get isRoleOk(): boolean;
}


/** Funcion for all role directives */
function roleIsNotOk(el: ElementRef) {
  el.nativeElement.classList.add('dn_i');
  el.nativeElement.remove();
}


/** If the user is not admin, the element with this tag will hide */
@Directive({
  selector: '[admin]'
})
export class RolesAdminDirective implements I_RolesDirective {

  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }

  get isRoleOk(): boolean {
    return this.userS.isAdmin;
  }
}

/** If the user is not adviser, the element with this tag will hide */
@Directive({
  selector: '[adviser]'
})
export class RolesAdviserDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.isAdviser;
  }
}

/** If the user is not mechanic, the element with this tag will hide */
@Directive({
  selector: '[mechanic]'
})
export class RolesMechanincDirective implements I_RolesDirective {
  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }
  get isRoleOk(): boolean {
    return this.userS.isMechanic;
  }
}

/** If the user is not admin, the element with this tag will hide */
@Directive({
  selector: '[adminORadviser]'
})
export class RolesAdminOrAdviserDirective implements I_RolesDirective {

  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }

  get isRoleOk(): boolean {
    return this.userS.isAdmin || this.userS.isAdviser;
  }
}


/** If the user is not admin, the element with this tag will hide */
@Directive({
  selector: '[adviserORechaninc]'
})
export class RolesAdviserOrMechanicDirective implements I_RolesDirective {

  constructor(el: ElementRef, private userS: UserService) {
    if (!this.isRoleOk) { roleIsNotOk(el); }
  }

  get isRoleOk(): boolean {
    return this.userS.isAdviser || this.userS.isMechanic;
  }
}
