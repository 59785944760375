import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutSendEmailComponent, PageStructureComponent, RouterService, SessionService, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_User } from 'src/app/core/models/M_User';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { CreateUserComponent } from './create-user/create-user.component';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  @ViewChild(LayoutSendEmailComponent) sendEmail?: LayoutSendEmailComponent;
  v = ViewPath;
  f = filter;
  disableCircle = false;
  loading = false;
  primary = getPrimaryColor;
  warn = getWarnColor;

  constructor(public routerS: RouterService, private apiS: ApiService, private dialog: MatDialog,
    public sessionS: SessionService, private chdRef: ChangeDetectorRef, public subS: SubscriptionService,
    public params : ParamsService) {
    this.getAllusers();

    this.subS.onChangeSubscription.subscribe(v => {
      this.refreshCircle();
      this.chdRef.detectChanges();
    })
  }

  ngOnInit(): void { }

  getAllusers() {
    this.loading = true;
    this.apiS.users().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        this.refreshCircle();
        this.loading = false;
      }
    });
  }

  refreshCircle() {
    if (this.subS.status && this.ps.loaded_) {
      var users: number = this.ps.data.length;
      this.disableCircle = users >= this.subS.status.users || !this.subS.hasProPermissions;
      this.chdRef.detectChanges();
    }
    else {
      this.disableCircle = true;
    }
  }

  get circleTooltip() {
    if (this.subS.status) {
      if (this.subS.hasProPermissions){
        return 'Máximo ' + this.subS.status.users + ' usuarios por empresa'
      }
      else {
        return "Se necesita el plan PRO para poder crear usuarios"
      }
    }
    return undefined;
  }

  onChange() {
    if (this.sendEmail) {
      this.sendEmail.sended = false;
      this.sendEmail.disabled = false;
      this.chdRef.detectChanges();
    }
  }

  openDialog() {
    if (this.subS.isBasic) {
      this.subS.openTryProDialog("Crear usuarios");
    }
    else {
      let dref = this.dialog.open(CreateUserComponent);
      dref.afterClosed().subscribe(res => {
        if (res == true) {
          this.getAllusers();
        }
      })
    }
  }

  async resendConfirm() {
    this.ps.selected!.confirm_sent = true;
    return this.apiS.reSendConfirmation(this.ps.selected!.id);
  }

  roleName(u: M_User) {
    return u.roleName;
  }

}
