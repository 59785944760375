import { Component } from '@angular/core';

type legend = {
  name: string
  color: string
}

@Component({
  selector: 'app-custom-legend',
  templateUrl: './custom-legend.component.html',
  styleUrls: ['./custom-legend.component.css']
})
export class CustomLegendComponent {

  legends: legend[] = [
    {
      name: "Normal",
      color: "var(--or-type-normal)"
    },
    {
      name: "Interna",
      color: "var(--or-type-interno)"
    },
    {
      name: "Siniestro",
      color: "var(--or-type-siniestro)"
    },
    // {
    //   name: "Garantía",
    //   color: "var(--or-type-garantia)"
    // }
    // SE COMENTA LA LINEA POR QUE NO SE MUESTRA GARANTIA
  ]
}
