<!-- DROPDOWN VERISON-->
<!--
<div *ngIf="someChildHasPermission">
    <div class="dropDownParent">
        <button class="dropDownButton" color="warn" [ngClass]="getClass() + ' ' + (class_ ? class_ : '')" mat-menu-item (click)="changeShow()">
            <mat-icon *ngIf="show" class="c_r menuMultipleItem">{{show? 'keyboard_arrow_down' : 'navigate_next'}}</mat-icon>
            <span *ngIf="parent.opened">{{data.name}}</span>
        </button>
    </div>
    <div [ngClass]="show ? 'dropDownContents' : 'dn dropDownContents'">
        <div *ngFor="let d of data.child">
            <app-menu-item [mc]="parent" [burgerMenu]="parent" [data]="d" [badge]="getBadge(d)" [svgIcon]="getSvgIcon(d)"></app-menu-item>
        </div>
    </div>
</div>-->

<p class="dropdown-label">
    <!--! <mat-icon class="c_r vab">person</mat-icon> !-->
    <span class="text" [ngClass]="{'hidden' : !parent.opened}">{{data.name}}</span>
    <span class="custom-divider" [ngClass]="{'hidden' : parent.opened || !parent.isDesktop}"></span>
</p>
<div *ngFor="let d of data.child">
    <app-menu-item [mc]="parent" [burgerMenu]="parent" [data]="d" [badge]="getBadge(d)" [svgIcon]="getSvgIcon(d)"></app-menu-item>
</div>
