import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTicketDialog, M_TicketNotificacion, NotificationInterface, OnboardingComponent, RouterService, SessionService } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { UserService } from 'src/app/views/profile/user-service';
import { ApiService } from '../../api/api.service';
import { M_User } from '../../models/M_User';
import { MenuComponent } from '../menu/menu.component';
import * as LogRocket from 'logrocket';
import { SubscriptionService } from '../../services/subscription.service';
import { feature } from '../../features-controller/FeaturesController';
import { OrTimerService } from '../../services/or-time.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
}
)
export class ToolbarComponent implements OnInit {
  f = feature;
  v = ViewPath;
  u: M_User | undefined;
  q: number = 0;
  @Input() mc!: MenuComponent;
  @Input() onboarding!: OnboardingComponent;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public subS: SubscriptionService, public routerS: RouterService, private sessionS: SessionService,
    public apiS: ApiService, private userS: UserService, private d: MatDialog, public timerS: OrTimerService) { }

  ngOnInit(): void {
    this.subS.refreshUserSubscription();
    const a = this.apiS.profile();
    const b = this.apiS.checkCompanyInfo();
    forkJoin([a, b]).subscribe(res => {
      //Setting user
      this.u = res[0];
      if (feature.logRocket) {
        LogRocket.identify(this.u.id!.toString(), {
          name: this.u.name,
          email: this.u.email,
        });
      }
      this.sessionS.setId(this.u.id!);
      //Setting if the user is missing company info
      this.userS.setMissingCompany(!res[1]);
    })

    /** Check if the user is working on OR */
    this.timerS.makeCall();
  }
  logOut() {
    this.sessionS.logOut();
  }

  goProfile() {
    this.routerS.goTo(this.v.profile);
  }

  openIssueDialog() {
    this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues: true }, autoFocus: false });
  }

  notificationAction(not: NotificationInterface) {
    if (not instanceof M_TicketNotificacion) {
      this.routerS.goWithQueryParams(ViewPath.issues, { ct_id: not.ct.id, ct_proj: not.ct.project });
    }
  }

}
