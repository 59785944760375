<div class="df jcc fdc aic bottom-entrance-blurred" [ngClass]="{'dn_i' : !showComponent}">
    <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in" trigger="loop" delay="2000"
        colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{warn()}},senary:#3a3347"
        class="lord-large">
    </lord-icon>
    <p class="fsi fsl mt10">¡Echa el freno!</p>
    <p>Para poder crear <span class="fw600">{{model}}</span> debes rellenar la información de tu empresa.</p>
    <p>No te preocupes, <span class="underline_r">¡es más fácil que ajustar un retrovisor!</span></p>
    <button mat-flat-button color="warn" (click)="goCompany()" class="mt10">
        Ir a la sección de empresa
        <mat-icon>arrow_forward</mat-icon>
    </button>
</div>