import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ClientDetailsComponent } from 'src/app/views/client-details/client-details.component';
import { M_Vehicle } from '../../models/M_Vehicle';
import { ParamsService } from '../../services/params.service';
import { M_Client } from '../../models/M_Client';
import { M_Appointment } from '../../models/M_Appointment';
import { RouterService } from '@sinigual/angular-lib';
import { UserService } from 'src/app/views/profile/user-service';

/** Double selector!!! */
@Component({
  selector: 'app-vehicle-row, app-client-row, app-appointment-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.css']
})
export class ClientVehicleRowComponent implements OnInit {
  v = ViewPath;
  @Input() item!: M_Vehicle | M_Client | M_Appointment;
  @Input() newTab: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() public advanced: ClientDetailsComponent, public params: ParamsService, private routerS: RouterService, private userS: UserService) { }

  ngOnInit(): void {
  }

  goItem() {
    if (!this.disabledItem) {
      this.onClick.emit();
      if (this.item instanceof M_Vehicle) {
        this.params.go(this.v.vehicleDetails, this.item.vehicle_id, undefined, this.newTab)
      }
      else if (this.item instanceof M_Client) {
        this.params.go(this.v.clientDetails, this.item.client_id, undefined, this.newTab)
      }
      else {
        this.routerS.goWithQueryParams(ViewPath.cargataller, {
          section: 'appointment',
          id: this.item.id,
          date: this.item.appointment_date,
          apfilter0: this.item.state
        })
      }
    }
  }

  get isItemDeleted() {
    return this.item instanceof M_Client || this.item instanceof M_Vehicle ? this.item.deleted : false;
  }

  get isUserMechanic() {
    return this.userS.isMechanic;
  }

  get disabledItem() {
    return this.isItemDeleted || this.userS.isMechanic;
  }

  get itemName() {
    return this.item instanceof M_Client || this.item instanceof M_Vehicle ? this.item.getName() : this.item.date_appointment.shortFormat();
  }

  get icon() {
    return this.item instanceof M_Client ? 'person' : this.item instanceof M_Vehicle ? this.item.icon : 'event';
  }

  isV(d: any): d is M_Vehicle {
    return d instanceof M_Vehicle;
  }
}
