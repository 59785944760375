import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ConfirmDialogService, CustomFile } from '@sinigual/angular-lib';
import { UserService } from 'src/app/views/profile/user-service';
import { M_TemplateField } from '../models/M_TemplateField';
import { TemplateFieldsService } from './template-fields.service';
import { M_ActiveReception } from '../models/M_ActiveReception';
import { forkJoin } from 'rxjs';
import { PreviewService } from './preview.service';
import { OrFormComponent } from '../components/or-form/or-form.component';
import { OrNotesAndImagesComponent } from 'src/app/views/or-create-edit/or-notes-and-images/or-notes-and-images.component';
import { M_RAGroup } from '../models/M_RAGroup';
import { M_Action } from '../models/M_Action';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientformComponent } from '../components/clientform/clientform.component';
import { VehicleformComponent } from '../components/vehicleform/vehicleform.component';
import { M_Client } from '../models/M_Client';
import { M_Vehicle } from '../models/M_Vehicle';


type createOrInterface = {
  /** Main OR form */
  orForm: OrFormComponent,
  /** Ra Groups */
  raGroups: M_RAGroup[],
  /** Notes and images Component */
  notesAndImages?: OrNotesAndImagesComponent,
  /** Dialog to close when OR is created */
  dialog?: MatDialogRef<any>,
  /** Client form component*/
  clientForm?: ClientformComponent,
  /** Vehicle form component */
  vehicleForm?: VehicleformComponent,
  /** Appointment ID */
  appoitnmentId?: number,
}

@Injectable({
  providedIn: 'root'
})


/** Service to create OR */
export class OrService {

  constructor(private apiS: ApiService, private userS: UserService,
    private confirmD: ConfirmDialogService, private templateService: TemplateFieldsService, private previewS: PreviewService) {
  }

  create(orComponents: createOrInterface) {
    if (this.userS.missingCompanyInfo == false) {
      if (orComponents.orForm.isFormOk) {
        orComponents.orForm.form.patchValue({ "assigned_to": orComponents.orForm.workerSearcher?.selected?.id })

        let preClient: M_Client | undefined = undefined;
        let preVehicle: M_Vehicle | undefined = undefined;

        if (orComponents.clientForm && orComponents.vehicleForm) {
          preClient = new M_Client(orComponents.clientForm.form.getRawValue())
          preVehicle = new M_Vehicle(orComponents.vehicleForm.form.getRawValue());
          preClient.vehicles = [preVehicle];
        }

        let ra = new M_ActiveReception(
          orComponents.orForm.vehicleSearcher?.selected?.client?.client_id,
          orComponents.orForm.clientInvoice?.selected?.client_id,
          orComponents.orForm.vehicleSearcher?.selected?.vehicle_id,
          orComponents.orForm.form.value,
          orComponents.raGroups,
          preClient,
          preVehicle
        );

        /** Creating OR from appointment */
        if (orComponents.appoitnmentId) { ra.appointment_id = orComponents.appoitnmentId };

        this.templateService.showTemplateSteps("RA", undefined, undefined, orComponents.orForm.vehicleSearcher?.selected?.type, ra).afterClosed().subscribe
          ((dialog_res: { models: M_TemplateField[], files: M_TemplateField[] } | false) => {
            if (dialog_res != false) {

              this.apiS.createOR(ra).then(res_create_or => {
                if (res_create_or.or_id) {
                  ra.id = res_create_or.or_id;
                  if (orComponents.notesAndImages) {
                    let files = orComponents.notesAndImages.fileupload.getUploadedFiles();
                    if (files) { this.uploadImage(ra, ...files) };
                  }
                  const a = this.apiS.saveTemplateFields(res_create_or.action_id!, dialog_res.models);
                  const b = this.apiS.saveTemplateFiles(res_create_or.action_id!, dialog_res.files);
                  forkJoin([a, b]).subscribe(res => {
                    this.previewS.showPreview("RA", res_create_or.token, undefined, ra.id, false, true);
                    if (orComponents.dialog) { orComponents.dialog.close(); }
                  })
                }
              });
            }
          });
      }
    }
    else {
      this.confirmD.showError("Error en el formulario", "Se requiere como mínimo introducir un vehículo, sus kilómetros y los datos de Empresa")
    }
  }

  /** Upload images to some action */
  uploadImage(or: M_Action | M_ActiveReception, ...cf: CustomFile[] | File[]) {
    if (cf && or.client_id && or.vehicle_id) {
      cf.forEach(cf => {
        this.apiS.uploadImage(cf, "action_image", ["action_id", or.id!], ["client_id", or!.client_id!], ["vehicle_id", or!.vehicle_id!]).then(res => {
          if (res.image_id && cf instanceof CustomFile) {
            cf.database_id = res.image_id;
          }
        })
      })
    }
  }

}
