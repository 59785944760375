import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-discount-dialog',
  templateUrl: './discount-dialog.component.html',
  styleUrls: ['./discount-dialog.component.css']
})
export class DiscountDialogComponent implements OnInit {
  discount : UntypedFormControl;
  showTooltip : boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public initValue : number | undefined) {
    this.discount = new UntypedFormControl('');
  }
  ngOnInit(): void {
    this.discount.patchValue(this.initValue);
  }
  
  checkDiscount(e : any){
    let newChar = e.data;
    let inputVal = e.target.value;
    if (newChar && !Number.isNaN(Number(newChar))){
      let concat = inputVal + newChar;
      let futureVal = Number(concat);
      if(!Number.isNaN(futureVal)) {
        if(futureVal < 0 || futureVal > 100) {
          e.preventDefault();
          this.showTooltip = true;
        }
      }
      else{
        this.showTooltip = false; 
      }
    }
  }
}
