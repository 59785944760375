import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_RAGroup } from 'src/app/core/models/M_RAGroup';

@Component({
  selector: 'app-tabbed-comments-preview',
  templateUrl: './tabbed-comments-preview.component.html',
  styleUrls: ['./tabbed-comments-preview.component.css']
})
export class TabbedCommentsPreviewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public tabs: M_RAGroup[]) { }
}
