
<h1 mat-dialog-title>MODIFICAR DESCUENTO</h1>
<div class="df jcc">
    <mat-form-field appearance="outline">
        <mat-label>Descuento (%)</mat-label>
        <input [formControl]="discount"
                autocomplete="off" (beforeinput)="checkDiscount($event)" 
                type="number" 
                matInput 
                placeholder="Introduce el descuento">
            <mat-hint *ngIf="showTooltip">Valor máx. : 100</mat-hint>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button [mat-dialog-close]="false" mat-button>Cancelar</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="discount.value">Ok</button>
</div>