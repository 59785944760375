<app-view-title>FRONTEND DESIGN</app-view-title>

<div class="tag">Colors</div>


<div class="df">
    <div *ngFor="let color of ['p', 'a', 'r', 'g', 'o', 'y', 'ice']" style="margin-right: 30px;">
        <div class="df" *ngFor="let c of [0,1,2,3,4]">
            <div class="frontend_color" [style]="getColor(color, c)"></div>
            <p>{{getVar(color, c)}}</p>
        </div>
        <span class="fw600">{{color == 'p' ? 'Primary' : color == 'a' ? 'Accent' : color == 'r' ? 'Warn' : ''}}</span>

    </div>
</div>

<div class="tag">mat-button</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<p>
    <button mat-button color="primary">Button</button>
    <button mat-button color="primary" [disabled]="true">Button</button>

    <button mat-button color="accent">Button</button>
    <button mat-button color="accent" [disabled]="true">Button</button>

    <button mat-button color="warn">Button</button>
    <button mat-button color="warn" [disabled]="true">Button</button>

    <button mat-button color="orange">Button</button>
    <button mat-button color="orange" [disabled]="true">Button</button>

    <button mat-button color="white">Button</button>
    <button mat-button color="white" [disabled]="true">Button</button>
</p>

<div class="tag">mat-stroked-button</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<p>
    <button mat-stroked-button color="primary">Button</button>
    <button mat-stroked-button color="primary" [disabled]="true">Button</button>

    <button mat-stroked-button color="accent">Button</button>
    <button mat-stroked-button color="accent" [disabled]="true">Button</button>

    <button mat-stroked-button color="warn">Button</button>
    <button mat-stroked-button color="warn" [disabled]="true">Button</button>

    <button mat-stroked-button color="orange">Button</button>
    <button mat-stroked-button color="orange" [disabled]="true">Button</button>

    <button mat-stroked-button color="white">Button</button>
    <button mat-stroked-button color="white" [disabled]="true">Button</button>
</p>


<div class="tag">mat-raised-button</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<p>
    <button mat-raised-button color="primary">Button</button>
    <button mat-raised-button color="primary" [disabled]="true">Button</button>

    <button mat-raised-button color="accent">Button</button>
    <button mat-raised-button color="accent" [disabled]="true">Button</button>

    <button mat-raised-button color="warn">Button</button>
    <button mat-raised-button color="warn" [disabled]="true">Button</button>

    <button mat-raised-button color="orange">Button</button>
    <button mat-raised-button color="orange" [disabled]="true">Button</button>

    <button mat-raised-button color="white">Button</button>
    <button mat-raised-button color="white" [disabled]="true">Button</button>
</p>


<div class="tag">mat-flat-button</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<p>
    <button mat-flat-button color="primary">Button</button>
    <button mat-flat-button color="primary" [disabled]="true">Button</button>

    <button mat-flat-button color="accent">Button</button>
    <button mat-flat-button color="accent" [disabled]="true">Button</button>

    <button mat-flat-button color="warn">Button</button>
    <button mat-flat-button color="warn" [disabled]="true">Button</button>

    <button mat-flat-button color="orange">Button</button>
    <button mat-flat-button color="orange" [disabled]="true">Button</button>

    <button mat-flat-button color="white">Button</button>
    <button mat-flat-button color="white" [disabled]="true">Button</button>
</p>

<div class="tag">mat-icon-button</div>
<div class="tip">Sin tips</div>
<p>
    <button mat-icon-button color="primary"><mat-icon>person</mat-icon></button>
    <button mat-icon-button color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-icon-button color="accent"><mat-icon>person</mat-icon></button>
    <button mat-icon-button color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-icon-button color="warn"><mat-icon>person</mat-icon></button>
    <button mat-icon-button color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-icon-button color="orange"><mat-icon>person</mat-icon></button>
    <button mat-icon-button color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-icon-button color="white"><mat-icon>person</mat-icon></button>
    <button mat-icon-button color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
</p>


<div class="tag">mat-fab</div>
<div class="tip">Por defecto, color primario</div>
<p>
    <button mat-fab color="primary"><mat-icon>person</mat-icon></button>
    <button mat-fab color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-fab color="accent"><mat-icon>person</mat-icon></button>
    <button mat-fab color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-fab color="warn"><mat-icon>person</mat-icon></button>
    <button mat-fab color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-fab color="orange"><mat-icon>person</mat-icon></button>
    <button mat-fab color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-fab color="white"><mat-icon>person</mat-icon></button>
    <button mat-fab color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
</p>

<div class="tag">mat-mini-fab</div>
<div class="tip">Por defecto, color primario</div>
<p>
    <button mat-mini-fab color="primary"><mat-icon>person</mat-icon></button>
    <button mat-mini-fab color="primary" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-mini-fab color="accent"><mat-icon>person</mat-icon></button>
    <button mat-mini-fab color="accent" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-mini-fab color="warn"><mat-icon>person</mat-icon></button>
    <button mat-mini-fab color="warn" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-mini-fab color="orange"><mat-icon>person</mat-icon></button>
    <button mat-mini-fab color="orange" [disabled]="true"><mat-icon>person</mat-icon></button>

    <button mat-mini-fab color="white"><mat-icon>person</mat-icon></button>
    <button mat-mini-fab color="white" [disabled]="true"><mat-icon>person</mat-icon></button>
</p>


<div class="tag">Icons</div>
<div class="tip">Sin tips</div>
<mat-icon>person</mat-icon>
<mat-icon color="accent">person</mat-icon>
<mat-icon color="warn">person</mat-icon>
<mat-icon color="orange">person</mat-icon>
<mat-icon color="white">person</mat-icon>


<div class="tag">app-view-title</div>
<div class="tip">Todo en mayúsculas</div>
<app-view-title>EJEMPLO</app-view-title>

<div class="tag">app-card-title</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<app-card-title>Ejemplo</app-card-title>

<div class="tag">app-card-subtitle</div>
<div class="tip">La primmera letra mayúscula, el resto minúsculas</div>
<app-card-subtitle [first]="true">Ejemplo</app-card-subtitle>

<div class="tag">app-card</div>
<div class="tip">Siempre utilizar los tags de <span class="tag">app-card-title</span> y <span
        class="tag">app-card-subtitle</span></div>
<app-card>
    <app-card-title>Card title</app-card-title>
    <app-card-subtitle [first]="true">Card subtitle 1</app-card-subtitle>
    <p>Some text</p>
    <app-card-subtitle>Card subtitle 2</app-card-subtitle>
    <p>Some text 2</p>
</app-card>


<div class="tag">Diálogos</div>
<div class="tip">Utilizar SIEMPRE la plantilla del componente <span class="tag">StandardDialogComponent</span> (copy &
    paste)</div>
<div class="tip">Par los títulos y los subtitilos se utilizan las directivas <span class="tag">mat-dialog-title</span> y
    <span class="tag">mat-dialog-subtitle</span>
</div>
<button mat-flat-button color="warn" (click)="standarDialog()">Ver ejemplo</button>
<button mat-flat-button color="warn" (click)="noCardDialog()">Dialogo sin contorno de 'card'</button>

<div class="tag">Diálogos de confirmación</div>
<div class="tip">Utilizar SIEMPRE el servicio de <span class="tag">ConfirmDialogService</span></div>
<button mat-flat-button color="primary" (click)="confirmDialog('confirm')">Ver ejemplo (confirm)</button>
<button mat-flat-button color="accent" (click)="confirmDialog('info')">Ver ejemplo (info)</button>
<button mat-flat-button color="orange" (click)="confirmDialog('warn')">Ver ejemplo (warn)</button>
<button mat-flat-button color="warn" (click)="confirmDialog('danger')">Ver ejemplo (danger)</button>




<div class="tag">Lord Icons</div>
<p>Los Lord Icons deben ser los "Wired Lineal"</p>
<p><a href="https://siniwin.atlassian.net/wiki/spaces/SINIWIN/pages/1011908702/Lord+Icons"> Lord Icons Confluence</a></p>
<p><a href="https://lordicon.com/icons/wired/lineal"> Lord Icons Web</a></p>
<div class="tip">Existen 5 tamaños predefinidos: <span class="tag">.lord-small</span>, <span
        class="tag">.lord-medium,</span>, <span class="tag">.lord-big</span>, <span class="tag">.lord-large</span>, <span class="tag">.lord-huge</span></div>
<script src="https://cdn.lordicon.com/lordicon.js"></script>
<lord-icon class="lord-small" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
<lord-icon class="lord-medium" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
<lord-icon class="lord-big" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
<lord-icon class="lord-large" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>
<lord-icon class="lord-huge" src="https://cdn.lordicon.com/jpzirtpz.json" trigger="hover"></lord-icon>





<app-view-title>FRONTEND DOCUMENTATION</app-view-title>
<div class="tag">Roles directive</div>

<div class="tip">
    Put the directive <span class="tag">admin</span>, <span class="tag">advisor</span> or <span class="tag">mechanic</span> to some element.
    The element can only be seen by the role/s in the directive.
</div>