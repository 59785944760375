import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { FormService, ParameterStateComponent, RouterService, SnackService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { ClientformComponent } from 'src/app/core/components/clientform/clientform.component';
import { VehicleformComponent } from 'src/app/core/components/vehicleform/vehicleform.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent extends ParameterStateComponent {
  loaded: boolean = false;
  v = ViewPath;
  public isEdit: boolean = false;
  public c: M_Client | undefined;
  @ViewChild('toggle') toggle?: MatSlideToggle;
  @ViewChild(ClientformComponent, { static: true }) clientForm!: ClientformComponent;
  @ViewChild(VehicleformComponent) formVehicle!: VehicleformComponent;

  constructor(private fs: FormService, private apiS: ApiService,
    routerS: RouterService, route: ActivatedRoute, private paramS: ParamsService,
    private params: ParamsService, private snackS: SnackService) {
    super(routerS, route, ["client"]);
  }


  override noParams() {
    this.loaded = true;
  }

  override onParam(_k: string, v: any) {
    if (_k == "client") {
      this.isEdit = true;
      this.apiS.getClientById(v).then(res => {
        if (res) {
          this.fillDataWithClient(res);
        }
      })
    }
  }

  fillDataWithClient(client: M_Client) {
    this.loaded = true;
    this.clientForm.form.patchValue(client);
    this.clientForm.form.patchValue({ "dob": client.dob ? client.dob.datePickerFormat() : null });
    client.clients_admincenters.forEach(ac => {
      this.clientForm.pushNewAdminCenter(ac);
    })
    this.c = client;
  }

  create() {
    if (this.isEdit) {
      this.clientForm.form.updateValueAndValidity();
      if (this.fs.isOk(this.clientForm.form)) {
        let client = new M_Client(this.clientForm.form.getRawValue());
        client.client_id = this.c!.client_id;
        client.vehicles = this.c!.vehicles;
        this.apiS.updateClient(client).then(_resp => {
          this.params.go(this.v.clientDetails, client.client_id);
          this.snackS.show("¡Cliente editado con éxito!")
        })
      }
    }
    else {
      if (this.toggle?.checked) {
        if (this.fs.isOk(this.clientForm.form, this.formVehicle.form)) {
          let c = new M_Client(this.clientForm.form.value);
          c.vehicles = [new M_Vehicle(this.formVehicle.form.value)];
          this.apiS.createClient(c).then(resp => {
            this.paramS.go(this.v.clientDetails, resp.client_id)
            this.snackS.show("¡Cliente y vehículo creados con éxito!")
          })
        }
      }
      else {
        if (this.fs.isOk(this.clientForm.form)) {
          let c = new M_Client(this.clientForm.form.value);
          this.apiS.createClient(c).then(resp => {
            this.paramS.go(this.v.clientDetails, resp.client_id)
            this.snackS.show("¡Cliente creado con éxito!")
          })
        }
      }
    }
  }

  goBackClient() {
    if (!this.c) { return; }
    this.paramS.go(ViewPath.clientDetails, this.c.client_id);
  }

}
