import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ResponsiveService, RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Action } from 'src/app/core/models/M_Action';

@Component({
  selector: 'app-dashboard-or-table',
  templateUrl: './dashboard-or-table.component.html',
  styleUrls: ['./dashboard-or-table.component.css']
})
export class DashboardOrTableComponent implements OnInit {
  @Input() ors: M_Action[] = [];
  dataSource: MatTableDataSource<M_Action> = new MatTableDataSource<M_Action>([]);
  constructor(public responsiveS: ResponsiveService, private routerS: RouterService) { }

  ngOnInit() {
    this.dataSource.data = this.ors;
  }

  get displayedColumns() {
    return  this.responsiveS.w > 550 ?  ['vehicle', 'task', 'worker', 'status'] : ['vehicle', 'worker', 'status'];
  }

  goOr(or: M_Action) {
    this.routerS.goWithQueryParams(ViewPath.editOr, { or: or.id })
  }

  hasAssigned(element: M_Action) {
    return element.assigned != undefined;
  }

  getTask(e : M_Action){
    return e.groups.length ? e.groups[0].title : "Sin datos";
  }
}
