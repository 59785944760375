import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_User } from '../../models/M_User';
import { MatDialog } from '@angular/material/dialog';
import { RolesExplanationComponent } from './roles-explanation/roles-explanation.component';

@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styles: [':host ::ng-deep mat-form-field, ._customInput  {width : 100%}']
})
export class UserformComponent implements OnInit {
  @Input() u?: M_User;
  public form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private d: MatDialog) {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: [''],
      role: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.u != undefined) {
      this.form.patchValue({
        "name": this.u.name,
        "email": this.u.email,
        "role": this.u.role
      })
      this.form.get('email')?.disable();
      if (this.u.admin) {
        this.form.get('role')?.disable();
      }
    }
  }

  openRolesExplanation() {
    this.d.open(RolesExplanationComponent, { autoFocus: false });
  }
}
