import { ChangeDetectorRef, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComentariosComponent, ComentariosMotoComponent, or_types } from '@sinigual/angular-lib';
import { TabbedCommentsPreviewComponent } from './tabbed-comments-preview/tabbed-comments-preview.component';
import { M_RAGroup } from 'src/app/core/models/M_RAGroup';



@Component({
  selector: 'app-tabbed-comments',
  templateUrl: './tabbed-comments.component.html',
  styleUrls: ['./tabbed-comments.component.css']
})
export class TabbedCommentsComponent {
  @Input() form!: UntypedFormGroup;
  @ViewChildren(ComentariosComponent) comentariosComponent?: QueryList<ComentariosComponent>;
  @ViewChildren(ComentariosMotoComponent) moto?: QueryList<ComentariosMotoComponent>;
  selected = new UntypedFormControl(0);
  maxTabs = 5;
  index = 1;
  constructor(private chdRef: ChangeDetectorRef, private d: MatDialog) { }
  tabs: M_RAGroup[] = [new M_RAGroup("Intervención 1")];

  addTab() {
    if (!this.max) {
      this.index++;
      this.tabs.push(new M_RAGroup('Intervención ' + (this.index)));
      this.selected.setValue(this.tabs.length - 1);
      this.form.patchValue({ comments: "" });
      this.chdRef.detectChanges();
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
    this.selected.setValue(this.selected.value - 1)
    this.chdRef.detectChanges();
  }

  onSelectedChange(index: number) {
    this.selected.setValue(index);
    this.chdRef.detectChanges();
  }

  getComments(i: number) {
    try {
      return this.comentariosComponent!.get(i)!.getCommentsAsArray();
    }
    catch {
      return [];
    }
  }

  get max() {
    return this.tabs.length >= this.maxTabs;
  }

  onTypeChange(tc: M_RAGroup, val: number) {
    tc.or_type = new or_types(val);
  }

  refreshComments() {
    throw Error("Not implemented");
    /*this.tabs.forEach((tab, index) => {
      tab.comments = this.getComments(index);
    })*/
  }

  preview() {
    this.refreshComments();
    this.d.open(TabbedCommentsPreviewComponent, { data: this.tabs, autoFocus: false });
  }

}
