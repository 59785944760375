import { Component } from '@angular/core';

@Component({
  selector: 'app-sell-vehicle',
  templateUrl: './sell-vehicle.component.html',
  styleUrls: ['./sell-vehicle.component.css']
})
export class SellVehicleComponent {

}
