<div class="advanced-details-container">
    <div class="title">
        <app-view-title>ABONAR FACTURA</app-view-title>
    </div>

    <!-- Vehicle on OR, general info on invoice-->
    <div class="left">
        <app-card [contentLoaded]="hasOrOrInvoice">
            <div *ngIf="invoice">
                <app-card-title>Información general</app-card-title>

                <app-card-subtitle>Fecha facturación</app-card-subtitle>
                <p>{{invoice.created_at!.humanFormat()}}</p>

                <app-card-subtitle>Total</app-card-subtitle>
                <p money [val]="invoice.total"></p>

                <app-card-subtitle>Comentarios</app-card-subtitle>
                <p *ngIf="invoice.comment">{{invoice.comment}}</p>
                <p *ngIf="!invoice.comment" class="c_t2">Sin comentarios</p>

            </div>
        </app-card>
    </div>

    <!-- Client -->
    <div class="right">
        <app-card [contentLoaded]="hasOrOrInvoice">
            <app-card-title>Cliente</app-card-title>
            <div class="ribbon" *ngIf="client?.discount">
                <span>{{client?.discount}}% DTO</span>
              </div>
            <app-client-info *ngIf="client; else noClient" [client]="client"> </app-client-info>
            <ng-template #noClient>
                <p class="c_t2">Sin cliente</p>
            </ng-template>
        </app-card>
    </div>

    <!-- Products / Products and time-->
    <div class="products">
        <app-card [contentLoaded]="hasOrOrInvoice">

            <app-card-title>Productos / intervenciones</app-card-title>
            <p>Se abonarán todos los productos o intervenciones de la factura.</p>

            <!-- If Invoice-->
            <div *ngIf="invoice && invoice.breakdown">
                <app-products-line-header [showLastLine]="false"></app-products-line-header>
                <app-add-product *ngFor="let p of invoice.breakdown.products" [p]="p" [abono]="true"
                    [canModify]="false"></app-add-product>
                <app-add-product *ngFor="let p of invoice.breakdown.customs" [p]="p" [abono]="true"
                    [canModify]="false"></app-add-product>
                <div class="df jcc">
                    <button mat-flat-button color="warn" (click)="abonar()">
                        Abonar
                    </button>
                </div>
            </div>
        </app-card>
    </div>
</div>