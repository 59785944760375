<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Configuración cita previa</p>

<div mat-dialog-content>
  <app-card [noStyle]="true" [contentLoaded]="config != undefined">
    <form [formGroup]="companyForm">

      <!-- ENLACE -->
      <div class="appoitments_web">
        <app-card-subtitle [first]="true" [rightIcon]="'public'">Web cita previa</app-card-subtitle>
        <p class="taj nmb">A través de este enlace, tus clientes podrán pedir cita previa en tu taller:</p>
  
        <div class="df fww aic">
          <button class="url-button" mat-stroked-button [color]="'accent'" [matTooltip]="'Visitar enlace'" (click)="goClientSideAppointments()">{{config?.url}}</button>
          <div class="df fww">
            <button mat-icon-button [color]="'primary'" [matTooltip]="'Ver QR'" (click)="openQRDialog(config?.url)">
              <mat-icon>qr_code</mat-icon>
            </button>
            <button mat-icon-button [color]="'primary'" [matTooltip]="'Copiar enlace'" (click)="copyToClippboard()">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
  
      </div>

      <!-- INTERVALO -->
      <app-card-subtitle>Intervalo</app-card-subtitle>
      <p class="taj">Seleccione el intervalo con la que quiere que los clientes puedan pedir cita.
        <mat-icon class="vam c_b" *ngIf="value" mat-icon-button #tooltip="matTooltip" matTooltip="Con la configuración actual, las horas disponibles para los usuarios serian :                 
          {{ getTooltip(value, select1) }} ..." matTooltipPosition="above">info</mat-icon>
      </p>
      <p class="tac fsm fwb nmb">{{value}} minutos</p>
      <mat-slider #slider [max]="max" [min]="min" [step]="5" showTickMarks [discrete]="true"
        [value]="config?.granularity" class="w100">
        <input matSliderThumb formControlName="granularity" [(ngModel)]="value" />
      </mat-slider>

      <!-- MAX APPOINTMENTS DAY -->
      <app-card-subtitle>Máximo de citas por día</app-card-subtitle>
      <p>El número máximo de citas que puedes recibir en un día. Si dejas el valor en 0,
        <span class="fw500">NO</span>
        se podrán pedir citas.
      </p>
      <mat-form-field [ngClass]="responsiveS.isPhone()? 'w100' : 'w50'" appearance="outline">
        <mat-label>Máximo citas</mat-label>
        <input matInput type="number" placeholder="Máximo citas" formControlName="total_appointment" />
      </mat-form-field>


      <!--INPUTS DE HORA ENTRADA/SALIDA-->
      <div class="form df fww fdc">
        <app-card-subtitle>Horario</app-card-subtitle>
        <p>
          Al establecer el horario de trabajo de su empresa, permitirá que los
          clientes puedan pedir citas exclusivamente el horario que establezca.
        </p>
        <div>
          <mat-form-field appearance="outline" class="w50">
            <mat-label>Apertura (mañana)</mat-label>
            <mat-select #select1 formControlName="morning_work_schedule_start" required
              (selectionChange)="inputChanged($event, 0)">
              <div *ngFor="let time of COMPANY_HOURS[0]">
                <mat-option [value]="time.getValue()">{{
                  time.getText()
                  }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w50">
            <mat-label>Cierre (mañana)</mat-label>
            <mat-select #select2 formControlName="morning_work_schedule_finish" required
              (selectionChange)="inputChanged($event, 1)">
              <div *ngFor="let time of COMPANY_HOURS[1]">
                <mat-option [value]="time.getValue()">{{
                  time.getText()
                  }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w50">
            <mat-label>Apertura (tarde)</mat-label>
            <mat-select #select3 formControlName="afternoon_work_schedule_start"
              (selectionChange)="inputChanged($event, 2)">
              <mat-option>Sin establecer</mat-option>
              <div *ngFor="let time of COMPANY_HOURS[2]">
                <mat-option [value]="time.getValue()">{{
                  time.getText()
                  }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w50">
            <mat-label>Cierre (tarde)</mat-label>
            <mat-select #select4 formControlName="afternoon_work_schedule_finish"
              (selectionChange)="inputChanged($event, 3)">
              <mat-option>Sin establecer</mat-option>
              <div *ngFor="let time of COMPANY_HOURS[3]">
                <mat-option [value]="time.getValue()">{{
                  time.getText()
                  }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>


        <!--MARGEN -->
        <app-card-subtitle>Margen</app-card-subtitle>
        <p class="taj">
          Seleccione el margen de días con los que los clientes pueden pedir
          citas.
        </p>
        <mat-form-field appearance="outline" [ngClass]="responsiveS.isPhone()? 'w100' : 'w50'">
          <mat-label>Margen de días</mat-label>
          <mat-select [value]="1" formControlName="range_of_days">
            <mat-option [value]="0">Sin margen</mat-option>
            <mat-option [value]="1">1 día</mat-option>
            <mat-option [value]="2">2 días</mat-option>
            <mat-option [value]="3">3 días</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </app-card>
</div>

<div mat-dialog-actions *ngIf="config != undefined">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button color="warn" mat-flat-button mat-dialog-close (click)="updateSettings()">Guardar</button>
</div>