import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ParameterStateComponent, RouterService, SessionService, getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { EditUserFormComponent } from 'src/app/core/components/edit-user-form/edit-user-form.component';
import { initialHolidayData } from 'src/app/core/components/holidays-calendar/holidays-calendar.component';
import { calendarEnabled } from 'src/app/core/constants/constants';
import { M_User } from 'src/app/core/models/M_User';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent extends ParameterStateComponent {
  loaded: boolean = false;
  user: M_User | undefined;
  enabledCalender = calendarEnabled;
  v = ViewPath;
  accent = getAccentColor;
  warn = getWarnColor;
  primary = getPrimaryColor;
  year: number = new Date().getFullYear();
  months = [
    { number: 0, name: 'Enero' },
    { number: 1, name: 'Febrero' },
    { number: 2, name: 'Marzo' },
    { number: 3, name: 'Abril' },
    { number: 4, name: 'Mayo' },
    { number: 5, name: 'Junio' },
    { number: 6, name: 'Julio' },
    { number: 7, name: 'Agosto' },
    { number: 8, name: 'Septiembre' },
    { number: 9, name: 'Octubre' },
    { number: 10, name: 'Noviembre' },
    { number: 11, name: 'Diciembre' },
  ];
  startDate: Date;
  selectedDates: Date[] = [];
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    public params: ParamsService, private chdRef: ChangeDetectorRef,
    public subS: SubscriptionService, public sessionS: SessionService, private d: MatDialog) {
    super(routerS, route, ["user"])
    this.startDate = new Date(this.year, 0, 1); // Iniciar en enero del año actual
  }

  openEditDialog() {
    this.d.open(EditUserFormComponent, { data: this.user }).afterClosed().subscribe(res => {
      if (res instanceof M_User) {
        this.user = res;

      }
    })
  }

  override onParam(_k: string, v: any) {
    this.apiS.getUserById(v).then(res => {
      if (res == undefined) {
        this.user = undefined;
      }
      if (res instanceof M_User) {
        this.user = res;
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }
  range(limit: number): number[] {
    return Array.from({ length: limit }, (_, index) => index);
  }
  onSaveUserHolidays(onSaveData: initialHolidayData, user: M_User) {
    user.holidays = onSaveData.userH;
    if (user.company) {
      user.company.holidays = onSaveData.companyH;
    }

  }
  onDateSelected(selectedDate: Date): void {
    this.selectedDates.push(selectedDate);
  }
}
