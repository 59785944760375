import { Component } from '@angular/core';

@Component({
  selector: 'app-albaranes',
  templateUrl: './albaranes.component.html',
  styleUrls: ['./albaranes.component.css']
})
export class AlbaranesComponent {

}
