import { Component, Input } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { ActivityEnum, M_LastActivity } from 'src/app/core/api/responses/R_Dashboard';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-last-activity',
  templateUrl: './last-activity.component.html',
  styleUrls: ['./last-activity.component.css']
})
export class LastActivityComponent {

  @Input() allActivity: M_LastActivity[] = [];
  @Input() isProfile: boolean = false;
  constructor(private paramsS: ParamsService) { }

  goToView(a: M_LastActivity) {
    if (a.action && a.object_id) {
      switch (a.activity_type) {
        case ActivityEnum.OR:
          this.paramsS.go(ViewPath.editOr, a.object_id);
          break;
        case ActivityEnum.BUDGET:
          this.paramsS.go(ViewPath.createEditBudget, a.object_id);
          break;
        case ActivityEnum.INVOICE:
          break;
        case ActivityEnum.CLIENT:
        case ActivityEnum.VEHICLE:
          this.paramsS.go(ViewPath.clientDetails, a.object_id);
          break;
        case ActivityEnum.ARTICLE:
          this.paramsS.go(ViewPath.productDetails, a.object_id);
          break;
        case ActivityEnum.CLOUDTICKET:
          break;
        case ActivityEnum.DELETE:
          break;
      }
    }
  }
}
